import { useState } from "react";
import { useDispatch } from "react-redux";
import { EqaCoreService } from "../../../../service/EqaCoreService";
import { updateCampusRedux } from "../../../../../../redux/action/tajweedCoreActions";

const useUpdateCampus = () => {
    const dispatch = useDispatch();
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });

    const updateCampuses = async (formState: any, currentRowIndex: any) => {
        try {
            const response: any = await EqaCoreService.updateCampus(formState);
            if (response.success) {
                dispatch(updateCampusRedux(currentRowIndex, formState));
                setSnackBarHandler({
                    severity: (response.success == true) ? "success" : "error",
                    message: (response.success == true) ? `${response.data.campusName} data has been updated successfully` : (response.data.message),
                    open: true
                })
            } else {
                console.log(response.errMessage);
                setSnackBarHandler({
                    open: true,
                    message: response.errMessage || "Failed to update the user",
                    severity: "error",
                });
            }
        } catch (error) {
            console.error("Error during user update: ", error);
            setSnackBarHandler({
                open: true,
                message: "An error occurred while updating the user.",
                severity: "error",
            });
        }
    };

    return { updateCampuses, snackBarHandler, setSnackBarHandler };
};

export default useUpdateCampus;