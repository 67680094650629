import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    FormControl,
    FormControlLabel,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../data/api/read';
import { columnValues } from '../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import useUpdateSurveyLink from '../customHooks/useUpdateSurveyLink';


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateSurveyFormLink = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });



        // const [viewAllCampusData, setViewAllCampusData] = useState<ViewAllCampusData | null>(null);

        // const [courseCodeList, setCourseCodeList] = useState<any>([]);
        // const [collegeName, setCollegeName] = useState<any>([]);
        // const [campusName, setCampusName] = useState<any>([]);
        // const [departmentName, setDepartmentName] = useState<any>([]);
        // const [programName, setProgramName] = useState<any>([]);
        // const [sessionTypeName, setSessionTypeName] = useState<any>([]);



        //set state for form value
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [sectionNumber, setSectionNumber] = useState<string>(originalValues.sectionNumber);
        const [urls, setUrl] = useState<string>(originalValues.url);
        // const [noOfDays, setNoOfDays] = useState<string>(originalValues.noOfDays);
        const [semester, setSemester] = useState<string>(originalValues.semester);
        const [year, setYear] = useState<string>(originalValues.year);
        const [collegeId, setCollegeId] = useState<string>(originalValues.collegeId);
        const [campusId, setCampusId] = useState<string>(originalValues.campusId);
        const [departmentId, setDepartmentId] = useState<string>(originalValues.departmentId);
        const [closCount, setClosCount] = useState<string>(originalValues.closCount);
        const [studentCount, setStudentCount] = useState<string>(originalValues.studentCount);
        const [isChecked, setIsChecked] = useState(originalValues.active);

        const [days, setDays] = useState(originalValues.noOfDays);


        const [daysError, setDaysError] = useState<boolean>(false);



        const daysListOptions = [
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' },
            { value: 4, label: '4' },
            { value: 5, label: '5' },
            { value: 6, label: '6' },
            { value: 7, label: '7' },
            { value: 8, label: '8' },
            { value: 9, label: '9' },
            { value: 10, label: '10' },
            { value: 11, label: '11' },
            { value: 12, label: '12' },
            { value: 13, label: '13' },
            { value: 14, label: '14' },
            { value: 15, label: '15' },
            { value: 16, label: '16' },
            { value: 17, label: '17' },
            { value: 18, label: '18' },
            { value: 19, label: '19' },
            { value: 20, label: '20' },
            { value: 21, label: '21' },
            { value: 22, label: '22' },
            { value: 23, label: '23' },
            { value: 24, label: '24' },
            { value: 25, label: '25' },
            { value: 26, label: '26' },
            { value: 27, label: '27' },
            { value: 28, label: '28' },
            { value: 29, label: '29' },
            { value: 30, label: '30' }
        ]

        const handleDayChange = (event: any, newValue: any) => {
            setDays(newValue ? newValue.value : null);
        };



        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(!isChecked);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const {updateSurveyLink, snackBarHandler, setSnackBarHandler} = useUpdateSurveyLink();
        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;
                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasError = false;
                        if (days === "") {
                            setDaysError(true);
                            hasError = true;
                        } else {
                            setDaysError(false);
                        }
                        return !hasError;
                    };


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {
                        const formState = {
                            "courseCode": courseCode,
                            "sectionNumber": sectionNumber,
                            "url": urls,
                            "noOfDays": days,
                            "semester": semester,
                            "year": year,
                            "collegeId": collegeId,
                            "campusId": campusId,
                            "departmentId": departmentId,
                            "closCount": closCount,
                            "studentCount": studentCount,
                            "active": isChecked
                        }

                        updateSurveyLink(formState, originalValues.id);
                        setFetchUpdate(true);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                        }, 3000);

                        // axios.put(`${url}/${originalValues.id}`,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.data.code === '200') {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200") ? "success" : "error",
                        //                 message: (response.data.code === "200") ? `Survey has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             if (response.data.code === "200") {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                     setFetchUpdate(true);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    padding:
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            {/* <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label={`${t('Home.Header.Modals.Survey.policy.Inputs.courseCode.label')}`}
                                    placeholder={`${t('Home.Header.Modals.Survey.policy.Inputs.courseCode.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={courseCode}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Section Number"
                                    placeholder="Section Number"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={sectionNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="URL"
                                    placeholder="URL"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={urls}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid> */}
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="daysComplete"
                                    options={daysListOptions}
                                    getOptionLabel={(option) => option.label}
                                    value={daysListOptions.find((option) => option.value === days) || null}
                                    onChange={handleDayChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.Survey.policy.Inputs.days.label')}`}
                                            placeholder={`${t('Home.Header.Modals.Survey.policy.Inputs.days.placeholder')}`}
                                            variant="standard"
                                            error={daysError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Semester"
                                    placeholder="Semester"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={semester}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Year"
                                    placeholder="Year"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={year}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="College Id"
                                    placeholder="College Id"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={collegeId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Campus Id"
                                    placeholder="Campus Id"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={campusId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Department Id"
                                    placeholder="Department Id"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={departmentId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Clos Count"
                                    placeholder="Clos Count"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={closCount}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    label="Student Count"
                                    placeholder="Student Count"
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    value={studentCount}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                />
                            </Grid> */}



                            {/* <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>

                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />

                            </Grid> */}


                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateSurveyFormLink;