import {
    CREATE_MEETING_REQUEST,
    CREATE_MEETING_SUCCESS,
    CREATE_MEETING_FAILURE,
    UPDATE_MEETING_REQUEST,
    UPDATE_MEETING_SUCCESS,
    UPDATE_MEETING_FAILURE,
    GET_MEETING_LIST,
    ADD_SINGLE_MEETING,
    UPDATE_SINGLE_MEETING,
    DELETE_MEETING
} from '../action/meetingActions';

interface meetingState {
    data: any | null;
    meetings: any | null;
    error: any;
    message: any | null;
    loading: any;
}

const initialState: meetingState = {
    data: [],
    meetings: [],
    loading: false,
    error: null,
    message: null
};

const meetingReducer = (state = initialState, action: any): meetingState => {
    switch (action.type) {
        case GET_MEETING_LIST:
            return {
                ...state,
                data: action.payload.data,
            };
        case ADD_SINGLE_MEETING:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: [...state.data.data, action.payload.data] // append the single meeting object
                }
            }
        case UPDATE_SINGLE_MEETING:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: state.data.data.map((meeting: any) =>
                        meeting.id === action.payload.data.id ? action.payload.data : meeting
                    ), // replace the meeting with the updated one
                },
            };
        case DELETE_MEETING:
            return {
                ...state,
                data: {
                    ...state.data,
                    data: state.data.data.filter((meeting: any) => meeting.id !== action.payload.meetingId),
                }, // remove the meeting with the specified id
            };

        case CREATE_MEETING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case CREATE_MEETING_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload
            };
        case CREATE_MEETING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case UPDATE_MEETING_REQUEST:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_MEETING_SUCCESS:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_MEETING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state;
    }
};

export default meetingReducer;




