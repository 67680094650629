import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchGroupData, fetchRoleData } from "../../../../../../redux/action/accountActions";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../redux/store";


const useRoleData = (fetchUpdate: boolean) => {
    const dispatch: AppDispatch = useDispatch();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [viewAllUsersData, setViewAllUsersData] = useState(null);
    const roleList = useSelector((state: RootState) => state.account.manageRole);


    const callApiFun = useCallback(async () => {
        setLoadingStatus(true);
        try {
            await dispatch(fetchRoleData());
        } catch (error) {
            console.error("Failed to fetch data:", error);
        } finally {
            setLoadingStatus(false);
        }
    }, [dispatch])

    useEffect(() => {
        if (roleList == null && fetchUpdate == true) {
            callApiFun();
        }
        else if (roleList !== null) {
            setViewAllUsersData(roleList);
        }
        else {
            callApiFun();
        }

    }, [fetchUpdate, roleList, callApiFun]);

    const addUserRole = localStorage.getItem("addUserRole");
    useEffect(() => {
        if (addUserRole == "true") {
            callApiFun();
            localStorage.removeItem("addUserRole");
        }
    }, [addUserRole])

    
    
    return { viewAllUsersData, loadingStatus, callApiFun };
}

export default useRoleData;