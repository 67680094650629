interface ScheduleCourseState {
    academicYear: any | null;
    courseCoordinator: any | null;
    scheduleCourse: any | null;
    loading: any;
}

const initialState: ScheduleCourseState = {
    academicYear: null,
    courseCoordinator: null,
    scheduleCourse: null,
    loading: false
};

const scheduleCourseReducer = (state = initialState, action: any): ScheduleCourseState => {
    switch (action.type) {
        case 'ACADEMIC_YEAR':
            return {
                ...state,
                academicYear: action.payload.data,
            };
        case 'COURSE_COORDINATOR':
            return {
                ...state,
                courseCoordinator: action.payload.data
            }
        case 'FETCH_SCHEDULE_COURSE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                scheduleCourse: action.payload,
            };
        default:
            return state;
    }
};

export default scheduleCourseReducer;