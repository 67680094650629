import axios from 'axios'
import { ICampusType, ICollegeType, ICourseDetailsType, IDepartmentType, IProgramGoalType, IProgramType, IProgramTypes, IProjectTemplateType, IUniversityType } from "../model/EqaCore"
import readAPI from '../data/api/read'
import createAPI from '../data/api/create'
import updateAPI from '../data/api/update'
import axiosInstance from '../../../Service/interceptor'

export type collegeType = {
    data: ICollegeType[],
    errMessage: ""
}

export type campusType = {
    data: ICampusType[],
    errMessage: ""
}

export type departmentType = {
    data: IDepartmentType[],
    errMessage: ""
}

export type programType = {
    data: IProgramType[],
    errMessage: ""
}

export type programTypes = {
    data: IProgramTypes[],
    errMessage: ""
}

export type universityTypes = {
    data: IUniversityType[]
    errMessage: ""
}

export type courseDetailTypes = {
    data: ICourseDetailsType[]
    errMessage: ""
}

export type programGoalTypes = {
    data: IProgramGoalType[]
    errMessage: ""
}

export type projectTemplateTypes = {
    data: IProjectTemplateType[]
    errMessage: ""
}

export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}


//@ calling get college api ============================================================

async function getAllCollege(): Promise<collegeType> {
    let url: string = readAPI.College;
    const colleges: collegeType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        colleges.data = res.data.obj;
        if (res.data.code !== "200.200") {
            colleges.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        colleges.errMessage = err.message;
    }
    return colleges;
}


//@ calling add college api ============================================================

async function addCollege(collegeData: any): Promise<ResponseType> {
    let url: string = createAPI.AddCollege;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.post(url, collegeData);

        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}


//@ calling update college api ===============================================================

async function updateCollege(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateCollege;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        console.log("formState", formState)
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}


//@ calling get campus api ============================================================

async function getAllCampus(): Promise<campusType> {
    let url: string = readAPI.Campus;
    const campuses: campusType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add campus api ============================================================

async function addCampus(campusData: any): Promise<ResponseType> {
    let url: string = createAPI.AddCampus;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, campusData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update campus api ===============================================================

async function updateCampus(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateCampus;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling get department api ============================================================

async function getAllDepartment(): Promise<departmentType> {
    let url: string = readAPI.Department;
    const campuses: departmentType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add department api ============================================================

async function addDepartment(campusData: any): Promise<ResponseType> {
    let url: string = createAPI.AddDepartment;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, campusData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update departmetn api ===============================================================

async function updateDepartment(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateDepartment;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling get program api ============================================================

async function getAllProgram(): Promise<programType> {
    let url: string = readAPI.Program;
    const campuses: programType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add program api ============================================================

async function addProgram(programData: any): Promise<ResponseType> {
    let url: string = createAPI.AddProgram;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, programData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update program api ===============================================================

async function updateProgram(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateProgram;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling get program type api ============================================================

async function getAllProgramType(): Promise<programTypes> {
    let url: string = readAPI.ProgramType;
    const campuses: programTypes = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add program type api ============================================================

async function addProgramType(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddProgramType;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update program type api ===============================================================

async function updateProgramType(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateProgramType;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling get university type api ============================================================

async function getAllUniversity(): Promise<universityTypes> {
    let url: string = readAPI.University;
    const campuses: universityTypes = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add university type api ============================================================

async function addUniversity(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddUniversity;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update university type api ===============================================================

async function updateUniversity(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateUniversity;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

//@ calling get university type api ============================================================

async function getAllProgramGoal(): Promise<programGoalTypes> {
    let url: string = readAPI.ProgramObjective;
    const campuses: programGoalTypes = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add university type api ============================================================

async function addProgramGoal(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddProgramObjective;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update university type api ===============================================================

async function updateProgramGoal(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateProgramObjective;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

//@ calling get course details type api ============================================================

async function getAllCourseDetails(): Promise<courseDetailTypes> {
    let url: string = readAPI.Courses;
    const campuses: courseDetailTypes = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        campuses.data = res.data.obj;
        if (res.data.code !== "200.200") {
            campuses.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        campuses.errMessage = err.message;
    }
    return campuses;
}


//@ calling add course details type api ============================================================

async function addCourseDetails(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddCourse;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update course details type api ===============================================================

async function updateCourseDetails(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateCourse;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

//@ calling get course PROJECT TEMPLATE api ============================================================

async function getAllProjectTemplate(): Promise<projectTemplateTypes> {
    let url: string = readAPI.ProjectComponent;
    const templates: projectTemplateTypes = {
        data: [],
        errMessage: ""
    };
    const params = {
        sessionTypeId: 30,
        departmentId: 'DID10',
        collegeId: 'C7'
    };

    try {
        const res = await axiosInstance.get(url, {
            params: params
        });
        // templates.data = res.data.obj;
        var data = res.data;
        const transformedData = data.obj.flatMap((item: any) => {
            const {
                college,
                department,
                subComponents,
                sessionType,
                ...restOfItem
            } = item;

            delete restOfItem.college;
            delete restOfItem.department;
            delete restOfItem.subComponents;
            delete restOfItem.sessionType;

            return subComponents.map((subComponent: any) => ({
                sessionType: sessionType.name,
                departmentName: department.departmentName,
                collegeName: college.collegeName,
                componentName: restOfItem.componentName,
                subComponentName: subComponent.subComponentName,
                clos: subComponent.clos,
                maxMarks: subComponent.maxMarks,
                active: restOfItem.active,
                subComponentId: subComponent.id
            }));
        });
        templates.data = transformedData

        if (res.data.code !== "200.200") {
            templates.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        templates.errMessage = err.message;
    }
    return templates;
}


//@ calling add course details type api ============================================================

async function addProjectTemplate(formData: any): Promise<ResponseType> {
    let url: string = createAPI.saveProjectComponent;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update course details type api ===============================================================

async function updateProjectTemplate(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateProjectSubComponent;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}



export const EqaCoreService = {
    getAllCollege,
    addCollege,
    updateCollege,
    getAllCampus,
    addCampus,
    updateCampus,
    getAllDepartment,
    addDepartment,
    updateDepartment,
    getAllProgram,
    addProgram,
    updateProgram,
    getAllProgramType,
    addProgramType,
    updateProgramType,
    getAllUniversity,
    addUniversity,
    updateUniversity,
    getAllProgramGoal,
    addProgramGoal,
    updateProgramGoal,
    getAllCourseDetails,
    addCourseDetails,
    updateCourseDetails,
    getAllProjectTemplate,
    updateProjectTemplate,
    addProjectTemplate
}