import { ICommitteeType } from "../model/Committee"
import readAPI from '../data/api/read'
import axiosInstance from '../../../Service/interceptor'
import createAPI from "../data/api/create"
import updateAPI from "../data/api/update"

export type committeeType = {
    data: ICommitteeType[],
    errMessage: ""
}


export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}


//@ calling get api ==============

async function getAllCommittee(): Promise<committeeType> {
    let url: string = readAPI.getCommittee;
    const committe: committeeType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        committe.data = res.data.obj.content;
        if (res.data.code !== "200.200") {
            committe.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        committe.errMessage = err.message;
    }
    return committe;
}


async function addCommittee(formData: any): Promise<ResponseType> {
    let url: string = createAPI.AddCommittee
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


//@ calling update course details type api ===============================================================

async function updateCommittee(formState: any): Promise<ResponseType> {
    let url: string = updateAPI.updateCommittee
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formState);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}


export const CommitteeService = {
    getAllCommittee,
    addCommittee,
    updateCommittee
}