import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import gulfCountry from "../../../../../../country.json"
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import useUpdateUniversity from '../customHooks/useUpdateUniversity';

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}

interface Country {
    name: string;
    capital: string;
    population: string;
    area_sq_km: string;
    currency: string;
    languages: string[];
    flag: string;
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateUniversity = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // newValues = {
        //     "roleId": values.roleId,
        //     "roleName": values.roleName,
        //     "roleDescription": values.roleDescription,
        //     "loggedInUser": loggedInUser,
        //     "active": values.active === "true" ? true : false
        // };

        /// Handling the data of the form

        const [countryList, setCountryList] = useState<Country[]>(gulfCountry.countries);
        const [countryDropDownOpen, setCountryDropDownOpen] = useState(false);
        const getOptionLabel = (option: Country) => option.name;


        const handleChange = (event: React.ChangeEvent<{}>, value: Country | null) => {
            if (value) {
                setCountry(value.name);
                if (countryError) {
                    setCountryError(false);
                }
            } else {
                console.log("No country selected.");
            }
        };

        // For field validation


        const [universityName, setUniversityName] = useState<string>(originalValues.universityName);
        const [universityEmailId, setUniversityEmailId] = useState<string>(originalValues.universityEmailId);
        const [universityPhoneNumber, setUniversityPhoneNumber] = useState<string>(originalValues.universityPhoneNumber);
        const [universityFaxId, setUniversityFaxId] = useState<string>(originalValues.universityFaxId);
        const [universityMission, setUniversityMission] = useState<string>(originalValues.universityMission);
        const [universityVision, setUniversityVision] = useState<string>(originalValues.universityVision);
        const [universityWebAddress, setUniversityWebAddress] = useState<string>(originalValues.universityWebAddress);
        const [addressLine1, setAddressLine1] = useState<string>(originalValues.addressLine1);
        const [addressLine2, setAddressLine2] = useState<string>(originalValues.addressLine2);
        const [addressLine3, setAddressLine3] = useState<string>(originalValues.addressLine3);
        const [zipCode, setZipCode] = useState<string>(originalValues.zipCode);
        const [province, setProvince] = useState<string>(originalValues.province);
        const [country, setCountry] = useState<string>(originalValues.country);

        // For handling errors

        const [universityNameError, setUniversityNameError] = useState<boolean>(false);
        const [universityEmailIdError, setUniversityEmailIdError] = useState<boolean>(false);
        const [universityPhoneNumberError, setUniversityPhoneNumberError] = useState<boolean>(false);
        const [universityFaxIdError, setUniversityFaxIdError] = useState<boolean>(false);
        const [universityMissionError, setUniversityMissionError] = useState<boolean>(false);
        const [universityVisionError, setUniversityVisionError] = useState<boolean>(false);
        const [universityWebAddressError, setUniversityWebAddressError] = useState<boolean>(false);
        const [addressLine1Error, setAddressLine1Error] = useState<boolean>(false);
        const [addressLine2Error, setAddressLine2Error] = useState<boolean>(false);
        const [addressLine3Error, setAddressLine3Error] = useState<boolean>(false);
        const [zipCodeError, setZipCodeError] = useState<boolean>(false);
        const [provinceError, setProvinceError] = useState<boolean>(false);
        const [countryError, setCountryError] = useState<boolean>(false);

        // For handling error messages
        const [universityNameErrorMessage, setUniversityNameErrorMessage] = useState("");
        const [universityWebAddressErrorMessage, setUniversityWebAddressErrorMessage] = useState("");
        const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
        const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");
        const [addressLine3ErrorMessage, setAddressLine3ErrorMessage] = useState("");
        const [universityEmailIdErrorMessage, setUniversityEmailIdErrorMessage] = useState("");
        const [universityPhoneNumberErrorMessage, setUniversityPhoneNumberErrorMessage] = useState("");
        const [universityFaxIdErrorMessage, setUniversityFaxIdErrorMessage] = useState("");
        const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState("")
        const [universityMissionErrorMessage, setUniversityMissionErrorMessage] = useState("")
        const [universityVisionErrorMessage, setUniversityVisionErrorMessage] = useState("")

        // Status radio buttons
        // const [status, setStatus] = useState(originalValues.active === true ? "Active" : "Deactive");

        // const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setStatus((event.target as HTMLInputElement).value);
        // };
        // Status radio buttons

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
          };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        const { updateUniversities, snackBarHandler, setSnackBarHandler } = useUpdateUniversity();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    // Set the validation errors
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const numberRegex = /^[0-9]{10}/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (universityFaxId === "") {
                        setUniversityFaxIdError(true);
                    }
                    if (!numberRegex.test(universityFaxId) || universityFaxId.length == 0 || universityFaxId.length > 10) {
                        setUniversityFaxIdErrorMessage("Please enter valid fax number")
                        setUniversityFaxIdError(true);
                    }
                    else {
                        setUniversityFaxIdError(false);
                        setUniversityFaxIdErrorMessage("")
                    }
                    if (universityEmailId === "") {
                        setUniversityEmailIdError(true);
                    }
                    if (!emailRegex.test(universityEmailId) && universityEmailId.length == 0) {
                        setUniversityEmailIdErrorMessage("Please enter valid email")
                        setUniversityEmailIdError(true)
                    }
                    else {
                        setUniversityEmailIdErrorMessage("")
                    }
                    if (universityMission === "") {
                        setUniversityMissionError(true)
                    }
                    if (universityVision === "") {
                        setUniversityVisionError(true)
                    }
                    if (universityPhoneNumber === "") {
                        setUniversityPhoneNumberError(true)
                    }
                    if (!numberRegex.test(universityPhoneNumber) || universityPhoneNumber.length == 0 || universityPhoneNumber.length > 10) {
                        setUniversityPhoneNumberErrorMessage("Please enter valid phone number")
                        setUniversityPhoneNumberError(true);
                    }
                    else {
                        setUniversityPhoneNumberError(false);
                        setUniversityPhoneNumberErrorMessage("")
                    }
                    if (universityName === "") {
                        setUniversityNameErrorMessage("Unversity Name is required")
                        setUniversityNameError(true);
                    }

                    if (!textRegex.test(universityMission) && universityMission.length !== 0) {
                        setUniversityMissionErrorMessage("Please Enter Only Alphabets")
                        setUniversityMissionError(true);
                    } else {
                        setUniversityMissionErrorMessage("")
                    }
                    if (!textRegex.test(universityVision) && universityVision.length !== 0) {
                        setUniversityVisionErrorMessage("Please Enter Only Alphabets")
                        setUniversityVisionError(true);
                    } else {
                        setUniversityVisionErrorMessage("")
                    }
                    if (universityWebAddress === "") {
                        setUniversityWebAddressError(true);
                    }
                    if (!urlRegex.test(universityWebAddress) && universityWebAddress.length !== 0) {
                        setUniversityWebAddressErrorMessage("Invalid URL format")
                        setUniversityWebAddressError(true);
                    }
                    else {
                        setUniversityWebAddressErrorMessage("")
                    }
                    if (addressLine1 === "") {
                        setAddressLine1Error(true);
                    }
                    if (!textRegex.test(addressLine1) && addressLine1.length !== 0) {
                        setAddressLine1ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine1Error(true);
                    } else {
                        setAddressLine1ErrorMessage("")
                    }
                    if (addressLine1.length > 100) {
                        setAddressLine1ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine1Error(true);
                    }
                    if (addressLine2 === "") {
                        setAddressLine2Error(true);
                    }
                    if (addressLine2.length > 100) {
                        setAddressLine2ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine2Error(true);
                    }
                    if (!textRegex.test(addressLine2) && addressLine2.length !== 0) {
                        setAddressLine2ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine2Error(true);
                    } else {
                        setAddressLine2ErrorMessage("")
                    }
                    if (addressLine3 === "") {
                        setAddressLine3Error(true);
                    }
                    if (addressLine3.length > 100) {
                        setAddressLine3ErrorMessage("Character Limit Exceeded Enter Less Than 100 Characters")
                        setAddressLine3Error(true);
                    }
                    if (!textRegex.test(addressLine3) && addressLine3.length !== 0) {
                        setAddressLine3ErrorMessage("Please Enter Only Alphabets")
                        setAddressLine3Error(true);
                    } else {
                        setAddressLine3ErrorMessage("")
                    }

                    if (zipCode === "") {
                        setZipCodeError(true)
                    }
                    if (province === "") {
                        setProvinceError(true);
                    }
                    if (country === "") {
                        setCountryError(true);
                    }
                    // Set the validation errors

                    if (
                        universityName !== "" && universityEmailId !== "" && universityMission !== "" && universityVision !== "" &&
                        universityFaxId !== "" && universityPhoneNumber !== "" && universityWebAddress !== "" && addressLine1 !== "" &&
                        addressLine2 !== "" && addressLine3 !== "" && province !== "" && country !== "" && zipCode !== ""
                    ) {
                        const formState = {
                            "universityId": originalValues.universityId,
                            "universityName": universityName,
                            "universityEmailId": universityEmailId,
                            "universityMission": universityMission,
                            "universityVision": universityVision,
                            "universityFaxId": universityFaxId,
                            "universityPhoneNumber": universityPhoneNumber,
                            "universityWebAddress": universityWebAddress,
                            "addressLine1": addressLine1,
                            "addressLine2": addressLine2,
                            "addressLine3": addressLine3,
                            "province": province,
                            "zipCode": zipCode,
                            "country": country,
                            "active": isChecked
                        };

                        await updateUniversities(formState, currentRowIndex)
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                            setFetchUpdate(true);
                        }, 3000);

                        
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                {/* <div style={{ marginTop: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} className={`${(windowSize[0] > 990) ? ("d-flex justify-content-between") : ("d-flex flex-column justify-content-start")}`}>
                <div>
                    {(currentLang === "ar") ? (
                        <>
                            <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span> / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / EQA
                        </>
                    ) : (
                        <>
                            EQA / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span>
                        </>
                    )}
                </div>
                <div>
                    <span style={{ color: "#4f747a", paddingRight: 10 }}>{currentFormatedDate}</span>
                </div>
            </div> */}

                {/* <hr /> */}

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    {/* <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        // border: "1px solid black",
                        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <PeopleOutlineIcon
                            sx={{
                                color: "#4f747a",
                                fontSize: 35,
                                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;", 
                            }} />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.subTitle')}
                        </Typography>
                    </Box>
                </Box> */}

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }
                        >

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityNameTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityName.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityNameError}
                                    helperText={universityNameErrorMessage}
                                    value={universityName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityNameError) {
                                            setUniversityNameError(false);
                                        }
                                        setUniversityName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityEmailIdTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityEmailId.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityEmailId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityEmailIdError}
                                    helperText={universityEmailIdErrorMessage}
                                    value={universityEmailId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityEmailIdError) {
                                            setUniversityEmailIdError(false);
                                        }
                                        setUniversityEmailId(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityWebAddressTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityWebAddress.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityWebAddress.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityWebAddressError}
                                    helperText={universityWebAddressErrorMessage}
                                    value={universityWebAddress}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityWebAddressError) {
                                            setUniversityWebAddressError(false);
                                        }
                                        setUniversityWebAddress(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityPhoneNumberTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityPhoneNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityPhoneNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityPhoneNumberError}
                                    helperText={universityPhoneNumberErrorMessage}
                                    value={universityPhoneNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityPhoneNumberError) {
                                            setUniversityPhoneNumberError(false);
                                        }
                                        setUniversityPhoneNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityFaxIdTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityFaxId.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityFaxId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityFaxIdError}
                                    helperText={universityFaxIdErrorMessage}
                                    value={universityFaxId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityFaxIdError) {
                                            setUniversityFaxIdError(false);
                                        }
                                        setUniversityFaxId(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityMissionTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityMission.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityMission.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityMissionError}
                                    helperText={universityMissionErrorMessage}
                                    value={universityMission}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityMissionError) {
                                            setUniversityMissionError(false);
                                        }
                                        setUniversityMission(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="universityVisionTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.UniversityVision.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.UniversityVision.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={universityVisionError}
                                    helperText={universityVisionErrorMessage}
                                    value={universityVision}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (universityVisionError) {
                                            setUniversityVisionError(false);
                                        }
                                        setUniversityVision(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine1TextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.AddressLine1.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.AddressLine1.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine1Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine1}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine1Error) {
                                            setAddressLine1Error(false);
                                        }
                                        setAddressLine1(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine2TextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.AddressLine2.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.AddressLine2.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine2Error}
                                    helperText={addressLine2ErrorMessage}
                                    value={addressLine2}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine2Error) {
                                            setAddressLine2Error(false);
                                        }
                                        setAddressLine2(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine3TextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.AddressLine3.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.AddressLine3.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine3Error}
                                    helperText={addressLine3ErrorMessage}
                                    value={addressLine3}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine3Error) {
                                            setAddressLine3Error(false);
                                        }
                                        setAddressLine3(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="zipCodeTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.ZipCode.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.ZipCode.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={zipCodeError}
                                    helperText={zipCodeErrorMessage}
                                    value={zipCode}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (zipCodeError) {
                                            setZipCodeError(false);
                                        }
                                        setZipCode(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="provinceTextField"
                                    label={t('Home.Header.Modals.University.policy.Inputs.Province.label')}
                                    placeholder={`${t('Home.Header.Modals.University.policy.Inputs.Province.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={provinceError}
                                    // helperText={provinceErrorMessage}
                                    value={province}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (provinceError) {
                                            setProvinceError(false);
                                        }
                                        setProvince(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    // {...countryDefaultProps}
                                    options={countryList}
                                    getOptionLabel={getOptionLabel}
                                    id="countryAutoComplete"
                                    autoHighlight
                                    open={countryDropDownOpen}
                                    value={countryList.find((Country) => Country.name === country)}
                                    onClick={() => {
                                        setCountryDropDownOpen(true);
                                    }}
                                    onClose={() => {
                                        setCountryDropDownOpen(!countryDropDownOpen);
                                    }}
                                    onChange={handleChange}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    renderInput={(params: any) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.University.policy.Inputs.Country.label')}
                                            variant="standard"
                                            placeholder='Select Country ...'
                                            helperText={(countryError) ? ("* Please select any Country.") : ("")}
                                            error={countryError}
                                            onClick={() => {
                                                setCountryDropDownOpen(true);
                                            }}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />

                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>



                        </Grid>
                    </Box>
                </Box>

                {/* <Box
                sx={{
                    display: "flex",
                    flexDirection: (currentLang === "ar") ? ('row-reverse') : ('row')
                }}
                dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
            >
                <Button
                    dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    variant="contained"
                    sx={{
                        backgroundColor: "#d5b254",
                        // textTransform: "none",
                        fontWeight: "bold",
                        height: 40,
                        mt:
                        // Give margins based on screen size
                        {
                            xs: 3, // theme.breakpoints.up('xs')
                            sm: 2, // theme.breakpoints.up('sm')
                            md: 2, // theme.breakpoints.up('md')
                            lg: 3, // theme.breakpoints.up('lg')
                            xl: 3, // theme.breakpoints.up('xl')
                        },
                        display: "flex",
                        // justifyContent: (currentLang === "ar") ? ('flex-start') : ('center'),
                        // alignItems:(currentLang === "ar") ? ('flex-end') : ('center'),
                        "&:hover": {
                            backgroundColor: "#d5b254",
                        },
                        // border:"1px solid red"
                    }}
                    // Give full width if screen size if less than 600px otherwise give auto width
                    fullWidth={(
                        windowSize[0] < 600
                    ) ? true : false}
                    // onClick={() => {
                    //     navigate("/");
                    // }}
                    startIcon={
                        (currentLang === "ar") ? (
                            null
                        ) : (
                            <SendIcon />
                        )
                    }
                    endIcon={
                        (currentLang === "ar") ? (
                            <SendIcon />
                        ) : (
                            null
                        )
                    }
                    onClick={submitForm}
                >
                    <Typography
                        style={{ display: "block" }}
                        dir={(currentLang === "ar") ? ('rtl') : ('ltr')}
                    >
                        {t('Home.Sidebar.list.userManagement.subMenu.Users.details.submit')}
                    </Typography>
                </Button>
            </Box> */}

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateUniversity;