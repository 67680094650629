import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../data/api/read';

import { columnValues } from '../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import useUpdateProjectTemplate from '../customHooks/useUpdateProjectTemplate';
interface ViewAllSubComponentData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        id: string;
        subComponentName: string;
        clos: string;
        maxMarks: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateProjectSubComponent = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // Fetching data using axios

        const [viewAllSubComponentData, setViewAllSubComponentData] = useState<ViewAllSubComponentData | null>(null);

        const [subComponentName, setSubComponentName] = useState<string>(originalValues.subComponentName);
        const [clos, setClos] = useState<string>(originalValues.clos);
        const [maxMarks, setMaxMarks] = useState<string>(originalValues.maxMarks);

        // For handling errors

        const [subComponentNameError, setSubComponentNameError] = useState<boolean>(false);
        const [closError, setClosError] = useState<boolean>(false);
        const [MaxMarksError, setMaxMarksError] = useState<boolean>(false);

        // For handling error messages
        const [subComponentNameErrorMessage, setSubComponentNameErrorMessage] = useState<string>("");
        const [closErrorMessage, setClosErrorMessage] = useState<string>("");
        const [MaxMarksErrorMessage, setMaxMarksErrorMessage] = useState<string>("");

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            setIsChecked(!isChecked);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Program, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllSubComponentData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const { updateProjectTemplates, snackBarHandler, setSnackBarHandler } = useUpdateProjectTemplate();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllSubComponentData !== null && accessToken !== null) {
                    // Set the validation errors
                    const textRegex = /^[\w,;'".?\d\s]+$/;

                    if (subComponentName === "") {
                        setSubComponentNameError(true);
                    }

                    if (clos === "") {
                        setClosError(true);
                    }
                    if (maxMarks === "") {
                        setMaxMarksError(true);
                    }
                    // Set the validation errors

                    if (subComponentName !== "" && maxMarks !== "" && clos !== "") {
                        const formState = {
                            "id": originalValues.id,
                            "subComponentName": subComponentName,
                            "maxMarks": maxMarks,
                            "clos": clos,
                            "active": isChecked
                        };

                        await updateProjectTemplates(formState, currentRowIndex, subComponentName);
                        setFetchUpdate(true);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                        }, 3000);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `${subComponentName} data has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             if (response.data.code === "200.200") {
                        //                 setFetchUpdate(true);
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error) {
                        //         console.log('error=>>>', error);
                        //     });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xs={12}>
                                <TextField
                                    id="subComponentNameTextField"
                                    label={t('Home.Header.Modals.ProjectTemplate.policy.Inputs.SubComponentName.label')}
                                    placeholder={`${t('Home.Header.Modals.ProjectTemplate.policy.Inputs.SubComponentName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={subComponentNameError}
                                    helperText={subComponentNameErrorMessage}
                                    value={subComponentName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (subComponentNameError) {
                                            setSubComponentNameError(false);
                                        }
                                        setSubComponentName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="ClosTextField"
                                    label={t('Home.Header.Modals.ProjectTemplate.policy.Inputs.CLOs.label')}
                                    placeholder={`${t('Home.Header.Modals.ProjectTemplate.policy.Inputs.CLOs.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={closError}
                                    helperText={closErrorMessage}
                                    value={clos}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (closError) {
                                            setClosError(false);
                                        }
                                        setClos(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="MaxMarksTextField"
                                    label={t('Home.Header.Modals.ProjectTemplate.policy.Inputs.MaxMarks.label')}
                                    placeholder={`${t('Home.Header.Modals.ProjectTemplate.policy.Inputs.MaxMarks.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={MaxMarksError}
                                    helperText={MaxMarksErrorMessage}
                                    value={maxMarks}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (MaxMarksError) {
                                            setMaxMarksError(false);
                                        }
                                        setMaxMarks(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>


                        </Grid>
                    </Box>
                </Box>

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateProjectSubComponent;