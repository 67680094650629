interface ManageCourseState {
    courseDetails: any | null;
    projectTemplate: any | null;
    loading: any;
}

const initialState: ManageCourseState = {
    courseDetails: null,
    projectTemplate: null,
    loading: false
};

const manageCourseReducer = (state = initialState, action: any): ManageCourseState => {
    switch (action.type) {
        //COURSE DETAILS
        case 'ADD_COURSE_DETAILS_DATA':
            return {
                ...state,
                courseDetails: [...state.courseDetails, action.payload.data]
            }
        case 'UPDATE_COURSE_DETAILS_DATA':
            return {
                ...state,
                courseDetails: state.courseDetails.map((courseDetails: any, index: any) =>
                    index === action.payload.index ? { ...courseDetails, ...action.payload.updatedCourseDetails } : courseDetails
                ),
            };
        case 'DELETE_COURSE_DETAILS_DATA':
            return {
                ...state,
                courseDetails: state.courseDetails.filter((_: any, idx: number) => idx !== action.payload.index),
            };
        case 'FETCH_COURSE_DETAILS_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                courseDetails: action.payload,
            };

        //PROJEC TEMPLATE

        case 'ADD_PROJECT_TEMPLATE_DATA':
            return {
                ...state,
                projectTemplate: [...state.projectTemplate, action.payload.data]
            }
        case 'UPDATE_PROJECT_TEMPLATE_DATA':
            return {
                ...state,
                projectTemplate: state.projectTemplate.map((projectTemplate: any, index: any) =>
                    index === action.payload.index ? { ...projectTemplate, ...action.payload.updatedCourseDetails } : projectTemplate
                ),
            };
        case 'DELETE_PROJECT_TEMPLATE_DATA':
            return {
                ...state,
                projectTemplate: state.projectTemplate.filter((_: any, idx: number) => idx !== action.payload.index),
            };
        case 'FETCH_PROJECT_TEMPLATE_DATA_SUCCESS':
            return {
                ...state,
                loading: false,
                projectTemplate: action.payload,
            };

        // case 'PROJECT_TEMPLATE':
        //     return {
        //         ...state,
        //         courseDetails: action.payload.data,
        //     };
        // case 'PROJECT_TEMPLATE':
        //     return {
        //         ...state,
        //         projectTemplate: action.payload.data
        //     }
        default:
            return state;
    }
};

export default manageCourseReducer;