import { useState } from "react";
import { AccountService } from "../../service/AccountService";
import { useDispatch } from "react-redux";
import { updateApplicationRedux } from "../../../../../../redux/action/accountActions";

const useUpdateAppData = () => {
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const dispatch = useDispatch();
    const updateAppData = async (accessToken: any, formState: any, currentRowIndex: any) => {
        try {
            const response: any = await AccountService.updateApps(accessToken, formState);
            if (response) {
                dispatch(updateApplicationRedux(currentRowIndex, formState))
            }
            else {
                console.log(response.errMessage);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return { updateAppData, snackBarHandler, setSnackBarHandler };
};

export default useUpdateAppData;