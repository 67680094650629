import { useState } from "react";
import { AccountService } from "../../service/AccountService";
import { useDispatch } from "react-redux";
import { updateAppFormRedux } from "../../../../../../redux/action/accountActions";

const useUpdateAppFormData = () => {
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const dispatch = useDispatch();
    const updateAppFormData = async (accessToken: any, formState: any, currentRowIndex: any) => {
        try {
            const response: any = await AccountService.updateAppForm(accessToken, formState);
            if (response) {
                dispatch(updateAppFormRedux(currentRowIndex, formState));
                console.log(response);
            }
            else {
                console.log(response.errMessage)
            }
        } catch (error) {
            console.log(error)
        }
    };

    return { updateAppFormData, snackBarHandler, setSnackBarHandler };
};

export default useUpdateAppFormData;