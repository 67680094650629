export const ADD_STRATEGIC_PLAN = 'ADD_STRATEGIC_PLAN';
export const SET_STRATEGIC_PLANS = 'SET_STRATEGIC_PLANS';
export const ADD_INITIATIVE = 'ADD_INITIATIVE';
export const SET_INITIATIVE = 'SET_INITIATIVE';
export const ADD_KPI = 'ADD_KPI';
export const SET_KPI = 'SET_KPI';
export const ADD_KPI_VALUE = 'ADD_KPI_VALUE';
export const SET_KPI_VALUE = 'SET_KPI_VALUE';
export const ADD_SUBGOAL = 'ADD_SUBGOAL';
export const SET_SUBGOAL = 'SET_SUBGOAL';
export const REMOVE_STRATEGIC_PLAN = 'REMOVE_STRATEGIC_PLAN';
export const UPDATE_STRATEGIC_PLAN = 'UPDATE_STRATEGIC_PLAN';
export const REMOVE_INITIATIVE = 'REMOVE_INITIATIVE';
export const UPDATE_INITIATIVE = 'UPDATE_INITIATIVE';
export const REMOVE_KPI = 'REMOVE_KPI';
export const REMOVE_KPI_VALUE = 'REMOVE_KPI_VALUE';
export const UPDATE_KPI = 'UPDATE_KPI';
export const UPDATE_KPI_VALUE = 'UPDATE_KPI_VALUE';
export const ADD_GOAL = 'ADD_GOAL';
export const REMOVE_GOAL = 'REMOVE_GOAL';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const CLEAR_STRATEGIC_PLANS = 'CLEAR_STRATEGIC_PLANS';
export const CLEAR_KPI = 'CLEAR_KPI';
export const CLEAR_KPI_VALUE = 'CLEAR_KPI_VALUE';
export const CLEAR_INITIATIVE = 'CLEAR_INITIATIVE';
export const CLEAR_GOAL = 'CLEAR_GOAL';
export const ADD_STRATEGIC_PLAN_DETAILED = 'ADD_STRATEGIC_PLAN_DETAILED';
export const CLEAR_STRATEGIC_PLAN_DETAILED = 'CLEAR_STRATEGIC_PLAN_DETAILED';
export const SET_HISTORY = 'SET_HISTORY';
export const CLEAR_HISTORY = 'CLEAR_HISTORY';

interface Row {
  kpiName: string;
  description: string;
  degree: string;
}

// interface Rowb {
//   program: any;
//   kpiName: any;
//   programId: any;
//   kpiId: any;
//   target: any;
//   benchMark: any;
//   newTarget: any;
//   minimumTarget: any;
// }


export const addStrategicPlan = (data: any) => ({
  type: ADD_STRATEGIC_PLAN,
  payload: { data }
});

export const clearStrategicPlans = () => ({
  type: CLEAR_STRATEGIC_PLANS
});

export const clearKPI = () => ({
  type: CLEAR_KPI
});

export const clearKPIValue = () => ({
  type: CLEAR_KPI_VALUE
});

export const clearInitiative = () => ({
  type: CLEAR_INITIATIVE
});


export const setStrategicPlans = (data: any[]) => ({
  type: SET_STRATEGIC_PLANS,
  payload: { data }
});

export const addInitiative = (data: any) => ({
    type: ADD_INITIATIVE,
    payload: {data}
})

export const setInitiative = (data: any) => ({
    type: SET_INITIATIVE,
    payload: {data}
})

export const addKPI = (data: Row[]) => ({
  type: ADD_KPI,
  payload: {data}
})

export const setKPI = (data: Row[]) => ({
  type: SET_KPI,
  payload: {data}
})

export const removeKpi = (index: number) => ({
  type: REMOVE_KPI,
  payload: index,
});

export const updateKpiByIndex = (index: number, updatedKpi: any) => ({
  type: UPDATE_KPI,
  payload: {index, updatedKpi}
});

export const addKpiValue = (data: any) => ({
  type: ADD_KPI_VALUE,
  payload: {data}
});

export const setKpiValue = (data: any) => ({
  type: SET_KPI_VALUE,
  payload: {data}
});

export const removeKpiValue = (index: number) => ({
  type: REMOVE_KPI_VALUE,
  payload: index,
});

export const updateKpiValueByIndex = (index: number, updatedKpiValue: any) => ({
  type: UPDATE_KPI_VALUE,
  payload: {index, updatedKpiValue}
});

export const removeStrategicPlanByIndex = (index: number) => ({
  type: REMOVE_STRATEGIC_PLAN,
  payload: index,
});

export const updateStrategicPlanByIndex = (updatedPlan: any) => ({
  type: UPDATE_STRATEGIC_PLAN,
  payload: { updatedPlan },
});

export const removeInitiative = (index: number) => ({
  type: REMOVE_INITIATIVE,
  payload: index,
});

export const updateInitiativeByIndex = (index: number, updatedInitiative: any) => ({
  type: UPDATE_INITIATIVE,
  payload: {index, updatedInitiative}
});

export const addGoal = (data: any) => ({
  type: ADD_GOAL,
  payload: { data }
});

export const removeGoal = (index: number) => ({
  type: REMOVE_GOAL,
  payload: index
})

export const updateGoalByIndex = (index: number, updatedGoal: any) => ({
  type: UPDATE_GOAL,
  payload: { index, updatedGoal },
});

export const clearGoal = () => ({
  type: CLEAR_GOAL
});

export const addStrategicPlanDetailed = (data: any) => ({
  type: ADD_STRATEGIC_PLAN_DETAILED,
  payload: { data }
});

export const clearStrategicPlanDetailed = () => ({
  type: CLEAR_STRATEGIC_PLAN_DETAILED
});

export const setHistoryDetail = (data: any) => ({
  type: SET_HISTORY,
  payload: { data }
})

export const clearHistoryDetail = () => ({
  type: CLEAR_HISTORY
});

