import { BASE_URL } from "../../../../../constants";
const createAPI = {
  
    AddUniversity: `${BASE_URL}/EQACORE-SERVICE/saveUniversity`,
    AddCollege: `${BASE_URL}/EQACORE-SERVICE/saveCollege`,
    AddCampus: `${BASE_URL}/EQACORE-SERVICE/saveCampus`,
    AddDepartment: `${BASE_URL}/EQACORE-SERVICE/saveDepartment`,
    AddProgram: `${BASE_URL}/EQACORE-SERVICE/saveProgram`,
    AddProgramType: `${BASE_URL}/EQACORE-SERVICE/saveProgramType`,
    AddProgramObjective: `${BASE_URL}/EQACORE-SERVICE/saveProgramObjective`,
    AddCourse : `${BASE_URL}/EQACORE-SERVICE/saveCourse`,
    AddCourseType : `${BASE_URL}/EQACORE-SERVICE/saveCourseType`,
    AddCourseObjective : `${BASE_URL}/EQACORE-SERVICE/saveCourseObjective`,
    AddCourseBook : `${BASE_URL}/EQACORE-SERVICE/saveCourseBook`,
    AddBook : `${BASE_URL}/EQACORE-SERVICE/saveBook`,
    AddTopics : `${BASE_URL}/EQACORE-SERVICE/saveCourseTopic`,
    AddCourseClo: `${BASE_URL}/EQACORE-SERVICE/saveCourseClo`,
    AddCloSoMapping : `${BASE_URL}/EQACORE-SERVICE/saveCloSoMapping`,
    AddAssessment: `${BASE_URL}/EQACORE-SERVICE/assessment/saveAssessment`,
    AddAssessmentType: `${BASE_URL}/EQACORE-SERVICE/assessment/saveAssessmentType`,
    saveOverAllGrade: `${BASE_URL}/EQACORE-SERVICE/assessment/saveOverAllGrade`,
    saveProjectComponent: `${BASE_URL}/EQACORE-SERVICE/configuration/projectComponent`,
    AddAssessmentMethod : `${BASE_URL}/EQACORE-SERVICE/saveAssessmentMethod`,
    AddCoordinator: `${BASE_URL}/EQACORE-SERVICE/core/coordinator`
  };
  
  export default createAPI;
  