import axios from 'axios';
import readAPI from '../data/api/read';
import {IGetAllMeetingDetails, IAllLocation, IMeetingDetailsCriteria} from "../model/Mom"
import createAPI from '../data/api/create';
import axiosInstance from '../../../Service/interceptor';
import updateAPI from '../data/api/update';


export type AllMeetingDetailsType = {
    data: IGetAllMeetingDetails[],
    errMessage: string
}

export type AllMeetingDetailsByCriteria = {
    data: IMeetingDetailsCriteria[],
    errMessage: string
}

export type AllLocation = {
    data: IAllLocation[],
    errMessage: string
}


export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}

//@function for fetching all meeting details list type
async function getAllMeetingDetails(accessToken: string): Promise<AllMeetingDetailsType> {
    let url: string = readAPI.AllMeetingDetailsListType;
    let header: string = accessToken;
    let allMeetingDetailsListType: AllMeetingDetailsType = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header,
            "Accept-Language": "en"
        }
    })
        .then((res) => {
            console.log("response details", res)
            allMeetingDetailsListType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                allMeetingDetailsListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            allMeetingDetailsListType.errMessage = err.message;
        })
    return allMeetingDetailsListType;
}


//@function for fetching all meeting details list type
async function getAllMeetingByCriteria(accessToken: string, searchKey: any, columnName: any): Promise<AllMeetingDetailsByCriteria> {
    let url: string = readAPI.AllMeetingDetailsByCriteria;
    let header: string = accessToken;
    let allMeetingDetailsListType: AllMeetingDetailsByCriteria = {
        data: [],
        errMessage: ""
    }
    let formData = {
        "searchKey" : searchKey,
        "columns":[columnName]
    }
    await axios.post(`${url}`, formData, {
        headers: {
            "x-api-key": header,
            "Accept-Language": "en"
        }
    })
        .then((res) => {
            console.log("response details", res)
            allMeetingDetailsListType.data = res.data.obj;
            if (res.data.code !== "200.200") {
                allMeetingDetailsListType.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            allMeetingDetailsListType.errMessage = err.message;
        })
    return allMeetingDetailsListType;
}

//@function for fetching all meeting details list type
async function getAllLocation(accessToken: string): Promise<AllLocation> {
    let url: string = readAPI.AllLocation;
    let header: string = accessToken;
    let allLocation: AllLocation = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}`, {
        headers: {
            "x-api-key": header,
            "Accept-Language": "en"
        }
    })
        .then((res) => {
            console.log("response location", res.data.obj)
            allLocation.data = res.data.obj;
            if (res.data.code !== "200.200") {
                allLocation.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            allLocation.errMessage = err.message;
        })
    return allLocation;
}


//@function for fetching mom by meeting id
async function getMom(accessToken: string, meetingId: any) {
    let url: string = readAPI.GetMOMByMeetingId;
    let header: string = accessToken;
    let meeting = {
        data: [],
        errMessage: ""
    }
    await axios.get(`${url}/${meetingId}`, {
        headers: {
            "x-api-key": header,
            "Accept-Language": "en"
        }
    })
        .then((res) => {
            console.log("response location", res.data.obj)
            meeting.data = res.data.obj;
            if (res.data.code !== "200.200") {
                meeting.errMessage = res.data.error;
            }
        })
        .catch((err) => {
            console.log(err);
            meeting.errMessage = err.message;
        })
    return meeting;
}



async function addMeeting(formData: any): Promise<ResponseType> {
    let url: string = createAPI.CreateMeeting
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

async function updateMeeting(formData: any): Promise<ResponseType> {
    let url: string = updateAPI.UpdateMeeting
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, formData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }
    return userResponse;
}

export const MomService = {
    getAllMeetingDetails,
    getAllMeetingByCriteria,
    getAllLocation,
    getMom,
    addMeeting,
    updateMeeting
}