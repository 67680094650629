import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import { FaPlus, FaWindowClose } from "react-icons/fa";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Chip, InputAdornment
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import initiative from "../../../../../assets/Images/strategicPlan/initiative.jpeg"
import { updateGoalByIndex, updateInitiativeByIndex } from '../../../../../redux/action/spsActions';
import { MdOutlineAddTask } from "react-icons/md";
import goal1 from "../../../../../../src/assets/Images/strategicPlan/goal2.jpg"
import { styled } from '@mui/material';
import CustomKpiModalUpdate from '../KpiModalUpdate';
import CustomKpiModalSubGoalUpdate from '../KpiModalSubGoalUpdate';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: '#3C6766',
    color: theme.palette.common.white,
    fontWeight: 'bold',
}));

interface InitiativeNameOption {
    label: string;
    value: string;
    initiativeName: string;
}

interface OptionType {
    label: string;
    value: string;
}

interface Row {
    goalName: string;
    goalDescription: string;
    manager: string;
    mappedInitiative: string;
    mappedKpi: string;
}

interface InstructorNameOption {
    label: string;
    value: string;
    userName: string;
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    setForUpdate: any,
    currentRowIndex: any,
    goalDatas: any,
    goalMappingToKpi: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateGoal = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        setForUpdate,
        currentRowIndex,
        goalDatas,
        goalMappingToKpi
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // console.log("originalValues", originalValues)

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const [checked, setChecked] = useState(false);
        const [checkedGoalMapping, setCheckedGoalMapping] = useState(false);
        
        const [selectedKpiSubGoal, setSelectedKpiSubGoal] = useState<any>([]);
        const [rowIndex, setRowIndex] = useState<any>();

        const [modalOpen, setModalOpen] = useState(false);
        const [modalOpenSubGoal, setModalOpenSubGoal] = useState(false);

        const [goalNames, setGoalName] = useState<string>(originalValues.goalName);
        const [goalDescriptions, setGoalDescription] = useState<string>(originalValues.goalDescription);
        const [goalManager, setGoalManager] = useState<string>(originalValues.goalManager);
        const [initiatives, setInitiative] = useState<any[]>(originalValues.initiative || []);
        const [mappedKpis, setMappedKpi] = useState<any>(originalValues.mappedKpi || []);
        const [subGoal, setSubGoal] = useState<any>(originalValues.subGoal);

        // error state
        const [goalNameError, setGoalNameError] = useState<boolean>(false);
        const [goalDescriptionError, setGoalDescriptionError] = useState<boolean>(false);
        const [goalManagerError, setGoalManagerError] = useState<boolean>(false);
        const [initiativeError, setInitiativeError] = useState<boolean>(false);

        // error message state

        const [goalNameErrorMessage, setGoalNameErrorMessage] = useState("");
        const [goalDescriptionErrorMessage, setGoalDescriptionErrorMessage] = useState("");
        const [goalManagerErrorMessage, setGoalManagerErrorMessage] = useState("");
        const [initiativeErrorMessage, setInitiativeErrorMessage] = useState("");
        const [inputValue, setInputValue] = useState('');

        const kpis = useSelector((state: RootState) => state.sps.strategicPlanDetailed[0]?.data?.kpiDetails);
        const findKpiById = (id: any) => kpis?.find((kpi: any) => kpi.id === id);
        const [rows, setRows] = useState<Row[]>(
            // subGoal
            //     ? subGoal
            subGoal
                ? subGoal.map((goal: any) => ({
                    ...goal,
                    mappedKpi: goal.mappedKpi.map((kpiId: any) => findKpiById(kpiId) || {}), // Map KPI IDs to full KPI objects
                }))
                : [{ goalName: '', goalDescription: '', manager: '', mappedInitiative: [], mappedKpi: [] }]
        );

        const [errors, setErrors] = useState<any[]>([]);

        // console.log("selectedKpiSubGoal", rows)
        // console.log("initiatives", initiatives)
        // console.log("mappedKpis", mappedKpis)

        // console.log("rowsrowsrowsrows", rows)
        let instructorName = useSelector((state: RootState) => state.userListData.data);
        const instructorNameOptions = instructorName?.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userId,
            userName: item.userName
        }));


        const handleGoalManagerChange = (event: any, newValue: any) => {
            setGoalManager(newValue ? newValue.value : null);
            if (newValue) {
                setGoalManagerError(false);
                setGoalManagerErrorMessage("");
            }
        }

        useEffect(() => {
            if (rowIndex !== null) {
                setRows(prevRows => {
                    const newRows = [...prevRows];
                    newRows[rowIndex] = {
                        ...newRows[rowIndex],
                        mappedKpi: selectedKpiSubGoal?.map((item: any) => ({
                            id: item.id,
                            kpiName: item.kpiName
                        }))
                    };
                    return newRows;
                });
            }
        }, [selectedKpiSubGoal]);


        // console.log("selectedKpiSubGoal", selectedKpiSubGoal);

        const handleOpenKPIs = (index: any) => {
            if (rows[index]) {
                setSelectedKpiSubGoal(rows[index].mappedKpi || []);
            }
            setModalOpenSubGoal(true);
            setRowIndex(index);
        };

        const handleCloseSubGoal = () => {
            setModalOpenSubGoal(false);
        }

        // const handleCheckboxKpiChangeSubGoal = (item: any) => {
        //     setSelectedKpiSubGoal((prevSelected: any) => {
        //         const isSelected = prevSelected.some((selected: any) => selected.id === item.id);
        //         if (isSelected) {
        //             // Remove from selected
        //             return prevSelected.filter((selected: any) => selected.id !== item.id);
        //         } else {
        //             // Add to selected
        //             return [...prevSelected, item];
        //         }
        //     });
        // };

        // const handleCheckboxKpiChangeSubGoal = (item: any) => {
        //     setSelectedKpiSubGoal((prevSelected: any) => {
        //         const isSelected = prevSelected.some((selected: any) => selected === item.id);
        //         if (isSelected) {
        //             // Remove from selected
        //             return prevSelected.filter((selected: any) => selected.id !== item.id);
        //         } else {
        //             // Add to selected
        //             return [...prevSelected, item]; // Push the whole object or ID based on your choice
        //         }
        //     });
        // };

        const handleCheckboxKpiChangeSubGoal = (kpiObject: any) => {
            // If no KPI object is found, exit
            if (!kpiObject) return;
        
            // Update the selected KPIs in state
            setSelectedKpiSubGoal((prevSelected: any) => {
                const isSelected = prevSelected.some((selected: any) => selected.id === kpiObject.id);
        
                if (isSelected) {
                    // Remove the KPI object from the selected list
                    return prevSelected.filter((selected: any) => selected.id !== kpiObject.id);
                } else {
                    // Add the full KPI object to the selected list
                    return [...prevSelected, kpiObject];
                }
            });
        };



        let initialInitiative: any[] = [];
        if (typeof initiative === 'string') {
            initialInitiative = initiative.split(',');
        } else if (Array.isArray(initiative)) {
            initialInitiative = initiative;
        }

        useEffect(() => {
            setInitiative(originalValues.initiative);
        }, [originalValues.initiative]);

        // Handle changes in the Autocomplete selection
        const handleChange = (
            event: React.SyntheticEvent,
            newValue: OptionType[]
        ) => {
            const ids: any = newValue.map(option => option.value);
            setInitiative(ids);
            // handleInitiativeChange(ids);
            // if (newValue.length > 0) {
            //     // Clear any error messages
            // }
        };


        const handleOpenAddInitiativeModal = () => {
            // setModalAddInitiativeModal(true);
        }

        const handleCheckboxChange = (event: any) => {
            setChecked(event.target.checked);
        };

        const handleCheckboxChangeGoalMapping = (event: any) => {
            setCheckedGoalMapping(event.target.checked);
        };


        const handleFieldChange = (index: any, field: any, value: any) => {
            const newRows: any = [...rows];
            if (field === 'mappedInitiative') {
                newRows[index][field] = value; // Value is already mapped to an array of IDs
            } else {
                newRows[index][field] = value;
            }
            setRows(newRows);
            // validateFields(index);
        };

        const handleAddRow = () => {
            const newRows: any = [...rows, { goalName: '', goalDescription: '', manager: '', mappedKpi: '', mappedInitiative: '' }];
            setRows(newRows);
            setErrors([...errors, { goalName: '', goalDescription: '', manager: '', mappedKpi: '', mappedInitiative: '' }]);
        };

        const handleDeleteRow = (index: any) => {
            const newRows = rows.filter((_, i) => i !== index);
            const newErrors = errors.filter((_, i) => i !== index);
            setRows(newRows);
            setErrors(newErrors);
        };

        // const [checkedKPIs, setCheckedKPIs] = useState<Record<string, boolean>>({});
        // const [isKpiError, setIsKpiError] = useState(false);


        useEffect(() => {
            if (subGoal?.length > 0) {
                setChecked(true);
            }
        }, [originalValues.subGoal])



        const handleOpen = () => setModalOpen(true);
        const handleClose = () => {
            setModalOpen(false)
            // setSelectedKPIs([])
        }


        // const handleCheckboxKpiChange = (event: any) => {
        //     const { name, checked } = event.target;
        //     setMappedKpi((prevState: any) => ({
        //         ...prevState,
        //         [name]: checked
        //     }));

        //     if (checked) {
        //         setMappedKpi((prev: any) => [...prev, name]);
        //     } else {
        //         setMappedKpi((prev: any) => prev.filter((item: any) => item !== name));
        //     }
        // };

        const handleCheckboxKpiChange = (kpi: any) => {
            setMappedKpi((prevSelected: any) => {
                if (prevSelected.includes(kpi)) {
                    return prevSelected.filter((item: any) => item !== kpi); // Remove if already selected
                } else {
                    return [...prevSelected, kpi]; // Add if not selected
                }
            });
        };


        let initiativeList = useSelector((state: RootState) => state.sps.strategicPlanDetailed[0]?.data?.initiatives);
        let kpiList = useSelector((state: RootState) => state.sps.strategicPlanDetailed[0]?.data?.kpiDetails);
        const kpiMap: any = {}
        kpiList?.forEach((item: any) => {
            kpiMap[item.id] = item.kpiName;
        })


        const initiativeOptions = initiativeList && initiativeList?.map((item: any) => ({
            label: `${item.initiativeName}`,
            value: item.id,
            initiativeName: item.initiativeName
        }))
        const handleInitiativeChange = (event: any, newValue: any[]) => {
            const newIds = newValue.map((option: any) => option.value);
            setInitiative(newIds);
            if (newValue.length > 0) {
                setInitiativeError(false);
                setInitiativeErrorMessage("");
            }
        };


        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasErrors = false;


                        return !hasErrors;
                    };

                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    } else {
                        const formState = {
                            "goalName": goalNames,
                            "goalDescription": goalDescriptions,
                            "manager": goalManager,
                            "mappedInitiative": initiatives,
                            "mappedKpi": mappedKpis,
                            "strategicPlanId": originalValues.strategicPlanId,
                            "subGoal": rows,
                            "active": true
                        };

                        const transformedData = {
                            ...formState,
                            subGoal: formState.subGoal.map((subGoal: any) => ({
                                ...subGoal,
                                mappedKpi: subGoal.mappedKpi?.map((kpi: any) => kpi.id) || [] // Map KPI objects to their ids
                            }))
                        };

                        axios.put(`${url}${originalValues.goalId}`,
                            transformedData
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    'accept-language': 'en'
                                }
                            })
                            .then(function (response) {
                                if (response.status === 200) {
                                    dispatch(updateGoalByIndex(currentRowIndex, transformedData))
                                    setOpenUpdateTableModal(false);
                                    setFetchUpdate(true);
                                    setForUpdate(true);
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={8}
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}>
                                <Grid container>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ marginTop: "-30px" }}>
                                        <TextField
                                            placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalName.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalName.label')}`}
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            value={goalNames}
                                            error={goalNameError}
                                            helperText={goalNameErrorMessage}
                                            onChange={(e) => {
                                                if (goalNameError) {
                                                    setGoalNameError(false);
                                                    setGoalNameErrorMessage("");
                                                }
                                                setGoalName(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalDescription.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalDescription.label')}`}
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            value={goalDescriptions}
                                            error={goalDescriptionError}
                                            helperText={goalDescriptionErrorMessage}
                                            onChange={(e) => {
                                                if (goalDescriptionError) {
                                                    setGoalDescriptionError(false);
                                                    setGoalDescriptionErrorMessage("");
                                                }
                                                setGoalDescription(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <Autocomplete
                                            options={instructorNameOptions}
                                            getOptionLabel={option => option.label}
                                            value={instructorNameOptions.find((option: any) => option.value === goalManager) || null}
                                            onChange={handleGoalManagerChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalManager.placeholder')}`}
                                                    label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.goalManager.label')}`}
                                                    variant="outlined"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    style={{ marginTop: "18px", backgroundColor: "#fff" }}
                                                    error={goalManagerError}
                                                    helperText={goalManagerErrorMessage}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={11}
                                        lg={11}
                                        md={11}
                                        sm={11}
                                        xs={11} sx={{ mt: 3 }}>

                                        <Autocomplete
                                            options={initiativeOptions || []}  // Ensure options is never undefined
                                            getOptionLabel={(option: any) => option.label}
                                            value={initiativeOptions ? initiativeOptions.filter((option: any) => initiatives?.includes(option.value)) : []}  // Ensure initiativeOptions is defined
                                            onChange={handleInitiativeChange}
                                            disabled={checked}
                                            multiple
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.initiative.placeholder')}`}
                                                    label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.initiative.label')}`}
                                                    variant="outlined"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    error={initiativeError}
                                                    helperText={initiativeErrorMessage}
                                                />
                                            )}
                                        />



                                    </Grid>

                                    <Grid item xl={1}
                                        lg={1}
                                        md={1}
                                        sm={1}
                                        xs={1} sx={{ mt: 3 }}>
                                        <MdOutlineAddTask onClick={handleOpenAddInitiativeModal} style={{
                                            marginTop: "7px", height: "35px", width: "35px", marginLeft: "10px"
                                        }} />
                                    </Grid>

                                    <Grid item xl={12}
                                        lg={11}
                                        md={11}
                                        sm={11}
                                        xs={11} sx={{ mt: 3 }}>
                                        <TextField
                                            label="KPIs"
                                            variant="outlined"
                                            disabled={checked}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        {mappedKpis.map((item: any) => (
                                                            <Chip key={item} label={kpiMap[item]} sx={{ marginRight: 0.5 }} />

                                                        ))}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            onClick={handleOpen}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true, // Ensure label is always visible
                                            }}
                                        />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xl={3}
                                lg={3}
                                md={3}
                                sm={12}
                                xs={12}>
                                <Grid container>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ marginTop: "-30px" }}>
                                        <img src={goal1} height="380" width="320" style={{ marginTop: "15px" }} />
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12} style={{ display: "flex", alignItems: "center" }}>
                                <Checkbox checked={checked} onChange={handleCheckboxChange} />
                                <p style={{ fontSize: "18px", marginLeft: "10px", marginTop: "15px", fontWeight: "300", color: "#A7A7A7" }}> <b style={{ fontWeight: "bold" }}>Sub Goal</b></p>
                            </Grid>

                            {checked && (
                                <Grid item xl={12}
                                    lg={12}
                                    md={12}
                                    sm={12}
                                    xs={12}>
                                    <TableContainer className={styles.tableContainer} dir={(currentLang === "ar") ? "rtl" : "ltr"}>
                                        <Table dir={(currentLang === "ar") ? "rtl" : "ltr"}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ minWidth: "400px" }}>{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalName.label')}</TableCell>
                                                    <TableCell >{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalDescription.label')}</TableCell>
                                                    <TableCell >{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalManager.label')}</TableCell>
                                                    <TableCell style={{ minWidth: "400px" }}>{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.initiative.label')}</TableCell>
                                                    <TableCell style={{ minWidth: "400px" }}>{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.kpiSubGoal.label')}</TableCell>
                                                    <TableCell >{t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.action')}</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row: any, index: any) => (
                                                    <TableRow key={index} style={{ borderStyle: "hidden" }}>
                                                        <TableCell style={{ minWidth: "400px" }}>

                                                            <TextField
                                                                placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalName.placeholder')}`}
                                                                label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalName.label')}`}
                                                                variant="standard"
                                                                helperText={errors[index]?.goalName}
                                                                error={!!errors[index]?.goalName}
                                                                value={row.goalName}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                onChange={(e) => handleFieldChange(index, 'goalName', e.target.value)}
                                                                style={{ width: '400px' }}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ minWidth: "500px" }}>
                                                            <TextField
                                                                placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalDescription.placeholder')}`}
                                                                label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalDescription.label')}`}
                                                                variant="standard"
                                                                helperText={errors[index]?.goalDescription}
                                                                error={!!errors[index]?.goalDescription}
                                                                value={row.goalDescription}
                                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                onChange={(e) => handleFieldChange(index, 'goalDescription', e.target.value)}
                                                                style={{ width: '500px' }}
                                                            />

                                                        </TableCell>

                                                        <TableCell className={styles.tableCellBody}>

                                                            <Autocomplete
                                                                options={instructorNameOptions}
                                                                getOptionLabel={option => option.label}
                                                                value={instructorNameOptions.find((option: any) => option.userName === row.manager) || null}
                                                                onChange={(event, newValue: InstructorNameOption | null) => {
                                                                    if (newValue) {
                                                                        // setSelectedCourseCode(newValue.value);
                                                                        handleFieldChange(index, 'manager', newValue.userName);
                                                                    } else {
                                                                        // setSelectedCourseCode(null);
                                                                        handleFieldChange(index, 'manager', '');
                                                                    }
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalManager.placeholder')}`}
                                                                        label={`${t('Home.Header.Modals.SPS.AddGoal.policy.Inputs.subGoalManager.label')}`}
                                                                        variant="standard"
                                                                        helperText={errors[index]?.manager}
                                                                        error={!!errors[index]?.manager}
                                                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                        style={{ width: '300px' }}
                                                                    />
                                                                )}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ minWidth: "400px" }}>





                                                            <Autocomplete
                                                                options={initiativeOptions || []}
                                                                getOptionLabel={(option: any) => option.label}
                                                                value={
                                                                    initiativeOptions && Array.isArray(row.mappedInitiative)
                                                                        ? initiativeOptions.filter((option: any) => row.mappedInitiative.includes(option.value))
                                                                        : []
                                                                } // Filter to find the selected initiatives
                                                                onChange={(event, newValue) => {
                                                                    // Ensure newValue is an array and map it to store the value (ID) only
                                                                    const newMappedInitiatives = newValue ? newValue.map((option: any) => option.value) : [];
                                                                    handleFieldChange(index, 'mappedInitiative', newMappedInitiatives);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Select Initiative"
                                                                        label="Initiative"
                                                                        variant="standard"
                                                                        helperText={errors[index]?.mappedInitiative}
                                                                        error={!!errors[index]?.mappedInitiative}
                                                                        style={{ width: '400px' }}
                                                                    />
                                                                )}
                                                                multiple
                                                            />

                                                        </TableCell>
                                                        <TableCell style={{ minWidth: "400px" }}>
                                                            <TextField
                                                                label="KPIs"
                                                                variant="standard"
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {/* {row.mappedKpi && Array.isArray(row.mappedKpi)
                                                                                ? row.mappedKpi.map((kpi: any, indexKpi: any) => (
                                                                                    <Chip
                                                                                        key={indexKpi}
                                                                                        label={kpiMap[kpi]}
                                                                                        sx={{ margin: 0.5 }}
                                                                                    />
                                                                                ))
                                                                                : null} */}
                                                                            {row.mappedKpi && row.mappedKpi.map((kpi: any) => (
                                                                                <Chip key={kpi.id} label={kpi.kpiName} sx={{ margin: 0.5 }} />
                                                                            ))}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleOpenKPIs(index);
                                                                }}
                                                                fullWidth
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell className={styles.tableCellPlusBtn}>
                                                            {index === rows.length - 1 ? (
                                                                <Button
                                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                    onClick={() => handleAddRow()}><FaPlus size={20} style={{ marginTop: "2px" }} color="#029c2b" /></Button>
                                                            ) : (
                                                                <Button
                                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                                    onClick={() => handleDeleteRow(index)}><FaWindowClose size={20} style={{ marginTop: "2px" }} color="#e60923" /></Button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>



                                    </TableContainer>
                                </Grid>
                            )}







                        </Grid>
                    </Box>


                    {/* <Stack sx={{ width: '100%', marginLeft: "15px", mt: 3 }} spacing={2}>
                        {isAdded ?
                            <Alert severity="success">Goal Updated Successfully.</Alert>
                            : ""}
                    </Stack> */}

                    {/* <SnackBar
                        isOpen={snackBarHandler.open}
                        message={snackBarHandler.message}
                        severity={snackBarHandler.severity}
                        isModal={true}
                        setIsOpen={
                            (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                        }
                    /> */}
                </Box>

                <CustomKpiModalUpdate
                    open={modalOpen}
                    handleClose={handleClose}
                    handleCheckboxKpiChange={handleCheckboxKpiChange}
                    selectedKpi={mappedKpis}
                />

                <CustomKpiModalSubGoalUpdate
                    open={modalOpenSubGoal}
                    handleCloseSubGoal={handleCloseSubGoal}
                    selectedKpiSubGoal={selectedKpiSubGoal}
                    handleCheckboxKpiChangeSubGoal={handleCheckboxKpiChangeSubGoal} />
                <br />
            </Box>
        )
    }
)
export default React.memo(UpdateGoal);