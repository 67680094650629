import { useState } from "react";
import { AccountService } from "../../service/AccountService";
import { useDispatch } from "react-redux";
import { updateGroupRedux } from "../../../../../../redux/action/accountActions";

const useUpdateGroupData = () => {
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const dispatch = useDispatch();

    const updateGroups = async (accessToken: any, formState: any, currentRowIndex: any) => {
        try {
                const response: any = await AccountService.updateGroup(accessToken, formState);
                if (response.success) {
                    dispatch(updateGroupRedux(currentRowIndex, formState));
                    console.log(response);
                }
                else {
                    console.log(response.errMessage)
                }
            } catch (error) {
                console.log(error);
            }
    };

    return { updateGroups, snackBarHandler, setSnackBarHandler };
};

export default useUpdateGroupData;