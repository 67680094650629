import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from "../store";
import { AccountService } from "../../Components/Pages/Home/UserManagement/service/AccountService";

export const MANAGE_USER = 'MANAGE_USER';
export const MANAGE_GROUP = 'MANAGE_GROUP';
export const MANAGE_USER_GROUP = 'MANAGE_USER_GROUP';
export const APPEND_USER_GROUP = 'APPEND_USER_GROUP';
export const MANAGE_ROLE = 'MANAGE_ROLE';
export const MANAGE_GROUP_ROLE = 'MANAGE_GROUP_ROLE';
export const MANAGE_APPLICATION = 'MANAGE_APPLICATION';
export const MANAGE_APP_FORM = 'MANAGE_APP_FORM';
export const MANAGE_ROLE_APP_PRIVILAGE = 'MANAGE_ROLE_APP_PRIVILAGE'

export const ADD_USER = 'ADD_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const ADD_USER_GROUP = 'ADD_USER_GROUP';
export const UPDATE_USER_GROUP = 'UPDATE_USER_GROUP';
export const DELETE_USER_GROUP = 'DELETE_USER_GROUP';
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE';
export const ADD_GROUP_ROLE = 'ADD_GROUP_ROLE';
export const UPDATE_GROUP_ROLE = 'UPDATE_GROUP_ROLE';
export const DELETE_GROUP_ROLE = 'DELETE_GROUP_ROLE';
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const ADD_APP_FORM = 'ADD_APP_FORM';
export const UPDATE_APP_FORM = 'UPDATE_APP_FORM';
export const DELETE_APP_FORM = 'DELETE_APP_FORM';
export const ADD_ROLE_APP_PRIVILEGE = 'ADD_ROLE_APP_PRIVILEGE';
export const UPDATE_ROLE_APP_PRIVILEGE = 'UPDATE_ROLE_APP_PRIVILEGE';
export const DELETE_ROLE_APP_PRIVILEGE = 'DELETE_ROLE_APP_PRIVILEGE';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILURE = 'FETCH_USER_DATA_FAILURE';

export const FETCH_GROUP_DATA_REQUEST = 'FETCH_GROUP_DATA_REQUEST';
export const FETCH_GROUP_DATA_SUCCESS = 'FETCH_GROUP_DATA_SUCCESS';
export const FETCH_GROUP_DATA_FAILURE = 'FETCH_GROUP_DATA_FAILURE';

export const FETCH_USER_GROUP_DATA_REQUEST = 'FETCH_USER_GROUP_DATA_REQUEST';
export const FETCH_USER_GROUP_DATA_SUCCESS = 'FETCH_USER_GROUP_DATA_SUCCESS';
export const FETCH_USER_GROUP_DATA_FAILURE = 'FETCH_USER_GROUP_DATA_FAILURE';

export const FETCH_ROLE_DATA_REQUEST = 'FETCH_ROLE_DATA_REQUEST';
export const FETCH_ROLE_DATA_SUCCESS = 'FETCH_ROLE_DATA_SUCCESS';
export const FETCH_ROLE_DATA_FAILURE = 'FETCH_ROLE_DATA_FAILURE';

export const FETCH_GROUP_ROLE_DATA_REQUEST = 'FETCH_GROUP_ROLE_DATA_REQUEST';
export const FETCH_GROUP_ROLE_DATA_SUCCESS = 'FETCH_GROUP_ROLE_DATA_SUCCESS';
export const FETCH_GROUP_ROLE_DATA_FAILURE = 'FETCH_GROUP_ROLE_DATA_FAILURE';

export const FETCH_APPLICATION_DATA_REQUEST = 'FETCH_APPLICATION_DATA_REQUEST';
export const FETCH_APPLICATION_DATA_SUCCESS = 'FETCH_APPLICATION_DATA_SUCCESS';
export const FETCH_APPLICATIOIN_DATA_FAILURE = 'FETCH_APPLICATIOIN_DATA_FAILURE';

export const FETCH_APPFORM_DATA_REQUEST = 'FETCH_APPFORM_DATA_REQUEST';
export const FETCH_APPFORM_DATA_SUCCESS = 'FETCH_APPFORM_DATA_SUCCESS';
export const FETCH_APPFORM_DATA_FAILURE = 'FETCH_APPFORM_DATA_FAILURE';

export const FETCH_ROLE_APP_PRIVILEGE_DATA_REQUEST = 'FETCH_ROLE_APP_PRIVILEGE_DATA_REQUEST';
export const FETCH_ROLE_APP_PRIVILEGE_DATA_SUCCESS = 'FETCH_ROLE_APP_PRIVILEGE_DATA_SUCCESS';
export const FETCH_ROLE_APP_PRIVILEGE_DATA_FAILURE = 'FETCH_ROLE_APP_PRIVILEGE_DATA_FAILURE';

//@ function for fetching user data
export const fetchData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_USER_DATA_REQUEST });
        try {
            const response = await AccountService.getAllUsers();
            dispatch({ type: FETCH_USER_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_USER_DATA_FAILURE, payload: error.message });
        }
    };
};

//@ function for fetching group data
export const fetchGroupData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_GROUP_DATA_REQUEST });
        try {
            const response = await AccountService.getAllGroups();
            dispatch({ type: FETCH_GROUP_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_GROUP_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching user group data
export const fetchUserGroupData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_USER_GROUP_DATA_REQUEST });
        try {
            const response = await AccountService.getAllUserGroup();
            dispatch({ type: FETCH_USER_GROUP_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_USER_GROUP_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching role data
export const fetchRoleData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_ROLE_DATA_REQUEST });
        try {
            const response = await AccountService.getAllRole();
            dispatch({ type: FETCH_ROLE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_ROLE_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching group role data
export const fetchGroupRoleData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_GROUP_ROLE_DATA_REQUEST });
        try {
            const response = await AccountService.getAllGroupRole();
            dispatch({ type: FETCH_GROUP_ROLE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_GROUP_ROLE_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching application data
export const fetchApplicationData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_APPLICATION_DATA_REQUEST });
        try {
            const response = await AccountService.getAllApplication();
            dispatch({ type: FETCH_APPLICATION_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_APPLICATIOIN_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching app form data
export const fetchAppFormData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_APPFORM_DATA_REQUEST });
        try {
            const response = await AccountService.getAllAppForm();
            dispatch({ type: FETCH_APPFORM_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_APPFORM_DATA_FAILURE, payload: error.message });
        }
    };
}

//@ function for fetching role app privilege data
export const fetchRoleAppPrivilegeData = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_ROLE_APP_PRIVILEGE_DATA_REQUEST });
        try {
            const response = await AccountService.getAllRoleAppPrivilege();
            dispatch({ type: FETCH_ROLE_APP_PRIVILEGE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_ROLE_APP_PRIVILEGE_DATA_FAILURE, payload: error.message });
        }
    };
}


export const addUser = (data: any) => ({
    type: ADD_USER,
    payload: { data }
  });

  export const updateUsers = (index: number, updatedUser: any) => ({
    type: UPDATE_USER,
    payload: { index, updatedUser },
  });

  export const deleteUsers = (index: number) => ({
    type: DELETE_USER,
    payload: {index}
  })

  export const addGroup = (data: any) => ({
    type: ADD_GROUP,
    payload: { data }
  });

  export const updateGroupRedux = (index: number, updatedGroup: any) => ({
    type: UPDATE_GROUP,
    payload: { index, updatedGroup },
  });

  export const deleteGroupRedux = (id: number) => ({
    type: DELETE_GROUP,
    payload: {id}
  })

  export const addUserGroup = (data: any) => ({
    type: ADD_USER_GROUP,
    payload: { data }
  })

  export const updateUserGroupRedux = (index: number, updatedUserGroup: any) => ({
    type: UPDATE_USER_GROUP,
    payload: { index, updatedUserGroup },
  });

  export const deleteUserGroupRedux = (id: number) => ({
    type: DELETE_USER_GROUP,
    payload: {id}
  })

  export const addRole = (data: any) => ({
    type: ADD_ROLE,
    payload: { data }
  });


  export const updateRoleRedux = (index: number, updatedRole: any) => ({
    type: UPDATE_ROLE,
    payload: { index, updatedRole },
  });

  export const deleteRoleRedux = (id: number) => ({
    type: DELETE_ROLE,
    payload: {id}
  })

  export const addGroupRoles = (data: any) => ({
    type: ADD_GROUP_ROLE,
    payload: { data }
  });

  export const updateGroupRoleRedux = (index: number, updatedGroupRole: any) => ({
    type: UPDATE_GROUP_ROLE,
    payload: { index, updatedGroupRole },
  });

  export const deleteGroupRoleRedux = (id: number) => ({
    type: DELETE_GROUP_ROLE,
    payload: {id}
  })

  export const addApplications = (data: any) => ({
    type: ADD_APPLICATION,
    payload: { data }
  });

  export const updateApplicationRedux = (index: number, updatedApplication: any) => ({
    type: UPDATE_APPLICATION,
    payload: { index, updatedApplication },
  });


  export const deleteApplicationRedux = (id: number) => ({
    type: DELETE_APPLICATION,
    payload: {id}
  })

  export const addAppForm = (data: any) => ({
    type: ADD_APP_FORM,
    payload: { data }
  });

  export const updateAppFormRedux = (index: number, updatedAppForm: any) => ({
    type: UPDATE_APP_FORM,
    payload: { index, updatedAppForm },
  });

  export const deleteAppFormRedux = (id: number) => ({
    type: DELETE_APP_FORM,
    payload: {id}
  })

  export const addRoleAppPrivilegeRedux = (data: any) => ({
    type: ADD_ROLE_APP_PRIVILEGE,
    payload: { data }
  });

  export const updateRoleAppPrivilegeRedux = (index: number, updatedRoleAppPrivilege: any) => ({
    type: UPDATE_ROLE_APP_PRIVILEGE,
    payload: { index, updatedRoleAppPrivilege },
  });

  export const deleteRoleAppPrivilegeRedux = (id: number) => ({
    type: DELETE_ROLE_APP_PRIVILEGE,
    payload: {id}
  })



export const manageUser = (data: any) => ({
    type: MANAGE_USER,
    payload: { data }
})

export const manageGroup = (data: any) => ({
    type: MANAGE_GROUP,
    payload: { data }
})

export const manageUserGroup = (data: any) => ({
    type: MANAGE_USER_GROUP,
    payload: { data }
})

export const appendUserGroup = (data: any) => ({
    type: APPEND_USER_GROUP,
    payload: { data }
});

export const manageRole = (data: any) => ({
    type: MANAGE_ROLE,
    payload: { data }
})

export const manageGroupRole = (data: any) => ({
    type: MANAGE_GROUP_ROLE,
    payload: { data }
})

export const manageApplication = (data: any) => ({
    type: MANAGE_APPLICATION,
    payload: { data }
})

export const manageAppForm = (data: any) => ({
    type: MANAGE_APP_FORM,
    payload: { data }
})

export const manageRoleApp = (data: any) => ({
    type: MANAGE_ROLE_APP_PRIVILAGE,
    payload: { data }
})



