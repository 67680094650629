import axios from "axios"
import Cookies from 'js-cookie';

//Create an instance of axios
let language = localStorage.getItem('i18nextLng') || 'en';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = Cookies.get('accessToken');
        if (accessToken) {
            config.headers['x-api-key'] = accessToken;
        }
        language = localStorage.getItem('i18nextLng') || 'en';
        config.headers['Accept-Language'] = language
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
)


export default axiosInstance;