interface SurveyState {
  surveyLink: any | null;
  loading: any
}

const initialState: SurveyState = {
  surveyLink: null,
  loading: false
};

const surveyReducer = (state = initialState, action: any): SurveyState => {
  switch (action.type) {
    case 'FETCH_SURVEY_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        surveyLink: action.payload,
      };
    case 'ADD_SURVEY':
      return {
        ...state,
        surveyLink: [...state.surveyLink, action.payload.data]
      }
    case 'UPDATE_SURVEY':
      return {
        ...state,
        surveyLink: state.surveyLink.map((survey: any) =>
          survey.id === action.payload.id ? { ...survey, ...action.payload.updatedSurvey } : survey
        ),
      };
    case 'DELETE_SURVEY':
      return {
        ...state,
        surveyLink: state.surveyLink.filter((survey: any) => survey.id !== action.payload.id),
      };
    default:
      return state;
  }
};

export default surveyReducer;