import { useState } from "react";
import { AccountService } from "../../service/AccountService";
import { useDispatch } from "react-redux";
import { updateUserGroupRedux } from "../../../../../../redux/action/accountActions";

const useUpdateUserGroupData = () => {
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });
    const dispatch = useDispatch();
    const updateUserGroups = async (accessToken: any, formState: any, currentRowIndex: any) => {
        try {
            const response: any = await AccountService.updateUserGroup(accessToken, formState);
            if(response.success) {
                dispatch(updateUserGroupRedux(currentRowIndex, formState));
            }
            else {
                console.log(response.errMessage);
            }
        } catch (error) {
            console.log(error)
        }
    };

    return { updateUserGroups, snackBarHandler, setSnackBarHandler };
};

export default useUpdateUserGroupData;