import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../../data/api/read';

import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import useUpdateDepartment from '../customHooks/useUpdateDepartment';


interface ViewAllDepartmentData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        departmentId: string;
        departmentName: string;
        departmentEmailId: string;
        departmentWebAddress: string;
        addressLine1: string;
        addressLine2: string;
        addressLine3: string;
        departmentChair: string;
        departmentPhoneNumber: string;
        departmentFaxNumber: string;
        departmentMission: string;
        departmentVision: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateDepartment = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // newValues = {
        //     "roleId": values.roleId,
        //     "roleName": values.roleName,
        //     "roleDescription": values.roleDescription,
        //     "loggedInUser": loggedInUser,
        //     "active": values.active === "true" ? true : false
        // };

        /// Handling the data of the form
        // For field validation
        // const [DepartmentName, setDepartmentName] = useState<string>(originalValues.DepartmentName);
        // const [roleDescription, setRoleDescription] = useState<string>(originalValues.roleDescription);

        // Error messages
        // const [roleNameErrorMessage, setRoleNameErrorMessage] = useState<string>("");
        // const [roleDescriptionErrorMessage, setRoleDescriptionErrorMessage] = useState<string>("");

        // For field validation
        // const [roleNameError, setRoleNameError] = useState<boolean>(false);
        // const [roleDescriptionError, setRoleDescriptionError] = useState<boolean>(false);

        // Fetching data using axios

        const [viewAllDepartmentData, setViewAllDepartmentData] = useState<ViewAllDepartmentData | null>(null);

        const [DepartmentName, setDepartmentName] = useState<string>(originalValues.departmentName);
        const [DepartmentEmailId, setDepartmentEmailId] = useState<string>(originalValues.departmentEmailId);
        const [DepartmentPhoneNumber, setDepartmentPhoneNumber] = useState<string>(originalValues.departmentPhoneNumber);
        const [DepartmentFaxNumber, setDepartmentFaxNumber] = useState<string>(originalValues.departmentFaxNumber);
        const [DepartmentMission, setDepartmentMission] = useState<string>(originalValues.departmentMission);
        const [DepartmentVision, setDepartmentVision] = useState<string>(originalValues.departmentVision);
        const [DepartmentWebAddress, setDepartmentWebAddress] = useState<string>(originalValues.departmentWebAddress);
        const [addressLine1, setAddressLine1] = useState<string>(originalValues.addressLine1);
        const [addressLine2, setAddressLine2] = useState<string>(originalValues.addressLine2);
        const [addressLine3, setAddressLine3] = useState<string>(originalValues.addressLine3);
        const [DepartmentChair, setDepartmentChair] = useState<string>(originalValues.departmentChair);

        // For handling errors

        const [DepartmentNameError, setDepartmentNameError] = useState<boolean>(false);
        const [DepartmentEmailIdError, setDepartmentEmailIdError] = useState<boolean>(false);
        const [DepartmentPhoneNumberError, setDepartmentPhoneNumberError] = useState<boolean>(false);
        const [DepartmentFaxNumberError, setDepartmentFaxNumberError] = useState<boolean>(false);
        const [DepartmentMissionError, setDepartmentMissionError] = useState<boolean>(false);
        const [DepartmentVisionError, setDepartmentVisionError] = useState<boolean>(false);
        const [DepartmentWebAddressError, setDepartmentWebAddressError] = useState<boolean>(false);
        const [addressLine1Error, setAddressLine1Error] = useState<boolean>(false);
        const [addressLine2Error, setAddressLine2Error] = useState<boolean>(false);
        const [addressLine3Error, setAddressLine3Error] = useState<boolean>(false);
        const [DepartmentChairError, setDepartmentChairError] = useState<boolean>(false);

        // For handling error messages
        const [DepartmentNameErrorMessage, setDepartmentNameErrorMessage] = useState("");
        const [DepartmentWebAddressErrorMessage, setDepartmentWebAddressErrorMessage] = useState("");
        const [addressLine1ErrorMessage, setAddressLine1ErrorMessage] = useState("");
        const [addressLine2ErrorMessage, setAddressLine2ErrorMessage] = useState("");
        const [addressLine3ErrorMessage, setAddressLine3ErrorMessage] = useState("");
        const [DepartmentEmailIdErrorMessage, setDepartmentEmailIdErrorMessage] = useState("");
        const [DepartmentPhoneNumberErrorMessage, setDepartmentPhoneNumberErrorMessage] = useState("");
        const [DepartmentChairErrorMessage, setDepartmentChairErrorMessage] = useState("");
        const [DepartmentFaxNumberErrorMessage, setDepartmentFaxNumberErrorMessage] = useState("");
        const [DepartmentMissionErrorMessage, setDepartmentMissionErrorMessage] = useState("");
        const [DepartmentVisionErrorMessage, setDepartmentVisionErrorMessage] = useState("");

        // Status radio buttons
        // const [status, setStatus] = useState(originalValues.active === true ? "Active" : "Deactive");

        // const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setStatus((event.target as HTMLInputElement).value);
        // };
        // Status radio buttons

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(!isChecked);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Department, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllDepartmentData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const { snackBarHandler, setSnackBarHandler, updateDepartments } = useUpdateDepartment();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllDepartmentData !== null && accessToken !== null) {
                    // Set the validation errors
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const numberRegex = /^[0-9]{10}/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (DepartmentEmailId === "") {
                        setDepartmentEmailIdError(true);
                    }
                    if (!emailRegex.test(DepartmentEmailId)) {
                        setDepartmentEmailIdErrorMessage("Please enter valid email")
                        setDepartmentEmailIdError(true)
                    }
                    if (DepartmentPhoneNumber === "") {
                        setDepartmentPhoneNumberError(true);
                    }
                    if (!numberRegex.test(DepartmentPhoneNumber) || DepartmentPhoneNumber.length == 0 || DepartmentPhoneNumber.length > 10) {
                        setDepartmentPhoneNumberErrorMessage("Please enter valid Phone Number")
                        setDepartmentPhoneNumberError(true);
                    }
                    else {
                        setDepartmentPhoneNumberErrorMessage("")
                        setDepartmentPhoneNumberError(false);
                    }
                    if (DepartmentName === "") {
                        setDepartmentNameErrorMessage("Department Name is required")
                        setDepartmentNameError(true);
                    }
                    if (DepartmentFaxNumber === "") {
                        setDepartmentFaxNumberError(true);
                    }
                    if (!numberRegex.test(DepartmentFaxNumber) || DepartmentFaxNumber.length == 0 || DepartmentFaxNumber.length > 10) {
                        setDepartmentFaxNumberErrorMessage("Please enter valid fax number")
                        setDepartmentFaxNumberError(true);
                    } else {
                        setDepartmentFaxNumberErrorMessage("")
                        setDepartmentFaxNumberError(false);
                    }
                    if (DepartmentMission === "") {
                        setDepartmentMissionError(true);
                    }
                    if (!textRegex.test(DepartmentMission)) {
                        setDepartmentMissionErrorMessage("Please Enter Only alphabets")
                        setDepartmentMissionError(true);
                    }
                    if (DepartmentVision === "") {
                        setDepartmentVisionError(true);
                    }
                    if (!textRegex.test(DepartmentVision)) {
                        setDepartmentVisionErrorMessage("Please Enter Only alphabets")
                        setDepartmentVisionError(true);
                    }
                    if (DepartmentWebAddress === "") {
                        setDepartmentWebAddressError(true);
                    }
                    if (!urlRegex.test(DepartmentWebAddress)) {
                        setDepartmentWebAddressErrorMessage("Invalid URL format")
                        setDepartmentWebAddressError(true);
                    }
                    if (addressLine1 === "") {
                        setAddressLine1Error(true);
                    }
                    if (!textRegex.test(addressLine1)) {
                        setAddressLine1ErrorMessage("Please enter only alphabets")
                        setAddressLine1Error(true);
                    }
                    else {
                        setAddressLine1ErrorMessage("")
                    }
                    if (addressLine1.length > 100) {
                        setAddressLine1ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine1Error(true);
                    }
                    if (addressLine2 === "") {
                        setAddressLine2Error(true);
                    }
                    if (addressLine2.length > 100) {
                        setAddressLine2ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine2Error(true);
                    }
                    if (!textRegex.test(addressLine2)) {
                        setAddressLine2ErrorMessage("Please enter only alphabets")
                        setAddressLine2Error(true);
                    } else {
                        setAddressLine2ErrorMessage("")
                    }
                    if (addressLine3.length > 100) {
                        setAddressLine3ErrorMessage("Character Limit Exceeded enter less than 100 characters")
                        setAddressLine3Error(true);
                    }
                    if (!textRegex.test(addressLine3)) {
                        setAddressLine3ErrorMessage("Please enter only alphabets")
                        setAddressLine3Error(true);
                    } else {
                        setAddressLine3ErrorMessage("")
                    }
                    if (DepartmentChair === "") {
                        setDepartmentChairError(true);
                    }
                    if (!textRegex.test(DepartmentChair)) {
                        setDepartmentChairErrorMessage("Please enter only alphabets")
                        setDepartmentChairError(true);
                    } else {
                        setDepartmentChairErrorMessage("")
                    }
                    // Set the validation errors

                    if (DepartmentName !== "" && DepartmentEmailId !== "" && DepartmentFaxNumber !== "" && DepartmentMission !== "" &&
                        DepartmentVision !== "" && DepartmentPhoneNumber !== "" && DepartmentWebAddress !== "" && addressLine1 !== ""
                        && addressLine2 !== "" && addressLine3 !== "" && DepartmentChair !== ""
                    ) {
                        const formState = {
                            "departmentId": originalValues.departmentId,
                            "departmentName": DepartmentName,
                            "departmentEmailId": DepartmentEmailId,
                            "departmentMission": DepartmentMission,
                            "departmentVision": DepartmentVision,
                            "departmentFaxNumber": DepartmentFaxNumber,
                            "departmentPhoneNumber": DepartmentPhoneNumber,
                            "departmentWebAddress": DepartmentWebAddress,
                            "addressLine1": addressLine1,
                            "addressLine2": addressLine2,
                            "addressLine3": addressLine3,
                            "departmentChair": DepartmentChair,
                            "active": isChecked
                        };

                        await updateDepartments(formState, currentRowIndex)
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                            setFetchUpdate(true);
                        }, 3000);
                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `${DepartmentName} data has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             if (response.data.code === "200.200") {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                     setFetchUpdate(true);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error: any) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                {/* <div style={{ marginTop: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} className={`${(windowSize[0] > 990) ? ("d-flex justify-content-between") : ("d-flex flex-column justify-content-start")}`}>
                <div>
                    {(currentLang === "ar") ? (
                        <>
                            <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span> / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / EQA
                        </>
                    ) : (
                        <>
                            EQA / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span>
                        </>
                    )}
                </div>
                <div>
                    <span style={{ color: "#4f747a", paddingRight: 10 }}>{currentFormatedDate}</span>
                </div>
            </div> */}

                {/* <hr /> */}

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    {/* <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        // border: "1px solid black",
                        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <PeopleOutlineIcon
                            sx={{
                                color: "#4f747a",
                                fontSize: 35,
                                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;", 
                            }} />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.subTitle')}
                        </Typography>
                    </Box>
                </Box> */}

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentNameTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentName.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentNameError}
                                    helperText={DepartmentNameErrorMessage}
                                    value={DepartmentName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentNameError) {
                                            setDepartmentNameError(false);
                                        }
                                        setDepartmentName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentEmailIdTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentEmailId.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentEmailId.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentEmailIdError}
                                    helperText={DepartmentEmailIdErrorMessage}
                                    value={DepartmentEmailId}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentEmailIdError) {
                                            setDepartmentEmailIdError(false);
                                        }
                                        setDepartmentEmailId(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentWebAddressTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentWebAddress.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentWebAddress.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentWebAddressError}
                                    helperText={DepartmentWebAddressErrorMessage}
                                    value={DepartmentWebAddress}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentWebAddressError) {
                                            setDepartmentWebAddressError(false);
                                        }
                                        setDepartmentWebAddress(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentPhoneNumberTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentPhoneNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentPhoneNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentPhoneNumberError}
                                    helperText={DepartmentPhoneNumberErrorMessage}
                                    value={DepartmentPhoneNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentPhoneNumberError) {
                                            setDepartmentPhoneNumberError(false);
                                        }
                                        setDepartmentPhoneNumber(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentMissionTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentMission.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentMission.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentMissionError}
                                    helperText={DepartmentMissionErrorMessage}
                                    value={DepartmentMission}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentMissionError) {
                                            setDepartmentMissionError(false);
                                        }
                                        setDepartmentMission(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentVisionTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentVision.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentVision.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentVisionError}
                                    helperText={DepartmentVisionErrorMessage}
                                    value={DepartmentVision}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentVisionError) {
                                            setDepartmentVisionError(false);
                                        }
                                        setDepartmentVision(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentFaxIdTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentFaxNumber.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentFaxNumber.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentFaxNumberError}
                                    // helperText={DepartmentFaxIdErrorMessage}
                                    value={DepartmentFaxNumber}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentFaxNumberError) {
                                            setDepartmentFaxNumberError(false);
                                        }
                                        setDepartmentFaxNumber(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine1TextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.AddressLine1.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.AddressLine1.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine1Error}
                                    helperText={addressLine1ErrorMessage}
                                    value={addressLine1}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine1Error) {
                                            setAddressLine1Error(false);
                                        }
                                        setAddressLine1(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine2TextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.AddressLine2.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.AddressLine2.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine2Error}
                                    helperText={addressLine2ErrorMessage}
                                    value={addressLine2}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine2Error) {
                                            setAddressLine2Error(false);
                                        }
                                        setAddressLine2(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="addressLine3TextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.AddressLine3.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.AddressLine3.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={addressLine3Error}
                                    helperText={addressLine3ErrorMessage}
                                    value={addressLine3}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (addressLine3Error) {
                                            setAddressLine3Error(false);
                                        }
                                        setAddressLine3(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="DepartmentChairTextField"
                                    label={t('Home.Header.Modals.Department.policy.Inputs.DepartmentChair.label')}
                                    placeholder={`${t('Home.Header.Modals.Department.policy.Inputs.DepartmentChair.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={DepartmentChairError}
                                    helperText={DepartmentChairErrorMessage}
                                    value={DepartmentChair}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (DepartmentChairError) {
                                            setDepartmentChairError(false);
                                        }
                                        setDepartmentChair(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateDepartment;