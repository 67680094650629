interface PortalState {
    uploadedDocument: any | null;
    documentType: any | null;
    loading: any
}

const initialState: PortalState = {
    uploadedDocument: null,
    documentType: null,
    loading: false
};

const portalReducer = (state = initialState, action: any): PortalState => {
    switch (action.type) {
        case 'FETCH_UPLOADED_DOCUMENT_LIST_SUCCESS':
            return {
                ...state,
                loading: false,
                uploadedDocument: action.payload,
            };
        case 'UPLOAD_DOCUMENT':
            return {
                ...state,
                uploadedDocument: [...state.uploadedDocument, action.payload.data]
            }
        case 'UPDATE_DOCUMENT':
            return {
                ...state,
                uploadedDocument: state.uploadedDocument.map((survey: any) =>
                    survey.id === action.payload.id ? { ...survey, ...action.payload.updatedSurvey } : survey
                ),
            };
        case 'DELETE_DOCUMENT':
            return {
                ...state,
                uploadedDocument: state.uploadedDocument.filter((survey: any) => survey.id !== action.payload.id),
            };
        // case 'GET_UPLOADED_DOCUMENT':
        //     return {
        //         ...state,
        //         data: action.payload.data,
        //     };
        case 'GET_UPLOADED_DOCUMENT_TYPE':
            return {
                ...state,
                documentType: action.payload.data
            }
        default:
            return state;
    }
};

export default portalReducer;