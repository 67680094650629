import { useState } from "react";
import { AccountService } from "../../service/AccountService";
import { useDispatch } from "react-redux";
import { updateUsers } from "../../../../../../redux/action/accountActions";

const useUpdateUserData = () => {
    const dispatch = useDispatch();
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });

    const updateUser = async (accessToken: any, formState: any, currentRowIndex: any) => {
        try {
            const response: any = await AccountService.updateUser(accessToken, formState);
            if (response.success) {
                console.log("response user", response.data);
                dispatch(updateUsers(currentRowIndex, response.data));
            } else {
                console.log(response.errMessage);
                setSnackBarHandler({
                    open: true,
                    message: response.errMessage || "Failed to update the user",
                    severity: "error",
                });
            }
        } catch (error) {
            console.error("Error during user update: ", error);
            setSnackBarHandler({
                open: true,
                message: "An error occurred while updating the user.",
                severity: "error",
            });
        }
    };

    return { updateUser, snackBarHandler, setSnackBarHandler };
};

export default useUpdateUserData;