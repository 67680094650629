import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    Autocomplete,
    Typography,
    TextareaAutosize,
    Switch,
    FormControlLabel
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../../data/api/read';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import useUpdateCourseDetails from '../customHooks/useUpdateCourseDetails';

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}


interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateCourse = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // newValues = {
        //     "roleId": values.roleId,
        //     "roleName": values.roleName,
        //     "roleDescription": values.roleDescription,
        //     "loggedInUser": loggedInUser,
        //     "active": values.active === "true" ? true : false
        // };

        /// Handling the data of the form

        // For Course Type Name autocomplete component
        const [courseTypeNamesList, setCourseTypeNamesList] = useState<any>([]);
        const [courseCodeList, setCourseCodeList] = useState<any>([]);

        

        const [CourseTypeNamesDropDownOpen, setCourseTypeNamesDropDownOpen] = useState(false);


        //For setting form value in state

        const [academicYearOffered, setAcademicYearOffered] = useState<string>(originalValues.academicYearOffered);
        const [alhChLaboratory, setAlhChLaboratory] = useState<string>(originalValues.alhChLaboratory);
        const [alhChLecture, setAlhChLecture] = useState<string>(originalValues.alhChLecture);
        const [alhChOther, setAlhChOther] = useState<string>(originalValues.alhChOther);
        const [alhChTutorial, setAlhChTutorial] = useState<string>(originalValues.alhChTutorial);
        const [alhOlhAssignment, setAlhOlhAssignment] = useState<string>(originalValues.alhOlhAssignment);
        const [alhOlhLibrary, setAlhOlhLibrary] = useState<string>(originalValues.alhOlhLibrary);
        const [alhOlhOther, setAlhOlhOther] = useState<string>(originalValues.alhOlhOther);
        const [alhOlhResearch, setAlhOlhResearch] = useState<string>(originalValues.alhOlhResearch);
        const [alhOlhStudy, setAlhOlhStudy] = useState<string>(originalValues.alhOlhStudy);
        const [courseCoRequisite, setCourseCoRequisite] = useState<string>(originalValues.courseCoRequisite);
        const [courseCode, setCourseCode] = useState<string>(originalValues.courseCode);
        const [courseCreditHours, setCourseCreditHours] = useState<string>(originalValues.courseCreditHours);
        const [courseDesc, setCourseDesc] = useState<string>(originalValues.courseDesc);
        const [courseLevel, setCourseLevel] = useState<string>(originalValues.courseLevel);
        const [courseName, setCourseName] = useState<string>(originalValues.courseName);
        const [courseTypeNames, setCourseTypeNames] = useState<string>(originalValues.courseTypeId);
        const [coursePrerequisite, setCoursePrerequisite] = useState<string>(originalValues.coursePrerequisite);
        const [frAccomodation, setFrAccomodation] = useState<string>(originalValues.frAccomodation);
        const [frOtherResources, setFrOtherResources] = useState<string>(originalValues.frOtherResources);
        const [frTechnologyResources, setFrTechnologyResources] = useState<string>(originalValues.frTechnologyResources);
        const [labHoursPerWeek, setLabHoursPerWeek] = useState<string>(originalValues.labHoursPerWeek);
        const [moiBlended, setMoiBlended] = useState<string>(originalValues.moiBlended);
        const [moiCorrespondence, setMoiCorrespondence] = useState<string>(originalValues.moiCorrespondence);
        const [moiElearning, setMoiElearning] = useState<string>(originalValues.moiElearning);
        const [moiOthers, setMoiOthers] = useState<string>(originalValues.moiOthers);
        const [moiTraditionalClassroom, setMoiTraditionalClassroom] = useState<string>(originalValues.moiTraditionalClassroom);
        const [requiredElective, setRequiredElective] = useState<string | null>(originalValues.requiredElective || null);
        const [sadCommittee, setSadCommittee] = useState<string>(originalValues.sadCommittee);
        const [sadDate, setSadDate] = useState<string>(originalValues.sadDate);
        const [sadReferenceNo, setSadReferenceNo] = useState<string>(originalValues.sadReferenceNo);
        const [studentAcademicSupport, setStudentAcademicSupport] = useState<string>(originalValues.studentAcademicSupport);
        const [studyPlan, setStudyPlan] = useState<string>(originalValues.studyPlan);
        const [teachingHoursPerWeek, setTeachingHoursPerWeek] = useState<string>(originalValues.teachingHoursPerWeek);


        // For handling errors

        const [academicYearOfferedError, setAcademicYearOfferedError] = useState<boolean>(false);
        const [alhChLaboratoryError, setAlhChLaboratoryError] = useState<boolean>(false);
        const [alhChLectureError, setAlhChLectureError] = useState<boolean>(false);
        const [alhChOtherError, setAlhChOtherError] = useState<boolean>(false);
        const [alhChTutorialError, setAlhChTutorialError] = useState<boolean>(false);
        const [alhOlhAssignmentError, setAlhOlhAssignmentError] = useState<boolean>(false);
        const [alhOlhLibraryError, setAlhOlhLibraryError] = useState<boolean>(false);
        const [alhOlhOtherError, setAlhOlhOtherError] = useState<boolean>(false);
        const [alhOlhResearchError, setAlhOlhResearchError] = useState<boolean>(false);
        const [alhOlhStudyError, setAlhOlhStudyError] = useState<boolean>(false);
        const [courseCoRequisiteError, setCourseCoRequisiteError] = useState<boolean>(false);
        const [courseCodeError, setCourseCodeError] = useState<boolean>(false);
        const [courseCreditHoursError, setCourseCreditHoursError] = useState<boolean>(false);
        const [courseDescError, setCourseDescError] = useState<boolean>(false);
        const [courseLevelError, setCourseLevelError] = useState<boolean>(false);
        const [courseNameError, setCourseNameError] = useState<boolean>(false);
        const [courseTypeNamesError, setCourseTypeNamesError] = useState<boolean>(false);
        const [coursePrerequisiteError, setCoursePrerequisiteError] = useState<boolean>(false);
        const [frAccomodationError, setFrAccomodationError] = useState<boolean>(false);
        const [frOtherResourcesError, setFrOtherResourcesError] = useState<boolean>(false);
        const [frTechnologyResourcesError, setFrTechnologyResourcesError] = useState<boolean>(false);
        const [labHoursPerWeekError, setLabHoursPerWeekError] = useState<boolean>(false);
        const [moiBlendedError, setMoiBlendedError] = useState<boolean>(false);
        const [moiCorrespondenceError, setMoiCorrespondenceError] = useState<boolean>(false);
        const [moiElearningError, setMoiElearningError] = useState<boolean>(false);
        const [moiOthersError, setMoiOthersError] = useState<boolean>(false);
        const [moiTraditionalClassroomError, setMoiTraditionalClassroomError] = useState<boolean>(false);
        const [requiredElectiveError, setRequiredElectiveError] = useState<boolean>(false);
        const [sadCommitteeError, setSadCommitteeError] = useState<boolean>(false);
        const [sadDateError, setSadDateError] = useState<boolean>(false);
        const [sadReferenceNoError, setSadReferenceNoError] = useState<boolean>(false);
        const [studentAcademicSupportError, setStudentAcademicSupportError] = useState<boolean>(false);
        const [studyPlanError, setStudyPlanError] = useState<boolean>(false);
        const [teachingHoursPerWeekError, setTeachingHoursPerWeekError] = useState<boolean>(false);

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            setIsChecked(!isChecked);
          };

        const courseTypeOptions = courseTypeNamesList.map((course: any) => ({
            label: `${course.courseTypeName}`,
            value: course.id,
        }));

        const handleCourseTypeChange = (event: React.ChangeEvent<{}>, newValue: any) => {
            setCourseTypeNames(newValue ? newValue.value : null);
            setCourseTypeNamesError(newValue ? false : true);
        };


        const courseCodeListOptions = courseCodeList.map((courseCode: any) => ({
            label: `${courseCode.courseCode}: ${courseCode.courseName}`,
            value: courseCode.courseCode,
        }));

        const handleCoursePreRequisiteChange = (event: any, newValue: any) => {
            setCoursePrerequisite(newValue ? newValue.value : null);
        };

        const handleCourseCoRequisiteChange = (event: any, newValue: any) => {
            setCourseCoRequisite(newValue ? newValue.value : null);
        };

        const requiredElectiveOptions = ["Required", "Elective"];

        const handleRequiredElectiveChange = (event: React.ChangeEvent<{}>, newValue: string | null) => {
            setRequiredElective(newValue);
            if (requiredElectiveError) {
                setRequiredElectiveError(false);
            }
        }


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                axios.get(readAPI.CourseTypes, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setCourseTypeNamesList(res.data.obj);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                //fetching courses
                axios.get(readAPI.Courses, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setCourseCodeList(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            else {
                navigate("/login");
            }
        }, [navigate]);

        const { updateCourseDetailss, snackBarHandler, setSnackBarHandler } = useUpdateCourseDetails();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                // let accessToken: any = Cookies.get("accessToken");

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    // Set the validation errors
                    const numberRegex = /^\d+$/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    const validateForm = () => {
                        let hasError = false;
                        if (academicYearOffered === "") {
                            setAcademicYearOfferedError(true);
                            hasError = true;
                        }
                        if (alhChLaboratory === "") {
                            setAlhChLaboratoryError(true);
                            hasError = true;
                        }
                        if (alhChLecture === "") {
                            setAlhChLectureError(true);
                            hasError = true;
                        }
                        if (alhChOther === "") {
                            setAlhChOtherError(true);
                            hasError = true;
                        }
                        if (alhChTutorial === "") {
                            setAlhChTutorialError(true);
                            hasError = true;
                        }
                        if (alhOlhAssignment === "") {
                            setAlhOlhAssignmentError(true);
                            hasError = true;
                        }
                        if (alhOlhLibrary === "") {
                            setAlhOlhLibraryError(true)
                            hasError = true;
                        }
                        if (alhOlhOther === "") {
                            setAlhOlhOtherError(true);
                            hasError = true;
                        }
                        if (alhOlhResearch === "") {
                            setAlhOlhResearchError(true);
                            hasError = true;
                        }
                        if (alhOlhStudy === "") {
                            setAlhOlhStudyError(true);
                            hasError = true;
                        }
                        if (courseCoRequisite === "") {
                            setCourseCoRequisiteError(true);
                            hasError = true;
                        }
                        if (courseCode === "") {
                            setCourseCodeError(true);
                            hasError = true;
                        }
                        if (courseCreditHours === "") {
                            setCourseCreditHoursError(true);
                            hasError = true;
                        }
                        if (courseDesc === "") {
                            setCourseDescError(true);
                            hasError = true;
                        }
                        if (courseLevel === "") {
                            setCourseLevelError(true);
                            hasError = true;
                        }
                        if (courseName === "") {
                            setCourseNameError(true);
                            hasError = true;
                        }
                        if (coursePrerequisite === "") {
                            setCoursePrerequisiteError(true);
                            hasError = true;
                        }
                        if (frAccomodation === "") {
                            setFrAccomodationError(true);
                            hasError = true;
                        }
                        if (frOtherResources === "") {
                            setFrOtherResourcesError(true);
                            hasError = true;
                        }
                        if (frTechnologyResources === "") {
                            setFrTechnologyResourcesError(true);
                            hasError = true;
                        }
                        if (labHoursPerWeek === "") {
                            setLabHoursPerWeekError(true);
                            hasError = true;
                        }
                        if (moiBlended === "") {
                            setMoiBlendedError(true);
                            hasError = true;
                        }
                        if (moiCorrespondence === "") {
                            setMoiCorrespondenceError(true)
                            hasError = true;
                        }
                        if (moiElearning === "") {
                            setMoiElearningError(true);
                            hasError = true;
                        }
                        if (moiOthers === "") {
                            setMoiOthersError(true);
                            hasError = true;
                        }
                        if (moiTraditionalClassroom === "") {
                            setMoiTraditionalClassroomError(true);
                            hasError = true;
                        }
                        if (requiredElective === "") {
                            setRequiredElectiveError(true);
                            hasError = true;
                        }
                        if (sadCommittee === "") {
                            setSadCommitteeError(true);
                            hasError = true;
                        }
                        if (sadDate === "") {
                            setSadDateError(true);
                            hasError = true;
                        }
                        if (sadReferenceNo === "") {
                            setSadReferenceNoError(true);
                            hasError = true;
                        }
                        if (studentAcademicSupport === "") {
                            setStudentAcademicSupportError(true);
                            hasError = true;
                        }
                        if (studyPlan === "") {
                            setStudyPlanError(true);
                            hasError = true;
                        }
                        if (teachingHoursPerWeek === "") {
                            setTeachingHoursPerWeekError(true)
                            hasError = true;
                        }

                        // Return true if no errors, false if any error occurred
                        return !hasError;
                    }
                    // Set the validation errors

                    if (validateForm()) {
                        const formState = {
                            "academicYearOffered": academicYearOffered,
                            "alhChLaboratory": alhChLaboratory,
                            "alhChLecture": alhChLecture,
                            "alhChOther": alhChOther,
                            "alhChTutorial": alhChTutorial,
                            "alhOlhAssignment": alhOlhAssignment,
                            "alhOlhLibrary": alhOlhLibrary,
                            "alhOlhOther": alhOlhOther,
                            "alhOlhResearch": alhOlhResearch,
                            "alhOlhStudy": alhOlhStudy,
                            "courseCoRequisite": courseCoRequisite,
                            "courseCode": courseCode,
                            "courseCreditHours": courseCreditHours,
                            "courseDesc": courseDesc,
                            "courseLevel": courseLevel,
                            "courseName": courseName,
                            "coursePrerequisite": coursePrerequisite,
                            "courseType": courseTypeNames,
                            "frAccomodation": frAccomodation,
                            "frOtherResources": frOtherResources,
                            "frTechnologyResources": frTechnologyResources,
                            "labHoursPerWeek": labHoursPerWeek,
                            "moiBlended": moiBlended,
                            "moiCorrespondence": moiCorrespondence,
                            "moiElearning": moiElearning,
                            "moiOthers": moiOthers,
                            "moiTraditionalClassroom": moiTraditionalClassroom,
                            "requiredElective": requiredElective,
                            "sadCommittee": sadCommittee,
                            "sadDate": sadDate,
                            "sadReferenceNo": sadReferenceNo,
                            "studentAcademicSupport": studentAcademicSupport,
                            "studyPlan": studyPlan,
                            "teachingHoursPerWeek": teachingHoursPerWeek,
                            "active": isChecked
                        };

                        await updateCourseDetailss(formState, currentRowIndex);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                            setFetchUpdate(true)
                        }, 3000);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `${courseName} has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             const m = response.data.message;
                        //             if (response.data.code === "200.200") {
                        //                 setFetchUpdate(true)
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error: any) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginLeft: "-20px",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Course Specification
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseCodeTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.courseCode.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseCode.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseCodeError}
                                    // helperText={courseCodeErrorMessage}
                                    value={courseCode}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseCodeError) {
                                            setCourseCodeError(false);
                                        }
                                        setCourseCode(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseNameTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.courseName.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseNameError}
                                    // helperText={courseNameErrorMessage}
                                    value={courseName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseNameError) {
                                            setCourseNameError(false);
                                        }
                                        setCourseName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>

                                <Autocomplete
                                    id="courseTypeNamesComplete"
                                    options={courseTypeOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseTypeOptions.find((option: any) => option.value === courseTypeNames) || null}
                                    onChange={handleCourseTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeName.label')}
                                            placeholder={`${t('Home.Header.Modals.CourseType.policy.Inputs.courseTypeName.placeholder')}`}
                                            variant="standard"
                                            helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                            error={courseTypeNamesError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    id="requiredElectiveComplete"
                                    autoHighlight
                                    options={requiredElectiveOptions}
                                    value={requiredElective}
                                    onChange={handleRequiredElectiveChange}
                                    dir={currentLang === "ar" ? "rtl" : "ltr"}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.Course.policy.Inputs.requiredElective.label')}
                                            placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.requiredElective.placeholder')}`}
                                            variant="standard"
                                            helperText={requiredElectiveError ? "* Please select a Course Type." : ""}
                                            error={requiredElectiveError}
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseCreditHoursTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.courseCreditHours.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseCreditHours.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseCreditHoursError}
                                    // helperText={courseCreditHoursErrorMessage}
                                    value={courseCreditHours}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseCreditHoursError) {
                                            setCourseCreditHoursError(false);
                                        }
                                        setCourseCreditHours(e.target.value);
                                    }}
                                />
                            </Grid>
                           
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseLevelTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.courseLevel.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseLevel.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={courseLevelError}
                                    // helperText={courseLevelErrorMessage}
                                    value={courseLevel}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseLevelError) {
                                            setCourseLevelError(false);
                                        }
                                        setCourseLevel(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="courseDescTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.courseDesc.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseDesc.placeholder')}`}
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    margin="normal"
                                    fullWidth
                                    error={courseDescError}
                                    // helperText={courseDescErrorMessage}
                                    value={courseDesc}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (courseDescError) {
                                            setCourseDescError(false);
                                        }
                                        setCourseDesc(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="teachingHoursPerWeekTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.teachingHoursPerWeek.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.teachingHoursPerWeek.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={teachingHoursPerWeekError}
                                    // helperText={teachingHoursPerWeekErrorMessage}
                                    value={teachingHoursPerWeek}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (teachingHoursPerWeekError) {
                                            setTeachingHoursPerWeekError(false);
                                        }
                                        setTeachingHoursPerWeek(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="labHoursPerWeekTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.labHoursPerWeek.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.labHoursPerWeek.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={labHoursPerWeekError}
                                    // helperText={labHoursPerWeekErrorMessage}
                                    value={labHoursPerWeek}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (labHoursPerWeekError) {
                                            setLabHoursPerWeekError(false);
                                        }
                                        setLabHoursPerWeek(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="academicYearOfferedTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.academicYearOffered.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.academicYearOffered.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={academicYearOfferedError}
                                    // helperText={academicYearOfferedErrorMessage}
                                    value={academicYearOffered}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (academicYearOfferedError) {
                                            setAcademicYearOfferedError(false);
                                        }
                                        setAcademicYearOffered(e.target.value);
                                    }}
                                />
                                </Grid>
                                <Grid item xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}>
                                    <Autocomplete
                                        id="coursePrerequisiteComplete"
                                        options={courseCodeListOptions}
                                        getOptionLabel={option => option.label}
                                        value={courseCodeListOptions.find((option: any) => option.value === coursePrerequisite) || null}
                                        onChange={handleCoursePreRequisiteChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('Home.Header.Modals.Course.policy.Inputs.coursePrerequisite.label')}
                                                placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.coursePrerequisite.placeholder')}`}
                                                variant="standard"
                                                // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                                error={coursePrerequisiteError}
                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}>
                                    <Autocomplete
                                        id="courseCorequisiteComplete"
                                        options={courseCodeListOptions}
                                        getOptionLabel={option => option.label}
                                        value={courseCodeListOptions.find((option: any) => option.value === courseCoRequisite) || null}
                                        onChange={handleCourseCoRequisiteChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('Home.Header.Modals.Course.policy.Inputs.courseCoRequisite.label')}
                                                placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.courseCoRequisite.placeholder')}`}
                                                variant="standard"
                                                // helperText={(coursePrerequisiteErrorMessage) ? ("* Please select any College.") : ("")}
                                                error={courseCoRequisiteError}
                                                dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            />
                                        )}
                                    />
                                </Grid>


                            
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="studyPlanTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.studyPlan.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.studyPlan.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={studyPlanError}
                                    // helperText={studyPlanErrorMessage}
                                    value={studyPlan}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (studyPlanError) {
                                            setStudyPlanError(false);
                                        }
                                        setStudyPlan(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginLeft: "-20px",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Mode of Instructions
                                    </Typography>
                                    {/* <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#696969",
                                        fontWeight: 300
                                    }}
                                >
                                    {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.subTitle')}
                                </Typography> */}
                                </Box>
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="moiTraditionalClassroomTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.moiTraditionalClassroom.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.moiTraditionalClassroom.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={moiTraditionalClassroomError}
                                    // helperText={moiTraditionalClassroomErrorMessage}
                                    value={moiTraditionalClassroom}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (moiTraditionalClassroomError) {
                                            setMoiTraditionalClassroomError(false);
                                        }
                                        setMoiTraditionalClassroom(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="moiBlendedTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.moiBlended.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.moiBlended.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={moiBlendedError}
                                    // helperText={moiBlendedErrorMessage}
                                    value={moiBlended}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (moiBlendedError) {
                                            setMoiBlendedError(false);
                                        }
                                        setMoiBlended(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="moiElearningTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.moiElearning.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.moiElearning.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={moiElearningError}
                                    // helperText={moiElearningErrorMessage}
                                    value={moiElearning}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (moiElearningError) {
                                            setMoiElearningError(false);
                                        }
                                        setMoiElearning(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="moiCorrespondenceTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.moiCorrespondence.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.moiCorrespondence.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={moiCorrespondenceError}
                                    // helperText={moiCorrespondenceErrorMessage}
                                    value={moiCorrespondence}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (moiCorrespondenceError) {
                                            setMoiCorrespondenceError(false);
                                        }
                                        setMoiCorrespondence(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="moiOthersTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.moiOthers.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.moiOthers.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={moiOthersError}
                                    // helperText={moiOthersErrorMessage}
                                    value={moiOthers}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (moiOthersError) {
                                            setMoiOthersError(false);
                                        }
                                        setMoiOthers(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginLeft: "-20px",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Actual Learning Hours
                                    </Typography>
                                    {/* <Typography
                                    variant="body1"
                                    sx={{
                                        color: "#696969",
                                        fontWeight: 300
                                    }}
                                >
                                    {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.subTitle')}
                                </Typography> */}
                                </Box>
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3 }}>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: "#696969",
                                            fontWeight: 400,
                                            marginLeft: "-20px",
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.subTitle')} */}
                                        Contact Hours:
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhChLectureTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhChLecture.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhChLecture.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhChLectureError}
                                    // helperText={alhChLectureErrorMessage}
                                    value={alhChLecture}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhChLectureError) {
                                            setAlhChLectureError(false);
                                        }
                                        setAlhChLecture(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhChLaboratoryTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhChLaboratory.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhChLaboratory.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhChLaboratoryError}
                                    // helperText={alhChLaboratoryErrorMessage}
                                    value={alhChLaboratory}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhChLaboratoryError) {
                                            setAlhChLaboratoryError(false);
                                        }
                                        setAlhChLaboratory(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhChTutorialTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhChTutorial.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhChTutorial.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhChTutorialError}
                                    // helperText={alhChTutorialErrorMessage}
                                    value={alhChTutorial}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhChTutorialError) {
                                            setAlhChTutorialError(false);
                                        }
                                        setAlhChTutorial(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhChOtherTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhChOther.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhChOther.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhChOtherError}
                                    // helperText={alhChOtherErrorMessage}
                                    value={alhChOther}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhChOtherError) {
                                            setAlhChOtherError(false);
                                        }
                                        setAlhChOther(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3 }}>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            marginLeft: "-20px",
                                            color: "#696969",
                                            fontWeight: 400
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.subTitle')} */}
                                        Other Learning Hours:
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhOlhStudyTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhOlhStudy.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhOlhStudy.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhOlhStudyError}
                                    // helperText={alhOlhStudyErrorMessage}
                                    value={alhOlhStudy}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhOlhStudyError) {
                                            setAlhOlhStudyError(false);
                                        }
                                        setAlhOlhStudy(e.target.value);
                                    }}
                                />
                            </Grid>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhOlhAssignmentTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhOlhAssignment.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhOlhAssignment.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhOlhAssignmentError}
                                    // helperText={alhOlhAssignmentErrorMessage}
                                    value={alhOlhAssignment}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhOlhAssignmentError) {
                                            setAlhOlhAssignmentError(false);
                                        }
                                        setAlhOlhAssignment(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhOlhLibraryTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhOlhLibrary.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhOlhLibrary.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhOlhLibraryError}
                                    // helperText={alhOlhLibraryErrorMessage}
                                    value={alhOlhLibrary}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhOlhLibraryError) {
                                            setAlhOlhLibraryError(false);
                                        }
                                        setAlhOlhLibrary(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhOlhResearchTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhOlhResearch.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhOlhResearch.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhOlhResearchError}
                                    // helperText={alhOlhResearchErrorMessage}
                                    value={alhOlhResearch}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhOlhResearchError) {
                                            setAlhOlhResearchError(false);
                                        }
                                        setAlhOlhResearch(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="alhOlhOtherTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.alhOlhOther.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.alhOlhOther.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={alhOlhOtherError}
                                    // helperText={alhOlhOtherErrorMessage}
                                    value={alhOlhOther}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (alhOlhOtherError) {
                                            setAlhOlhOtherError(false);
                                        }
                                        setAlhOlhOther(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginLeft: "-20px",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Student Academic Support
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControl fullWidth>
                                    <TextareaAutosize
                                        id="studentAcademicSupportTextField"
                                        // label={t('Home.Header.Modals.Course.policy.Inputs.studentAcademicSupport.label')}
                                        placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.studentAcademicSupport.placeholder')}`}
                                        // variant="standard"
                                        // margin="normal"
                                        // fullWidth
                                        aria-label="minimum height"
                                        minRows={5}
                                        // error={studentAcademicSupportError}
                                        // helperText={studentAcademicSupportErrorMessage}
                                        value={studentAcademicSupport}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        onChange={(e) => {
                                            if (studentAcademicSupportError) {
                                                setStudentAcademicSupportError(false);
                                            }
                                            setStudentAcademicSupport(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>



                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginLeft: "-20px",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Facilities Required
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControl fullWidth>
                                    <TextareaAutosize
                                        id="frAccomodationTextField"
                                        // label={t('Home.Header.Modals.Course.policy.Inputs.frAccomodation.label')}
                                        placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.frAccomodation.placeholder')}`}
                                        // variant="standard"
                                        // margin="normal"
                                        aria-label="minimum height"
                                        minRows={5}
                                        // error={frAccomodationError}
                                        // helperText={frAccomodationErrorMessage}
                                        value={frAccomodation}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        onChange={(e) => {
                                            if (frAccomodationError) {
                                                setFrAccomodationError(false);
                                            }
                                            setFrAccomodation(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControl fullWidth>
                                    <TextareaAutosize
                                        id="frTechnologyResourcesTextField"
                                        // label={t('Home.Header.Modals.Course.policy.Inputs.frTechnologyResources.label')}
                                        placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.frTechnologyResources.placeholder')}`}
                                        // variant="standard"
                                        // margin="normal"
                                        // fullWidth
                                        aria-label="minimum height"
                                        minRows={5}
                                        // error={frTechnologyResourcesError}
                                        // helperText={frTechnologyResourcesErrorMessage}
                                        value={frTechnologyResources}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        onChange={(e) => {
                                            if (frTechnologyResourcesError) {
                                                setFrTechnologyResourcesError(false);
                                            }
                                            setFrTechnologyResources(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControl fullWidth>
                                    <TextareaAutosize
                                        id="frOtherResourcesTextField"
                                        // label={t('Home.Header.Modals.Course.policy.Inputs.frOtherResources.label')}
                                        placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.frOtherResources.placeholder')}`}
                                        // variant="standard"
                                        // margin="normal"
                                        // fullWidth
                                        aria-label="minimum height"
                                        minRows={5}
                                        // error={frOtherResourcesError}
                                        // helperText={frOtherResourcesErrorMessage}
                                        value={frOtherResources}
                                        dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        onChange={(e) => {
                                            if (frOtherResourcesError) {
                                                setFrOtherResourcesError(false);
                                            }
                                            setFrOtherResources(e.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>


                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <Box sx={{ ml: 3, mt: 5 }}>
                                    <Typography variant="h6"
                                        sx={{
                                            // color: "#312a2c",
                                            color: "#3c6766",
                                            marginLeft: "-20px",
                                            fontWeight: 400,
                                            borderBottom: "1px solid",
                                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                                            display: "flex",
                                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row"),
                                        }}
                                    >
                                        {/* {t('Home.Sidebar.list.userManagement.subMenu.Users.details.Permissions.title')} */}
                                        Specification Approval Data
                                    </Typography>
                                </Box>
                            </Grid>




                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="sadCommitteeTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.sadCommittee.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.sadCommittee.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={sadCommitteeError}
                                    // helperText={sadCommitteeErrorMessage}
                                    value={sadCommittee}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (sadCommitteeError) {
                                            setSadCommitteeError(false);
                                        }
                                        setSadCommittee(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="sadDateTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.sadDate.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.sadDate.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={sadDateError}
                                    // helperText={sadDateErrorMessage}
                                    value={sadDate}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (sadDateError) {
                                            setSadDateError(false);
                                        }
                                        setSadDate(e.target.value);
                                    }}
                                    defaultValue="2023-05-24"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="sadReferenceNoTextField"
                                    label={t('Home.Header.Modals.Course.policy.Inputs.sadReferenceNo.label')}
                                    placeholder={`${t('Home.Header.Modals.Course.policy.Inputs.sadReferenceNo.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={sadReferenceNoError}
                                    // helperText={sadReferenceNoErrorMessage}
                                    value={sadReferenceNo}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (sadReferenceNoError) {
                                            setSadReferenceNoError(false);
                                        }
                                        setSadReferenceNo(e.target.value);
                                    }}
                                />
                            </Grid>


                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>



                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCourse;