import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../store';
import { SurveyService } from '../../Apps/survey/service/SurveyService';

export const GET_SURVEY_LINK = 'GET_SURVEY_LINK';
export const FETCH_SURVEY_DATA_REQUEST = 'FETCH_SURVEY_DATA_REQUEST';
export const FETCH_SURVEY_DATA_SUCCESS = 'FETCH_SURVEY_DATA_SUCCESS';
export const FETCH_SURVEY_DATA_FAILURE = 'FETCH_SURVEY_DATA_FAILURE';
export const ADD_SURVEY = 'ADD_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const DELETE_SURVEY = 'DELETE_SURVEY'; 


  //@ function for survey data ============================================================

export const fetchSurveyRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
      dispatch({ type: FETCH_SURVEY_DATA_REQUEST });
      try {
          const response = await SurveyService.getAllSurveyDetails();
          dispatch({ type: FETCH_SURVEY_DATA_SUCCESS, payload: response.data });
      } catch (error: any) {
          dispatch({ type: FETCH_SURVEY_DATA_FAILURE, payload: error.message });
      }
  };
}

export const addSurveyDataRedux = (data: any) => ({
  type: ADD_SURVEY,
  payload: { data }
});

export const updateSurveyDataRedux = (updatedSurvey: any, id: any) => ({
  type: UPDATE_SURVEY,
  payload: { updatedSurvey, id },
});

export const deleteSurveyDataRedux = (id: number ) => ({
  type: DELETE_SURVEY,
  payload: { id }
});


// export const surveyLinkList = (data: any) => ({
//   type: GET_SURVEY_LINK,
//   payload: {data}
// })
