import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControlLabel,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../../data/api/read';

import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import useUpdateProgram from '../customHooks/useUpdateProgram';


interface ViewAllProgramData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        programId: string;
        programApprovalDate: number[] | null;
        programDesc: string;
        programDuration: number;
        programMission: string;
        programStartDate: number[] | null;
        programTitle: string;
        programTotalCredits: string;
        programVision: string;
        targetLevel: string;
        programWebAddress: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateProgram = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // Fetching data using axios

        const [viewAllProgramData, setViewAllProgramData] = useState<ViewAllProgramData | null>(null);

        const [ProgramApprovalDate, setProgramApprovalDate] = useState<string>(originalValues.programApprovalDate);
        const [ProgramDesc, setProgramDesc] = useState<string>(originalValues.programDesc);
        const [ProgramDuration, setProgramDuration] = useState<string>(originalValues.programDuration);
        const [ProgramStartDate, setProgramStartDate] = useState<string>(originalValues.programStartDate);
        const [ProgramTitle, setProgramTitle] = useState<string>(originalValues.programTitle);
        const [ProgramTotalCredits, setProgramTotalCredits] = useState<string>(originalValues.programTotalCredits)
        const [ProgramMission, setProgramMission] = useState<string>(originalValues.programMission);
        const [ProgramVision, setProgramVision] = useState<string>(originalValues.programVision);
        const [ProgramWebAddress, setProgramWebAddress] = useState<string>(originalValues.programWebAddress);
        const [TargetLevel, setTargetLevel] = useState<string>(originalValues.targetLevel);

        // For handling errors

        const [ProgramApprovalDateError, setProgramApprovalDateError] = useState<boolean>(false);
        const [ProgramDescError, setProgramDescError] = useState<boolean>(false);
        const [ProgramDurationError, setProgramDurationError] = useState<boolean>(false);
        const [ProgramStartDateError, setProgramStartDateError] = useState<boolean>(false);
        const [ProgramTitleError, setProgramTitleError] = useState<boolean>(false);
        const [ProgramTotalCreditsError, setProgramTotalCreditsError] = useState<boolean>(false);
        const [ProgramMissionError, setProgramMissionError] = useState<boolean>(false);
        const [TargetLevelError, setTargetLevelError] = useState<boolean>(false);
        const [ProgramVisionError, setProgramVisionError] = useState<boolean>(false);
        const [ProgramWebAddressError, setProgramWebAddressError] = useState<boolean>(false);

        // For handling error messages
        const [ProgramApprovalDateErrorMessage, setProgramApprovalDateErrorMessage] = useState<string>("");
        const [ProgramDescErrorMessage, setProgramDescErrorMessage] = useState<string>("");
        const [ProgramDurationErrorMessage, setProgramDurationErrorMessage] = useState<string>("");
        const [ProgramStartDateErrorMessage, setProgramStartDateErrorMessage] = useState<string>("");
        const [ProgramTitleErrorMessage, setProgramTitleErrorMessage] = useState<string>("");
        const [ProgramTotalCreditsErrorMessage, setProgramTotalCreditsErrorMessage] = useState<string>("")
        const [ProgramMissionErrorMessage, setProgramMissionErrorMessage] = useState<string>("");
        const [ProgramVisionErrorMessage, setProgramVisionErrorMessage] = useState<string>("");
        const [ProgramWebAddressErrorMessage, setProgramWebAddressErrorMessage] = useState<string>("");
        const [TargetLevelErrorMessage, setTargetLevelErrorMessage] = useState<string>("");
        // Status radio buttons


        useEffect(() => {
            if (ProgramApprovalDate && Array.isArray(ProgramApprovalDate)) {
                const [year, month, day] = ProgramApprovalDate;
                const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                setProgramApprovalDate(formattedDate);
            }
            if (ProgramStartDate && Array.isArray(ProgramStartDate)) {
                const [year, month, day] = ProgramStartDate;
                const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
                setProgramStartDate(formattedDate);
            }
        }, [ProgramStartDate, ProgramApprovalDate]);

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            setIsChecked(!isChecked);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        useEffect(() => {
            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }
            if (accessToken !== null) {
                // @1) Fetching Users
                axios.get(readAPI.Program, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        if (res.data.code === "200.200") {
                            setViewAllProgramData(res.data);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }, [])

        const { snackBarHandler, setSnackBarHandler, updatePrograms } = useUpdateProgram();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (viewAllProgramData !== null && accessToken !== null) {
                    // Set the validation errors
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const numberRegex = /^\d+$/;
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

                    if (ProgramApprovalDate === "") {
                        setProgramApprovalDateError(true);
                    }
                    if (ProgramDesc === "") {
                        setProgramDescError(true);
                    }
                    if (!textRegex.test(ProgramDesc) && ProgramDesc.length == 0) {
                        setProgramDescErrorMessage("Please enter only alphabets")
                        setProgramDescError(true);
                    } else {
                        setProgramDescError(false);
                        setProgramDescErrorMessage("")
                    }
                    if (ProgramDuration === "") {
                        setProgramDurationError(true);
                    }
                    if (ProgramStartDate === "") {
                        setProgramStartDateError(true);
                    }
                    if (ProgramTitle === "") {
                        setProgramTitleError(true);
                    }
                    if (!textRegex.test(ProgramTitle) && ProgramTitle.length == 0) {
                        setProgramTitleErrorMessage("Please enter only alphabets")
                        setProgramTitleError(true);
                    } else {
                        setProgramTitleError(false);
                        setProgramTitleErrorMessage("")
                    }
                    if (ProgramTotalCredits === "") {
                        setProgramTotalCreditsError(true);
                    }
                    if (ProgramMission === "") {
                        setProgramMissionError(true);
                    }
                    if (!textRegex.test(ProgramMission) && ProgramMission.length == 0) {
                        setProgramMissionErrorMessage("Please enter only alphabets")
                        setProgramMissionError(true);
                    } else {
                        setProgramMissionError(false);
                        setProgramMissionErrorMessage("")
                    }
                    if (ProgramVision === "") {
                        setProgramVisionError(true);
                    }
                    if (TargetLevel === "") {
                        setTargetLevelError(true);
                    }
                    if (!textRegex.test(ProgramVision) && ProgramVision.length == 0) {
                        setProgramVisionErrorMessage("Please enter only alphabets")
                        setProgramVisionError(true);
                    } else {
                        setProgramVisionError(false);
                        setProgramVisionErrorMessage("")
                    }
                    if (ProgramWebAddress === "") {
                        setProgramWebAddressError(true);
                    }
                    if (!urlRegex.test(ProgramWebAddress)) {
                        setProgramWebAddressErrorMessage("Invalid URL format")
                        setProgramWebAddressError(true);
                    }
                    // Set the validation errors

                    if (ProgramApprovalDate !== "" && ProgramDesc !== "" && ProgramDuration !== "" && ProgramMission !== "" &&
                        ProgramVision !== "" && ProgramDuration !== "" && ProgramWebAddress !== "" && ProgramTitle !== ""
                        && ProgramStartDate !== "" && TargetLevel !== ""
                    ) {
                        const formState = {
                            "programId": originalValues.programId,
                            "programApprovalDate": ProgramApprovalDate,
                            "programDesc": ProgramDesc,
                            "programDuration": ProgramDuration,
                            "programMission": ProgramMission,
                            "programStartDate": ProgramStartDate,
                            "programTitle": ProgramTitle,
                            "programTotalCredits": ProgramTotalCredits,
                            "programVision": ProgramVision,
                            "programWebAddress": ProgramWebAddress,
                            "targetLevel": TargetLevel,
                            "active": isChecked
                        };

                        await updatePrograms(formState, currentRowIndex)
                        setOpenUpdateTableModal(false);
                        setFetchUpdate(true);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `${ProgramTitle} data has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             if (response.data.code === "200.200") {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                     setFetchUpdate(true);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error: any) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                {/* <div style={{ marginTop: 5, flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row") }} className={`${(windowSize[0] > 990) ? ("d-flex justify-content-between") : ("d-flex flex-column justify-content-start")}`}>
                <div>
                    {(currentLang === "ar") ? (
                        <>
                            <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span> / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / EQA
                        </>
                    ) : (
                        <>
                            EQA / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f2')} / {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f3')} / <span style={{ color: "#4f747a" }}> {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.breadcrumb.f4')} </span>
                        </>
                    )}
                </div>
                <div>
                    <span style={{ color: "#4f747a", paddingRight: 10 }}>{currentFormatedDate}</span>
                </div>
            </div> */}

                {/* <hr /> */}

                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                    {/* <Box sx={{
                    // border: "1px solid red",
                    display: "flex",
                    marginBottom: 2,
                    alignItems: (currentLang === "ar") ? ("flex-end") : ("flex-start"),
                    flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                }}>
                    <Box sx={{
                        // border: "1px solid black",
                        // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                        // boxShadow: "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;",
                        boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;",
                        width: 60,
                        height: 60,
                        borderRadius: 1.25,
                        backgroundColor: "#fffefe",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <PeopleOutlineIcon
                            sx={{
                                color: "#4f747a",
                                fontSize: 35,
                                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;", 
                            }} />
                    </Box>
                    <Box sx={{ ml: 3 }}>
                        <Typography variant="h5" sx={{
                            // color: "#312a2c",
                            color: "#3c6766",
                            fontWeight: 500,
                            marginTop: (windowSize[0] < 600) ? (0) : (0.5),
                            display: "flex",
                            flexDirection: (currentLang === "ar") ? ("row-reverse") : ("row")
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.title')}
                        </Typography>
                        <Typography variant="body1" sx={{
                            // color: "#4f747a" 
                            // color: "#C0C0C0"
                            color: "#696969",
                            fontWeight: 300
                        }}>
                            {t('Home.Sidebar.list.userManagement.subMenu.roles.details.Add.subTitle')}
                        </Typography>
                    </Box>
                </Box> */}

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramTitleTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramTitle.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramTitle.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramTitleError}
                                    helperText={ProgramTitleErrorMessage}
                                    value={ProgramTitle}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramTitleError) {
                                            setProgramTitleError(false);
                                        }
                                        setProgramTitle(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramWebAddressTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramWebAddress.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramWebAddress.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramWebAddressError}
                                    helperText={ProgramWebAddressErrorMessage}
                                    value={ProgramWebAddress}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramWebAddressError) {
                                            setProgramWebAddressError(false);
                                        }
                                        setProgramWebAddress(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramMissionTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramMission.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramMission.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramMissionError}
                                    helperText={ProgramMissionErrorMessage}
                                    value={ProgramMission}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramMissionError) {
                                            setProgramMissionError(false);
                                        }
                                        setProgramMission(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>



                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramVisionTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramVision.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramVision.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramVisionError}
                                    helperText={ProgramVisionErrorMessage}
                                    value={ProgramVision}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramVisionError) {
                                            setProgramVisionError(false);
                                        }
                                        setProgramVision(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>

                            <Grid item xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramDescTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramDesc.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramDesc.placeholder')}`}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramDescError}
                                    // helperText={ProgramDescErrorMessage}
                                    value={ProgramDesc}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramDescError) {
                                            setProgramDescError(false);
                                        }
                                        setProgramDesc(e.target.value);
                                    }}
                                    multiline
                                    rows={10}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramStartDateTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramStartDate.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramStartDate.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramStartDateError}
                                    // helperText={ProgramStartDateErrorMessage}
                                    value={ProgramStartDate}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramStartDateError) {
                                            setProgramStartDateError(false);
                                        }
                                        setProgramStartDate(e.target.value);
                                    }}
                                    defaultValue="2023-05-24"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramApprovalDateTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramApprovalDate.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramApprovalDate.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramApprovalDateError}
                                    helperText={ProgramApprovalDateErrorMessage}
                                    value={ProgramApprovalDate}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramApprovalDateError) {
                                            setProgramApprovalDateError(false);
                                        }
                                        setProgramApprovalDate(e.target.value);
                                    }}
                                    defaultValue="2023-05-24"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramDurationTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramDuration.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramDuration.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramDurationError}
                                    // helperText={ProgramDurationErrorMessage}
                                    value={ProgramDuration}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramDurationError) {
                                            setProgramDurationError(false);
                                        }
                                        setProgramDuration(e.target.value);
                                    }}
                                />
                            </Grid>



                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="ProgramTotalCreditsTextField"
                                    label={t('Home.Header.Modals.Program.policy.Inputs.ProgramTotalCredits.label')}
                                    placeholder={`${t('Home.Header.Modals.Program.policy.Inputs.ProgramTotalCredits.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramTotalCreditsError}
                                    helperText={ProgramTotalCreditsErrorMessage}
                                    value={ProgramTotalCredits}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramTotalCreditsError) {
                                            setProgramTotalCreditsError(false);
                                        }
                                        setProgramTotalCredits(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>

                        </Grid>
                    </Box>
                </Box>


                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateProgram;