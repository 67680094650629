import axios from 'axios'
import { IUsersType, IGroupsType, IUserGroupType, IRoleType, IGroupRoleType, IApplicationType, IAppForm, IRoleAppPrevilege } from "../model/Account"
import readAPI from '../../../../../Data/API/READ';
import createAPI from '../../../../../Data/API/CREATE';
import updateAPI from '../../../../../Data/API/UPDATE';
import axiosInstance from '../../../../../Service/interceptor';

export type UsersType = {
    data: IUsersType[],
    errMessage: ""
}

export type ResponseType = {
    success: boolean;
    errMessage: string;
    data?: any;
}

export type Groupstype = {
    data: IGroupsType[],
    errMessage: ""
}

export type UserGroupType = {
    data: IUserGroupType[],
    errMessage: ""
}

export type RoleType = {
    data: IRoleType[],
    errMessage: ""
}

export type GroupRoleType = {
    data: IGroupRoleType[],
    errMessage: ""
}

export type AppsRoleType = {
    data: IApplicationType[],
    errMessage: ""
}

export type AppFormType = {
    data: IAppForm[],
    errMessage: ""
}

export type RoleAppPrivilegeType = {
    data: IRoleAppPrevilege[],
    errMessage: ""
}


//@ calling get users api ============================================================

async function getAllUsers(): Promise<UsersType> {
    let url: string = readAPI.Users;
    const userType: UsersType = {
        data: [],
        errMessage: ""
    };
    try {
        const res = await axiosInstance.get(url);
        userType.data = res.data.obj;
        if (res.data.code !== "200.200") {
            userType.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        userType.errMessage = err.message;
    }
    return userType;
}


//@ calling add user api ============================================================

async function createUser(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Users;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.post(url, userData);

        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}


//@ calling update user api ===============================================================

async function updateUser(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Users;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get group api ===============================================================

async function getAllGroups(): Promise<Groupstype> {
    let url: string = readAPI.Groups;
    const groupTypeData: any = {
        data: [],
        errMessage: ""
    }

    try {
        const res = await axiosInstance.get(url);
        groupTypeData.data = res.data.obj;
        if (res.data.code !== "200.200") {
            groupTypeData.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        groupTypeData.errMessage = err.message;
    }
    return groupTypeData;
}

//@ calling add group api =====================================================================

async function createGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Groups;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling update group api ===============================================================

async function updateGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Groups;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get user group api ============================================================

async function getAllUserGroup(): Promise<UserGroupType> {
    let url: string = readAPI.UserGroup;
    const userGroupType: UserGroupType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        // userGroupType.data = res.data.obj;
        userGroupType.data = res.data.obj;
        userGroupType.data.forEach((element: any) => {
            element.userId = element.user.userId;
            element.grpId = element.group.grpId;
            element.user = element.user.userId + " " + element.user.userName;
            element.group = element.group.grpId + " " + element.group.grpName;
            element.active = element.active === true ? "true" : "false";
        });
        if (res.data.code !== "200.200") {
            userGroupType.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        userGroupType.errMessage = err.message;
    }
    return userGroupType;
}


//@ calling update user group api ===============================================================

async function updateUserGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.UserGroup;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling create user group api ===============================================================

async function createUserGroup(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.UserGroup;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get role api ===============================================================

async function getAllRole(): Promise<RoleType> {
    let url: string = readAPI.Roles;
    const roleTypeData: RoleType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        roleTypeData.data = res.data.obj;
        if (res.data.code !== "200.200") {
            roleTypeData.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        roleTypeData.errMessage = err.message;
    }

    return roleTypeData;
}

//@ calling create role api ===============================================================

async function createRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Roles;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get group role api ============================================================

async function getAllGroupRole(): Promise<GroupRoleType> {
    let url: string = readAPI.GroupRole;
    const groupRoleType: GroupRoleType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        groupRoleType.data = res.data.obj;
        // Adding a new property appId in the array
        groupRoleType.data.forEach((element: any) => {
            let grpId = element.groupDetails.grpId;
            element.grpId = grpId;
            element.groupDetails = element.groupDetails.grpId + " : " + element.groupDetails.grpName;
            let roleId = element.role.roleId;
            element.roleId = roleId;
            element.role = element.role.roleId + " : " + element.role.roleName;
        });
        if (res.data.code !== "200.200") {
            groupRoleType.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        groupRoleType.errMessage = err.message;
    }

    return groupRoleType;
}

//@ calling create role api ===============================================================

async function createGroupRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.GroupRole;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: []
    }
    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateGroupRole(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.GroupRole;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get application api ===============================================================

async function getAllApplication(): Promise<AppsRoleType> {
    let url: string = readAPI.Apps;
    const applicationTypeData: AppsRoleType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        applicationTypeData.data = res.data.obj;
        if (res.data.code !== "200.200") {
            applicationTypeData.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        applicationTypeData.errMessage = err.message;
    }

    return applicationTypeData;
}


//@ calling create role api ===============================================================

async function createApps(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.Apps;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateApps(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.Apps;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling get app form api ============================================================

async function getAllAppForm(): Promise<AppFormType> {
    let url: string = readAPI.AppForm;
    const appFormType: AppFormType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        appFormType.data = res.data.obj;
        if (res.data.code !== "200.200") {
            appFormType.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        appFormType.errMessage = err.message;
    }

    return appFormType;
}

//@ calling create app form api ===============================================================

async function createAppForm(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.AppForm;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

//@ calling update role api ===============================================================

async function updateAppForm(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.AppForm;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }
    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}


//@ calling get role app privilege api ============================================================

async function getAllRoleAppPrivilege(): Promise<RoleAppPrivilegeType> {
    let url: string = readAPI.RoleApp;
    const roleAppDataType: RoleAppPrivilegeType = {
        data: [],
        errMessage: ""
    }
    try {
        const res = await axiosInstance.get(url);
        roleAppDataType.data = res.data.obj;
        roleAppDataType.data.forEach((element: any) => {
            element.roleId = element.role.roleId;
            element.role = element.role.roleId + " " + element.role.roleName;
            // element.appDetails = element.appDetails.appId + " " + element.appDetails.appName;
            element.appId = element.appDetails.appId;
            element.formId = element.appForms.formId;
            element.appForms = element.appForms.formId + " " + element.appForms.formName;
            element.createPermission = element.createPermission === true ? "true" : "false";
            element.readPermission = element.readPermission === true ? "true" : "false";
            element.updatePermission = element.updatePermission === true ? "true" : "false";
            element.deletePermission = element.deletePermission === true ? "true" : "false";
            element.active = element.active === true ? "true" : "false";
        });
        if (res.data.code !== "200.200") {
            roleAppDataType.errMessage = res.data.error;
        }
    } catch (err: any) {
        console.log(err);
        roleAppDataType.errMessage = err.message;
    }

    return roleAppDataType;
}


//@ calling add user api ============================================================

async function createRoleApp(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = createAPI.RoleApp;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.post(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}


//@ calling update user api ===============================================================

async function updateRoleApp(accessToken: string, userData: any): Promise<ResponseType> {
    let url: string = updateAPI.RoleApp;
    let header: string = accessToken;
    const userResponse: ResponseType = {
        success: false,
        errMessage: "",
        data: {}
    }

    try {
        const res = await axiosInstance.put(url, userData);
        if (res.status === 200 && res.data.code === "200.200") {
            userResponse.success = true;
            userResponse.data = res.data.obj;
        } else {
            userResponse.errMessage = res.data.message;
        }
    } catch (err: any) {
        userResponse.errMessage = err.message;
    }

    return userResponse;
}

export const AccountService = {
    getAllUsers,
    createUser,
    updateUser,
    getAllGroups,
    createGroup,
    updateGroup,
    getAllUserGroup,
    createUserGroup,
    updateUserGroup,
    getAllRole,
    createRole,
    updateRole,
    getAllGroupRole,
    createGroupRole,
    updateGroupRole,
    getAllApplication,
    createApps,
    updateApps,
    getAllAppForm,
    createAppForm,
    updateAppForm,
    getAllRoleAppPrivilege,
    createRoleApp,
    updateRoleApp
}