import { useState } from "react";
import { useDispatch } from "react-redux";
import { CommitteeService } from "../../service/CommitteeService";
import { updateCommitteeDataRedux } from "../../../../redux/action/committeeActions";

const useUpdateCommittee = () => {
    const dispatch = useDispatch();
    const [snackBarHandler, setSnackBarHandler] = useState({
        open: false,
        message: '',
        severity: '',
    });

    const updateCommittees = async (formState: any, currentRowIndex: any) => {
        try {
            const response: any = await CommitteeService.updateCommittee(formState);
            if (response.success) {
                dispatch(updateCommitteeDataRedux(currentRowIndex, formState));
                setSnackBarHandler({
                    severity: (response.success == true) ? "success" : "error",
                    message: (response.success == true) ? `${response.data.committeeName} data has been updated successfully` : (response.data.message),
                    open: true
                })
            } else {
                console.log(response.errMessage);
                setSnackBarHandler({
                    open: true,
                    message: response.errMessage || "Failed to update the user",
                    severity: "error",
                });
            }
        } catch (error) {
            console.error("Error during user update: ", error);
            setSnackBarHandler({
                open: true,
                message: "An error occurred while updating the user.",
                severity: "error",
            });
        }
    };

    return { updateCommittees, snackBarHandler, setSnackBarHandler };
};

export default useUpdateCommittee;