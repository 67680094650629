import React, { Component, ErrorInfo, ReactNode } from 'react';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        // Initial state to track error occurrence
        this.state = { hasError: false };
    }

    // This method updates the state if there is an error
    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    // You can log error details using this method (optional)
    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
        // If an error has occurred, render a fallback UI
        if (this.state.hasError) {
            return <div>Something went wrong. Please try again later.</div>;
        }

        // Otherwise, render the children (i.e., the normal UI)
        return this.props.children;
    }
}

export default ErrorBoundary;
