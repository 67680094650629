import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import useUpdateAssessment from '../../customHooks/useUpdateAssessment';


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateAssessment = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // newValues = {
        //     "roleId": values.roleId,
        //     "roleName": values.roleName,
        //     "roleDescription": values.roleDescription,
        //     "loggedInUser": loggedInUser,
        //     "active": values.active === "true" ? true : false
        // };

        // For field validation


        const [assessmentId, setassessmentId] = useState<string>(originalValues.assessmentId);
        const [assessmentTypeId, setassessmentTypeId] = useState<string>(originalValues.assessmentTypeId);
        const [assessmentDate, setassessmentDate] = useState<string>(originalValues.assessmentDate);
        const [assessmentTime, setassessmentTime] = useState<string>(originalValues.assessmentTime);
        const [maximumMarks, setmaximumMarks] = useState<string>(originalValues.maximumMarks);
        const [noOfQuestions, setnoOfQuestions] = useState<string>(originalValues.noOfQuestions);
        const [offeredCourseId, setofferedCourseId] = useState<string>(originalValues.offeredCourseId);
        const [projectDescription, setprojectDescription] = useState<string>(originalValues.projectDescription);
        const [projectTitle, setprojectTitle] = useState<string>(originalValues.projectTitle);

        const [supervisorId, setSupervisorId] = useState<string>(originalValues.supervisorName);
        const [examiner1Id, setExaminer1Id] = useState<string>(originalValues.examiner1Name);
        const [examiner2Id, setExaminer2Id] = useState<string>(originalValues.examiner2Name);

        // For handling errors

        const [assessmentIdError, setassessmentIdError] = useState<boolean>(false);
        const [assessmentTypeIdError, setassessmentTypeIdError] = useState<boolean>(false);
        const [assessmentDateError, setassessmentDateError] = useState<boolean>(false);
        const [assessmentTimeError, setassessmentTimeError] = useState<boolean>(false);
        const [maximumMarksError, setmaximumMarksError] = useState<boolean>(false);
        const [noOfQuestionsError, setnoOfQuestionsError] = useState<boolean>(false);
        const [offeredCourseIdError, setOfferedCourseIdError] = useState<boolean>(false);
        const [projectDescriptionError, setprojectDescriptionError] = useState<boolean>(false);
        const [projectTitleError, setprojectTitleError] = useState<boolean>(false);

        // For handling error messages
        const [assessmentIdErrorMessage, setassessmentIdErrorMessage] = useState("");
        const [offeredCourseIdErrorMessage, setOfferedCourseIdErrorMessage] = useState("");
        const [projectDescriptionErrorMessage, setprojectDescriptionErrorMessage] = useState("");
        const [projectTitleErrorMessage, setprojectTitleErrorMessage] = useState("");
        const [addressLine3ErrorMessage, setAddressLine3ErrorMessage] = useState("");
        const [assessmentTypeIdErrorMessage, setassessmentTypeIdErrorMessage] = useState("");
        const [assessmentDateErrorMessage, setassessmentDateErrorMessage] = useState("");
        const [assessmentTimeErrorMessage, setassessmentTimeErrorMessage] = useState("");
        const [maximumMarksErrorMessage, setmaximumMarksErrorMessage] = useState("")
        const [noOfQuestionsErrorMessage, setnoOfQuestionsErrorMessage] = useState("")

        const [instructorName, setInstructorName] = useState<any>([]);
        const [examiner1, setExaminer1] = useState<any>([]);
        const [examiner2, setExaminer2] = useState<any>([]);

        // Status radio buttons
        // const [status, setStatus] = useState(originalValues.active === true ? "Active" : "Deactive");

        // const handleChangeStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        //     setStatus((event.target as HTMLInputElement).value);
        // };
        // Status radio buttons
        const [assessmentTypeList, setAssessmentTypeList] = useState<any>([]);
        const [selectedSessionType, setSelectedSessionType] = useState(originalValues.sessionType);
        const [sessionTypeName, setSessionTypeName] = useState('');

        const [parsedData, setParsedData] = useState<any>([])
        useEffect(() => {
            // Retrieve the data from local storage
            const storedData = localStorage.getItem('courseData');

            if (storedData) {
                const parseData = JSON.parse(storedData);
                setParsedData(parseData)
                // Now, 'parsedData' contains your course data from local storage
            }
        }, []);


        const courseTypeOptions = parsedData.map((course: any) => ({
            label: `${course.courseCode} : ${course.section}`,
            value: course.offeredCourseId,
            sessionType: course.sessionType,
        }));

        const handleCourseTypeChange = (event: any, newValue: any) => {
            setofferedCourseId(newValue.value);
            setSelectedSessionType(newValue.sessionType);

        };


        const assessmentTypeOptions = assessmentTypeList.map((assessmentType: any) => ({
            label: assessmentType.assessmentTypeName,
            value: assessmentType.assessmentTypeId,
        }));

        const handleAssessmentTypeChange = (event: any, newValue: any) => {
            setassessmentTypeId(newValue.value);
        };


        // supervisor name options
        const instructorNameOptions = instructorName.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userId,
        }));
        const examiner1Options = examiner1.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userName,
        }));
        const examiner2Options = examiner2.map((item: any) => ({
            label: `${item.firstName} ${item.lastName} ${item.emailId}`,
            value: item.userName,
        }));

        const handleSupervisorNameChange = (event: any, newValue: any) => {
            setSupervisorId(newValue ? newValue.value : null);
        };
        const handleExaminer1Change = (event: any, newValue: any) => {
            setExaminer1Id(newValue ? newValue.value : null);
        };
        const handleExaminer2Change = (event: any, newValue: any) => {
            setExaminer2Id(newValue ? newValue.value : null);
        };

        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        // console.log("mydata", useSelector((state: RootState) => state.tableData.data));

        useEffect(() => {

            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                // Fetching data using axios and also pass the header x-api-key for auth
                axios.get(readAPI.AssessmentType, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setAssessmentTypeList(res.data.obj);
                    })
                    .catch((err) => {
                        console.log(err)
                        // setSnackBarHandler({
                        //     open: true,
                        //     message: err.message,
                        //     severity: "error"
                        // })
                    });
            }
        }, []);

        useEffect(() => {

            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {

                // @ Fetching user
                axios.get(readAPI.FetchUsers, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setInstructorName(res.data.obj);
                        setExaminer1(res.data.obj)
                        setExaminer2(res.data.obj)
                    })
                    .catch((err) => {
                        console.log(err)
                        // setSnackBarHandler({
                        //     open: true,
                        //     message: err.message,
                        //     severity: "error"
                        // })
                    });
            }
        }, [])

        useEffect(() => {

            if (accessToken === undefined || accessToken === null) {
                accessToken = null;
            }

            if (accessToken !== null) {
                // Fetching session type
                axios.get(`${readAPI.SessionTypeById}/${selectedSessionType}`, {
                    headers: {
                        "x-api-key": accessToken
                    }
                })
                    .then((res) => {
                        setSessionTypeName(res.data.obj.name)
                    })
                    .catch((err) => {
                        console.log(err)
                        // setSnackBarHandler({
                        //     open: true,
                        //     message: err.message,
                        //     severity: "error"
                        // })
                    });
            }
        }, [selectedSessionType])

        const { updateAssessments, snackBarHandler, setSnackBarHandler } = useUpdateAssessment();

        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasError = false;
                    
                        if (offeredCourseId === "" || offeredCourseId === null) {
                            setOfferedCourseIdError(true);
                            setOfferedCourseIdErrorMessage("Please Select Offered Course Id");
                            hasError = true;
                        } else {
                            setOfferedCourseIdError(false);
                            setOfferedCourseIdErrorMessage("");
                        }
                    
                        if (assessmentTypeId === "") {
                            setassessmentTypeIdError(true);
                            setassessmentTypeIdErrorMessage("Please Select Assessment Type Id");
                            hasError = true;
                        } else {
                            setassessmentTypeIdError(false);
                            setassessmentTypeIdErrorMessage("");
                        }
                    
                        const maximumMarksValue = parseFloat(maximumMarks);
                        if (maximumMarks === "" || isNaN(maximumMarksValue) || maximumMarksValue > 100 || maximumMarksValue < 1) {
                            setmaximumMarksError(true);
                            setmaximumMarksErrorMessage("Please Enter Marks Valid Value Range (1-100)");
                            hasError = true;
                        } else {
                            setmaximumMarksError(false);
                            setmaximumMarksErrorMessage("");
                        }
                    
                        const noOfQuestionsValue = parseFloat(noOfQuestions);
                        if (noOfQuestions === "" || isNaN(noOfQuestionsValue) || noOfQuestionsValue < 1 || noOfQuestionsValue > 100 || !Number.isInteger(noOfQuestionsValue)) {
                            setnoOfQuestionsError(true);
                            setnoOfQuestionsErrorMessage("Please Enter No Of Question Valid Value Range (1-100)");
                            hasError = true;
                        } else {
                            setnoOfQuestionsError(false);
                            setnoOfQuestionsErrorMessage("");
                        }
                    
                        if (assessmentTime === "") {
                            setassessmentTimeError(true);
                            setassessmentTimeErrorMessage("Please Enter Assessment Time");
                            hasError = true;
                        } else {
                            setassessmentTimeError(false);
                            setassessmentTimeErrorMessage("");
                        }
                    
                        return !hasError;
                    };
                    

                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    } else {
                        const formState = {
                            "assessmentId": originalValues.assessmentId,
                            "assessmentTypeId": assessmentTypeId,
                            "maximumMarks": maximumMarks,
                            "noOfQuestions": noOfQuestions,
                            "assessmentTime": assessmentTime,
                            "assessmentDate": assessmentDate,
                            "offeredCourseId": offeredCourseId,
                            "projectDescription": projectDescription,
                            "projectTitle": projectTitle,
                            "supervisorName": supervisorId,
                            "examiner1Name": examiner1Id,
                            "examiner2Name": examiner2Id,
                            "active": true
                        };

                        updateAssessments(formState, currentRowIndex);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                        }, 3000);
                        setFetchUpdate(true);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `Assessment has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             const m = response.data.message;
                        //             if (response.data.code === "200.200") {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                 }, 3000);
                        //                 setFetchUpdate(true);
                        //             }
                                    
                        //         }
                        //     })
                        //     .catch(function (error: any) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                {/* {offeredCourseId} */}
                                <Autocomplete
                                    id="offeredCourseIdAutocomplete"
                                    options={courseTypeOptions}
                                    getOptionLabel={option => option.label}
                                    value={courseTypeOptions.find((option: any) => option.value === offeredCourseId) || null}
                                    onChange={handleCourseTypeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('Home.Header.Modals.Assessment.policy.Inputs.offeredCourseId.label')}
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            error={offeredCourseIdError}
                                            helperText={offeredCourseIdErrorMessage}
                                            // value={offeredCourseId}
                                            dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
                                        />
                                    )}
                                />


                            </Grid>

                            {selectedSessionType?.includes("Project") ? (
                                <>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            id="projectTitleTextField"
                                            label={t('Home.Header.Modals.Assessment.policy.Inputs.projectTitle.label')}
                                            placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.projectTitle.placeholder')}`}
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            error={projectTitleError}
                                            helperText={projectDescriptionErrorMessage}
                                            value={projectTitle}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            onChange={(e) => {
                                                if (projectTitleError) {
                                                    setprojectTitleError(false);
                                                }
                                                setprojectTitle(e.target.value);
                                            }}
                                        />
                                        <span>* Maximum 1000 Characters Are Allowed</span>
                                    </Grid>

                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            id="projectDescriptionTextField"
                                            label={t('Home.Header.Modals.Assessment.policy.Inputs.projectDescription.label')}
                                            placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.projectDescription.placeholder')}`}
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={10}
                                            error={projectDescriptionError}
                                            helperText={projectDescriptionErrorMessage}
                                            value={projectDescription}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            onChange={(e) => {
                                                if (projectDescriptionError) {
                                                    setprojectDescriptionError(false);
                                                }
                                                setprojectDescription(e.target.value);
                                            }}
                                        />
                                        <span>* Maximum 2000 Characters Are Allowed</span>
                                    </Grid>

                                    <Grid item xl={4}
                                        lg={4}
                                        md={4}
                                        sm={12}
                                        xs={12}>
                                        <Autocomplete
                                            id="supervisorNameAutocomplete"
                                            options={instructorNameOptions}
                                            getOptionLabel={option => option.label}
                                            value={instructorNameOptions.find((option: any) => option.value === supervisorId) || null}
                                            onChange={handleSupervisorNameChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.supervisorName.label')}
                                                    // placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.supervisorName.placeholder')}`}
                                                    variant="standard"
                                                    fullWidth
                                                    value={supervisorId}
                                                    // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                    // error={courseTypeNamesError}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    style={{ width: '280px' }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={4}
                                        lg={4}
                                        md={4}
                                        sm={12}
                                        xs={12}>
                                        <Autocomplete
                                            id="examiner1Autocomplete"
                                            options={examiner1Options}
                                            getOptionLabel={option => option.label}
                                            value={examiner1Options.find((option: any) => option.value === examiner1Id) || null}
                                            onChange={handleExaminer1Change}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.examiner1.label')}
                                                    // placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.supervisorName.placeholder')}`}
                                                    variant="standard"
                                                    fullWidth
                                                    value={examiner1Id}
                                                    // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                    // error={courseTypeNamesError}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    style={{ width: '280px' }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={4}
                                        lg={4}
                                        md={4}
                                        sm={12}
                                        xs={12}>
                                        <Autocomplete
                                            id="examiner2Autocomplete"
                                            options={examiner2Options}
                                            getOptionLabel={option => option.label}
                                            value={examiner2Options.find((option: any) => option.value === examiner2Id) || null}
                                            onChange={handleExaminer2Change}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.examiner2.label')}
                                                    // placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.supervisorName.placeholder')}`}
                                                    variant="standard"
                                                    fullWidth
                                                    value={examiner2Id}
                                                    // helperText={(courseTypeNamesError) ? ("* Please select any College.") : ("")}
                                                    // error={courseTypeNamesError}
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                    style={{ width: '280px' }}
                                                />
                                            )}
                                        />
                                    </Grid>


                                </>) : (
                                <>
                                    <Grid item xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}>

                                        <Autocomplete
                                            id="assessmentTypeIdAutocomplete"
                                            options={assessmentTypeOptions}
                                            getOptionLabel={option => option.label}
                                            value={assessmentTypeOptions.find((option: any) => option.value === assessmentTypeId) || null}
                                            onChange={handleAssessmentTypeChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.assessmentTypeId.label')}
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    error={assessmentTypeIdError}
                                                    helperText={assessmentTypeIdErrorMessage}
                                                    dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
                                                />
                                            )}
                                        />


                                    </Grid>

                                    <Grid item xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            id="maximumMarksTextField"
                                            label={t('Home.Header.Modals.Assessment.policy.Inputs.maximumMarks.label')}
                                            placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.maximumMarks.placeholder')}`}
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            error={maximumMarksError}
                                            helperText={maximumMarksErrorMessage}
                                            value={maximumMarks}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            onChange={(e) => {
                                                if (maximumMarksError) {
                                                    setmaximumMarksError(false);
                                                }
                                                setmaximumMarks(e.target.value);
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xl={6}
                                        lg={6}
                                        md={6}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            id="noOfQuestionsTextField"
                                            label={t('Home.Header.Modals.Assessment.policy.Inputs.noOfQuestions.label')}
                                            placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.noOfQuestions.placeholder')}`}
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            error={noOfQuestionsError}
                                            helperText={noOfQuestionsErrorMessage}
                                            value={noOfQuestions}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                            onChange={(e) => {
                                                if (noOfQuestionsError) {
                                                    setnoOfQuestionsError(false);
                                                }
                                                setnoOfQuestions(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="assessmentDateTextField"
                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.assessmentDate.label')}
                                    placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.assessmentDate.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    type="date"
                                    fullWidth
                                    error={assessmentDateError}
                                    helperText={assessmentDateErrorMessage}
                                    value={assessmentDate}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (assessmentDateError) {
                                            setassessmentDateError(false);
                                        }
                                        setassessmentDate(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <TextField
                                    id="assessmentTimeTextField"
                                    label={t('Home.Header.Modals.Assessment.policy.Inputs.assessmentTime.label')}
                                    placeholder={`${t('Home.Header.Modals.Assessment.policy.Inputs.assessmentTime.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={assessmentTimeError}
                                    helperText={assessmentTimeErrorMessage}
                                    value={assessmentTime}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (assessmentTimeError) {
                                            setassessmentTimeError(false);
                                        }
                                        setassessmentTime(e.target.value);
                                    }}
                                    type="time"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default React.memo(UpdateAssessment);