import * as React from 'react';
import { useState, useEffect } from "react";

import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch
} from '@mui/material';
import SnackBar from '../../../../../../Components/SnackBar';

import { useTranslation } from "react-i18next";

import Cookies from 'js-cookie';

import axios from 'axios';

import styles from "./style.module.css";

import readAPI from '../../../../data/api/read';

import { columnValues } from '../../../../../../Data/Tables/CourseOfferings';
import { useSelector } from 'react-redux';
import { RootState } from "../../../../../../redux/store";
import useUpdateProgramType from '../customHooks/useUpdateProgramType';


interface ViewAllProgramTypeData {
    timestamp: string;
    transactionId: string;
    status: string;
    code: string;
    message: string[];
    obj: {
        createdBy: string;
        creationDateAndTime: number[];
        updatedBy: string | null;
        updateDateAndTime: number[] | null;
        programTypeId: string;
        programDesc: string;
        programName: string;
        programRemarks: string;
        active: boolean;
    }[];
}

interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateProgramType = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        // Fetching data using axios

        const [viewAllProgramTypeData, setViewAllProgramTypeData] = useState<ViewAllProgramTypeData | null>(null);
        const [ProgramDesc, setProgramDesc] = useState<string>(originalValues.programDesc);
        const [ProgramName, setProgramName] = useState<string>(originalValues.programName);
        const [ProgramRemarks, setProgramRemarks] = useState<string>(originalValues.programRemarks);

        // For handling errors

        const [ProgramDescError, setProgramDescError] = useState<boolean>(false);
        const [ProgramNameError, setProgramNameError] = useState<boolean>(false);
        const [ProgramRemarksError, setProgramRemarksError] = useState<boolean>(false);

        // For handling error messages
        const [ProgramDescErrorMessage, setProgramDescErrorMessage] = useState<string>("");
        const [ProgramNameErrorMessage, setProgramNameErrorMessage] = useState<string>("");
        const [ProgramRemarksErrorMessage, setProgramRemarksErrorMessage] = useState<string>("");

        const [isChecked, setIsChecked] = useState<boolean>(originalValues.active);
        const handleSwitchChange = (event: any) => {
            setIsChecked(!isChecked);
          };


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
        // let accessToken: any = Cookies.get("accessToken");

        // useEffect(() => {
        //     if (accessToken === undefined || accessToken === null) {
        //         accessToken = null;
        //     }

        //     if (accessToken !== null) {
        //         // @1) Fetching Users
        //         axios.get(readAPI.Program, {
        //             headers: {
        //                 "x-api-key": accessToken
        //             }
        //         })
        //             .then((res) => {
        //                 if (res.data.code === "200.200") {
        //                     setViewAllProgramTypeData(res.data);
        //                 }
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }
        // }, []);

        const {updateProgramsType, snackBarHandler, setSnackBarHandler} = useUpdateProgramType();
        const submitForm = async () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    // Set the validation errors
                    const textRegex = /^[\w,;'".?\d\s]+$/;
                    if (ProgramDesc === "") {
                        setProgramDescError(true);
                    }
                    if (!textRegex.test(ProgramDesc) && ProgramDesc.length !== 0) {
                        setProgramDescErrorMessage("Please enter only alphabets")
                        setProgramDescError(true);
                    } else {
                        setProgramDescErrorMessage("")
                    }
                    if (ProgramName === "") {
                        setProgramNameError(true);
                    }
                    if (!textRegex.test(ProgramName) && ProgramName.length !== 0) {
                        setProgramNameErrorMessage("Please enter only alphabets")
                        setProgramNameError(true);
                    } else {
                        setProgramNameErrorMessage("")
                    }
                    if (ProgramRemarks === "") {
                        setProgramRemarksError(true);
                    }
                    if (!textRegex.test(ProgramRemarks) && ProgramRemarks.length !== 0) {
                        setProgramRemarksErrorMessage("Please enter only alphabets")
                        setProgramRemarksError(true);
                    } else {
                        setProgramRemarksErrorMessage("")
                    }
                    // Set the validation errors

                    if (ProgramDesc !== "" && ProgramRemarks !== "" && ProgramName !== "") {
                        const formState = {
                            "programTypeId": originalValues.programTypeId,
                            "programDesc": ProgramDesc,
                            "programRemarks": ProgramRemarks,
                            "programName": ProgramName,
                            "active": isChecked
                        };
                        await updateProgramsType(formState, currentRowIndex);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                            setFetchUpdate(true);
                        }, 3000);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.data.code === "200.200") ? "success" : "error",
                        //                 message: (response.data.code === "200.200") ? `${ProgramName} data has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             if (response.data.code === "200.200") {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                     setFetchUpdate(true);
                        //                 }, 3000);
                        //             }
                        //         }
                        //     })
                        //     .catch(function (error: any) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    } else {
                        setSnackBarHandler({
                            open: true,
                            message: "Please fill all the required fields",
                            severity: 'error'
                        });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>

                   

                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>

                            <Grid item xs={12}>
                                <TextField
                                    id="ProgramNameTextField"
                                    label={t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramName.label')}
                                    placeholder={`${t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramName.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramNameError}
                                    helperText={ProgramNameErrorMessage}
                                    value={ProgramName}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramNameError) {
                                            setProgramNameError(false);
                                        }
                                        setProgramName(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    id="ProgramRemarksTextField"
                                    label={t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramRemarks.label')}
                                    placeholder={`${t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramRemarks.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramRemarksError}
                                    helperText={ProgramRemarksErrorMessage}
                                    value={ProgramRemarks}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramRemarksError) {
                                            setProgramRemarksError(false);
                                        }
                                        setProgramRemarks(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="ProgramDescTextField"
                                    label={t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramDesc.label')}
                                    placeholder={`${t('Home.Header.Modals.ProgramType.policy.Inputs.ProgramDesc.placeholder')}`}
                                    variant="standard"
                                    margin="normal"
                                    fullWidth
                                    error={ProgramDescError}
                                    // helperText={ProgramDescErrorMessage}
                                    value={ProgramDesc}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    onChange={(e) => {
                                        if (ProgramDescError) {
                                            setProgramDescError(false);
                                        }
                                        setProgramDesc(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xl={6}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}>
                                    <FormControlLabel
                                    label="Status"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            // value={isChecked}
                                            // onChange={()=> setIsChecked(!isChecked)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }

                                />
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

               

                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateProgramType;