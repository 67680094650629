import * as React from 'react';
import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material';
import SnackBar from '../../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';
import axios from 'axios';
import styles from "./style.module.css";
import readAPI from '../../../data/api/read';
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import initiative from "../../../../../assets/Images/strategicPlan/initiative.jpeg"
import { updateInitiativeByIndex, updateKpiValueByIndex } from '../../../../../redux/action/spsActions';


interface UpdateProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any,
    setFetchUpdate: any,
    setForUpdate: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}

const UpdateKpiValue = React.forwardRef<UpdateRef, UpdateProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        setForUpdate,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const dispatch = useDispatch();
        ///////////////////////////////// Snackbar State /////////////////////////////////
        const [snackBarHandler, setSnackBarHandler] = useState({
            open: false,
            message: '',
            severity: 'success'
        });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        // const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });


        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        const [program, setProgram] = useState<string>(originalValues.program);
        const [kpiName, setKpiName] = useState<string>(originalValues.kpiId);
        const [target, setTarget] = useState<string>(originalValues.target);
        const [minimumTarget, setMinimumTarget] = useState<string>(originalValues.minimumTarget);
        const [benchMark, setBenchMark] = useState<string>(originalValues.benchmark);
        const [newTarget, setNewTarget] = useState<string>(originalValues.newtarget);

        // error state
        const [programError, setProgramError] = useState<boolean>(false);
        const [kpiNameError, setKpiNameError] = useState<boolean>(false);
        const [targetError, setTargetError] = useState<boolean>(false);
        const [benchMarkError, setBenchMarkError] = useState<boolean>(false);
        const [newTargetError, setNewTargetError] = useState<boolean>(false);

        // error message state
        const [programErrorMessage, setProgramErrorMessage] = useState("");
        const [kpiNameErrorMessage, setKpiNameErrorMessage] = useState("");
        const [targetErrorMessage, setTargetErrorMessage] = useState("");
        const [benchMarkErrorMessage, setBenchMarkErrorMessage] = useState("");
        const [newTargetErrorMessage, setNewTargetErrorMessage] = useState("");


        const programData = useSelector((state: RootState) => state.programListData.data);
        const programOptions = programData?.map((item: any) => ({
            label: `${item.programTitle}`,
            value: item.programId
        }));

        const handleProgramChange = (event: any, newValue: any) => {
            setProgram(newValue ? newValue.value : null);
        }

        const kpiNames = useSelector((state: RootState) => state.sps.kpis);
        const kpiOptions = kpiNames?.map((item: any) => ({
            label: `${item.kpiName}`,
            value: item.kpiId
        }));

        const handleKpiChange = (event: any, newValue: any) => {
            setKpiName(newValue ? newValue.value : null);
        }
    
       



        const submitForm = () => {
            // Get the user from local storage
            // Add validation also 
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken === undefined || accessToken === null) {
                    accessToken = null;
                }

                if (accessToken !== null) {
                    const isValidateForm = () => {
                        let hasErrors = false;

                        if (kpiName === "") {
                            hasErrors = true;
                            setKpiNameError(true);
                            setKpiNameErrorMessage("Please Enter Name of Initiative");
                        }
                        if (target === "") {
                            hasErrors = true;
                            setTargetError(true);
                            setTargetErrorMessage("Please Enter Description");
                        }
                        if (minimumTarget === "") {
                            hasErrors = true;
                            setTargetError(true);
                            setTargetErrorMessage("Please Enter Description");
                        }
                        if (benchMark === "") {
                            hasErrors = true;
                            setBenchMarkError(true);
                            setBenchMarkErrorMessage("Please Enter Initiative Executive Manager")
                        }

                        return !hasErrors;
                    };


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    } else {
                        const formState = {
                            programId: program,
                            kpiId: kpiName,
                            target,
                            benchmark: benchMark,
                            newTarget,
                            minimumTarget,
                            strategicPlanId: originalValues.strategicPlanId,
                            active: true
                        };




                        axios.put(`${url}/${originalValues.kpiValueId}`,
                            formState
                            , {
                                headers: {
                                    'x-api-key': accessToken,
                                    'Accept-Language': 'en'
                                }
                            })
                            .then(function (response) {
                                if (response.data.code === "200.200") {
                                    dispatch(updateKpiValueByIndex(currentRowIndex, response.data.code))
                                    setOpenUpdateTableModal(false);
                                    setFetchUpdate(true);
                                    setForUpdate(true);
                                }
                            })
                            .catch(function (error: any) {
                                setSnackBarHandler({
                                    open: true,
                                    message: error.message,
                                    severity: "error"
                                })
                            });
                    }
                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>
                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>
                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>




                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ padding: "26px" }}>
                                <Grid container>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ mt: 3 }}>
                                        <Autocomplete
                                            options={programOptions}
                                            getOptionLabel={option => option.label}
                                            value={programOptions.find((option: any) => option.value === program) || null}
                                            onChange={handleProgramChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.program.placeholder')}`}
                                                    label={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.program.label')}`}
                                                    variant="outlined"
                                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                                // error={initiativeManagerError}
                                                // helperText={initiativeManagerErrorMessage}

                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12} sx={{ mt: 3 }}> 
                                        <Autocomplete
                                            options={kpiOptions}
                                            getOptionLabel={(option) => option.label}
                                            value={kpiOptions?.find((option: any) => option.value === kpiName) || null}
                                            onChange={handleKpiChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.kpiName.label')}
                                                    variant="outlined"
                                                    dir={currentLang === 'ar' ? 'rtl' : 'ltr'}
                                                />
                                            )}
                                        />
                                        
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={target}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.target.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.target.label')}`}
                                            error={targetError}
                                            helperText={targetErrorMessage}
                                            onChange={(e) => {
                                                if (targetError) {
                                                    setTargetError(false);
                                                    setTargetErrorMessage("");
                                                }
                                                setTarget(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={minimumTarget}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.minTarget.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.minTarget.label')}`}
                                            error={targetError}
                                            helperText={targetErrorMessage}
                                            onChange={(e) => {
                                                if (targetError) {
                                                    setTargetError(false);
                                                    setTargetErrorMessage("");
                                                }
                                                setMinimumTarget(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={benchMark}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.benchMark.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.benchMark.label')}`}
                                            error={benchMarkError}
                                            helperText={benchMarkErrorMessage}
                                            onChange={(e) => {
                                                if (benchMarkError) {
                                                    setBenchMarkError(false);
                                                    setBenchMarkErrorMessage("");
                                                }
                                                setBenchMark(e.target.value);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}>
                                        <TextField
                                            variant="outlined"
                                            style={{ backgroundColor: "#fff" }}
                                            margin="normal"
                                            fullWidth
                                            dir="ltr"
                                            value={newTarget}
                                            placeholder={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.newTarget.placeholder')}`}
                                            label={`${t('Home.Header.Modals.SPS.KPIValue.policy.Inputs.newTarget.label')}`}
                                            error={newTargetError}
                                            helperText={newTargetErrorMessage}
                                            onChange={(e) => {
                                                if (newTargetError) {
                                                    setNewTargetError(false);
                                                    setNewTargetErrorMessage("");
                                                }
                                                setNewTarget(e.target.value);
                                            }}
                                        />
                                    </Grid>



                                </Grid>

                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={6}
                                xs={6} style={{ padding: "0px", margin: "0px", paddingTop: "0px" }}>
                                <img src={initiative} height="100%" width="100%" />


                            </Grid>








                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default React.memo(UpdateKpiValue);