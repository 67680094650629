import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../store';
import { EqaCoreService } from '../../Apps/eqa-core/service/EqaCoreService';

export const COURSE_DETAILS = 'COURSE_DETAILS';
export const PROJECT_TEMPLATE = 'PROJECT_TEMPLATE';

export const FETCH_COURSE_DETAILS_DATA_REQUEST = 'FETCH_COURSE_DETAILS_DATA_REQUEST';
export const FETCH_COURSE_DETAILS_DATA_SUCCESS = 'FETCH_COURSE_DETAILS_DATA_SUCCESS';
export const FETCH_COURSE_DETAILS_DATA_FAILURE = 'FETCH_COURSE_DETAILS_DATA_FAILURE';
export const ADD_COURSE_DETAILS_DATA = 'ADD_COURSE_DETAILS_DATA';
export const UPDATE_COURSE_DETAILS_DATA = 'UPDATE_COURSE_DETAILS_DATA';
export const DELETE_COURSE_DETAILS_DATA = 'DELETE_COURSE_DETAILS_DATA';

export const FETCH_PROJECT_TEMPLATE_DATA_REQUEST = 'FETCH_PROJECT_TEMPLATE_DATA_REQUEST';
export const FETCH_PROJECT_TEMPLATE_DATA_SUCCESS = 'FETCH_PROJECT_TEMPLATE_DATA_SUCCESS';
export const FETCH_PROJECT_TEMPLATE_DATA_FAILURE = 'FETCH_PROJECT_TEMPLATE_DATA_FAILURE';
export const ADD_PROJECT_TEMPLATE_DATA = 'ADD_PROJECT_TEMPLATE_DATA';
export const UPDATE_PROJECT_TEMPLATE_DATA = 'UPDATE_PROJECT_TEMPLATE_DATA';
export const DELETE_PROJECT_TEMPLATE_DATA = 'DELETE_PROJECT_TEMPLATE_DATA';

//@ function for fetching COURSE DETAILS data ============================================================

export const fetchCourseDetailsRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_COURSE_DETAILS_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllCourseDetails();
            dispatch({ type: FETCH_COURSE_DETAILS_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_COURSE_DETAILS_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addCourseDetailsRedux = (data: any) => ({
    type: ADD_COURSE_DETAILS_DATA,
    payload: { data }
});

export const updateCourseDetailsRedux = (index: number, updatedCourseDetails: any) => ({
    type: UPDATE_COURSE_DETAILS_DATA,
    payload: { index, updatedCourseDetails },
});

export const deleteCourseDetailsRedux = (collegeId: number ) => ({
    type: DELETE_COURSE_DETAILS_DATA,
    payload: { collegeId }
});


//@ function for fetching PROJECT TEMPLATE data ============================================================

export const fetchProjectTemplateRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_PROJECT_TEMPLATE_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllProjectTemplate();
            dispatch({ type: FETCH_PROJECT_TEMPLATE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_PROJECT_TEMPLATE_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addProjectTemplateRedux = (data: any) => ({
    type: ADD_PROJECT_TEMPLATE_DATA,
    payload: { data }
});

export const updateProjectTemplateRedux = (index: number, updatedCourseDetails: any) => ({
    type: UPDATE_PROJECT_TEMPLATE_DATA,
    payload: { index, updatedCourseDetails },
});

export const deleteProjectTemplateRedux = (collegeId: number ) => ({
    type: DELETE_PROJECT_TEMPLATE_DATA,
    payload: { collegeId }
});

// export const courseDetails = (data: any) => ({
//     type: COURSE_DETAILS,
//     payload: { data }
// })

// export const projectTemplate = (data: any) => ({
//     type: PROJECT_TEMPLATE,
//     payload: { data }
// })


