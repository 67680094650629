import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { AnyAction, Dispatch } from 'redux';
import { CommitteeService } from "../../Apps/committee/service/CommitteeService";

export const COMMITTEE = 'COMMITTEE';

export const FETCH_COMMITTEE_DATA_REQUEST = 'FETCH_COMMITTEE_DATA_REQUEST';
export const FETCH_COMMITTEE_DATA_SUCCESS = 'FETCH_COMMITTEE_DATA_SUCCESS';
export const FETCH_COMMITTEE_DATA_FAILURE = 'FETCH_COMMITTEE_DATA_FAILURE';
export const ADD_COMMITTEE_DATA = 'ADD_COMMITTEE_DATA'
export const UPDATE_COMMITTEE_DATA = 'UPDATE_COMMITTEE_DATA'
export const DELETE_COMMITTEE_DATA = 'DELETE_COMMITTEE_DATA'

export const fetchCommitteeDataRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
  return async (dispatch: Dispatch): Promise<void> => {
      dispatch({ type: FETCH_COMMITTEE_DATA_REQUEST });
      try {
          const response = await CommitteeService.getAllCommittee();
          dispatch({ type: FETCH_COMMITTEE_DATA_SUCCESS, payload: response.data });
      } catch (error: any) {
          dispatch({ type: FETCH_COMMITTEE_DATA_FAILURE, payload: error.message });
      }
  };
}

export const addCommitteeDataRedux = (data: any) => ({
  type: ADD_COMMITTEE_DATA,
  payload: { data }
});

export const updateCommitteeDataRedux = (index: number, updatedCommitteeData: any) => ({
  type: UPDATE_COMMITTEE_DATA,
  payload: { index, updatedCommitteeData },
});

export const deleteCommitteeDataRedux = (id: number ) => ({
  type: DELETE_COMMITTEE_DATA,
  payload: { id }
});



export const committee = (data: any) => ({
    type: COMMITTEE,
    payload: {data}
  })


  
