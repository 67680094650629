interface CommitteeReducerState {
  committee: any | null;
  loading: any;
}

const initialState: CommitteeReducerState = {
  committee: null,
  loading: false,
};

const committeeReducer = (state = initialState, action: any): CommitteeReducerState => {
  switch (action.type) {
    // case 'COMMITTEE':
    //   return {
    //     ...state,
    //     committee: action.payload.data,
    //   };
    case 'FETCH_COMMITTEE_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        committee: action.payload
      }
    case 'ADD_COMMITTEE_DATA':
      return {
        ...state,
        committee: [...state.committee, action.payload.data]
      }
    case 'UPDATE_COMMITTEE_DATA':
      return {
        ...state,
        committee: state.committee.map((committee: any, index: any) =>
          index === action.payload.index ? { ...committee, ...action.payload.updatedCommitteeData } : committee
        ),
      };
    case 'DELETE_COMMITTEE_DATA':
      return {
        ...state,
        committee: state.committee.filter((committee: any, idx: number) => committee.committeeId !== action.payload.id),
      };
    default:
      return state;
  }
};

export default committeeReducer;