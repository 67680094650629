import * as React from 'react';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";

// Importing material ui components
import {
    Box,
    Grid,
    TextField,
    Switch,
    Autocomplete,
    FormControlLabel,
} from '@mui/material';
import SnackBar from '../../../../Components/SnackBar';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { CampusDetails, CollegeDetails, DepartmentDetails, GlobalService, UserDetails } from '../../../../Service/GlobalService';
import useUpdateCommittee from '../customHooks/useUpdateCommittee';


interface collegeOption {
    label: string;
    value: string;
}

interface campusOption {
    label: string;
    value: string;
}

interface departmentOption {
    label: string;
    value: string;
}

interface UpdateCommitteeProps {
    currentLang: string
    originalValues: any,
    url: string,
    setOpenUpdateTableModal: any
    setFetchUpdate: any,
    studyPlanOfferedProgram?: any,
    currentRowIndex: any
}

interface UpdateRef {
    // Define any functions that you want to expose to the parent component
    submitForm: any
}
const UpdateCommittee = React.forwardRef<UpdateRef, UpdateCommitteeProps>(
    ({
        currentLang,
        originalValues,
        url,
        setOpenUpdateTableModal,
        setFetchUpdate,
        studyPlanOfferedProgram,
        currentRowIndex
    },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();

        ///////////////////////////////// Snackbar State /////////////////////////////////
        // const [snackBarHandler, setSnackBarHandler] = useState({
        //     open: false,
        //     message: '',
        //     severity: 'success'
        // });
        ///////////////////////////////// Snackbar State /////////////////////////////////

        const [windowSize, setWindowSize] = useState([
            window.innerWidth,
            window.innerHeight,
        ]);

        useEffect(() => {
            const handleWindowResize = () => {
                setWindowSize([window.innerWidth, window.innerHeight]);
            };

            window.addEventListener('resize', handleWindowResize);

            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        });

        const [userList, setUsersList] = useState<UserDetails>({
            data: [],
            errMessage: ""
        })
        const [departmentList, setDepartmentList] = useState<DepartmentDetails>({
            data: [],
            errMessage: ""
        })
        const [campusList, setCampusList] = useState<CampusDetails>({
            data: [],
            errMessage: ""
        })
        const [collegeList, setCollegeList] = useState<CollegeDetails>({
            data: [],
            errMessage: ""
        })
        const [committeeName, setCommitteeName] = useState<any>(originalValues.committeeName);
        const [committeeDescription, setCommitteeDescription] = useState<any>(originalValues.committeeDescription);
        const [chair, setChair] = useState<any>(originalValues.committeeChair);
        const [department, setDepartment] = useState<any>(originalValues.department);
        const [campus, setCampus] = useState<any>(originalValues.campus);
        const [college, setCollege] = useState<any>(originalValues.college);

        const [isChecked, setIsChecked] = useState(true);
        const handleSwitchChange = (event: any) => {
            const newValue = event.target.checked;
            setIsChecked(newValue);
        };
        // set state for error

        const [committeeNameError, setCommitteeNameError] = useState<boolean>(false);
        const [committeeDescriptionError, setCommitteeDescriptionError] = useState<boolean>(false);
        const [chairError, setChairError] = useState<boolean>(false);
        const [departmentError, setDepartmentError] = useState<boolean>(false);
        const [campusError, setCampusError] = useState<boolean>(false);
        const [collegeError, setCollegeError] = useState<boolean>(false);


        //@ user list options
        const userListOptions = userList && userList?.data?.map((user: any) => ({
            key: user.userId,
            label: `${user.firstName} ${user.lastName} : ${user.emailId}`,
            value: user.userId

        }));
        const handleChairChange = (event: any, newValue: any) => {
            setChair(newValue ? newValue.value : null);
        };

        //@ college list options
        const collegeListOptions: collegeOption[] = collegeList && collegeList.data.map((item: any) => ({
            key: item.collegeId,
            label: `${item.collegeName}`,
            value: item.collegeId

        }));

        //@ department list options
        const departmentListOptions: departmentOption[] = departmentList && departmentList.data.map((item: any) => ({
            key: item.departmentId,
            label: `${item.departmentName}`,
            value: item.departmentId

        }));

        //@ campus list options
        const campusListOptions: campusOption[] = campusList && campusList.data.map((item: any) => ({
            key: item.campusId,
            label: `${item.campusName}`,
            value: item.campusId

        }));
        const handleCollegeChange = (event: any, newValue: any) => {
            setCollege(newValue ? newValue.value : null);
        };
        const handleCampusChange = (event: any, newValue: any) => {
            setCampus(newValue ? newValue.value : null);
        };
        const handleDepartmentChange = (event: any, newValue: any) => {
            setDepartment(newValue ? newValue.value : null);
        };






        let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);

        //@ Fetching data on load of component
        useEffect(() => {
            if (accessToken !== null) {
                //@fetching users
                const fetchUsers = async () => {
                    const usersData = await GlobalService.getUsers(accessToken);
                    setUsersList(usersData);
                }
                //@fetching department
                const fetchDepartment = async () => {
                    const departmentData = await GlobalService.getDepartment(accessToken);
                    setDepartmentList(departmentData);
                }
                //@fetching campus
                const fetchCampus = async () => {
                    const campusData = await GlobalService.getCampus(accessToken);
                    setCampusList(campusData);
                }
                //@fetching department
                const fetchCollege = async () => {
                    const collegeData = await GlobalService.getCollege(accessToken);
                    setCollegeList(collegeData);
                }

                //calling function
                fetchUsers();
                fetchDepartment();
                fetchCampus();
                fetchCollege();
            }
        }, []);






        const { updateCommittees, snackBarHandler, setSnackBarHandler } = useUpdateCommittee();
        const submitForm = () => {
            const userLocalStorage = JSON.parse(localStorage.getItem('user') || '{}');
            if (userLocalStorage !== null && userLocalStorage !== undefined) {
                const loggedInUser = userLocalStorage.userName;

                if (accessToken !== null) {
                    // setIsLoadingSpinner(true);
                    let hasError = false;
                    const isValidateForm = () => {
                        if (committeeName === "") {
                            setCommitteeNameError(true);
                            hasError = true;
                        }
                        else {
                            setCommitteeNameError(false);
                        }
                        if (committeeDescription === "") {
                            setCommitteeDescriptionError(true);
                            hasError = true;
                        }
                        else {
                            setCommitteeDescriptionError(false);
                        }
                        if (chair === "") {
                            setChairError(true);
                            hasError = true;
                        }
                        else {
                            setChairError(false);
                        }
                        if (campus === "") {
                            setCampusError(true);
                            hasError = true;
                        }
                        else {
                            setCampusError(false);
                        }
                        if (college === "") {
                            setCollegeError(true);
                            hasError = true;
                        }
                        else {
                            setCollegeError(false);
                        }
                        if (department === "") {
                            setDepartmentError(true);
                            hasError = true;
                        }
                        else {
                            setDepartmentError(false);
                        }


                        return !hasError;
                    }


                    // Set the validation errors

                    if (!isValidateForm()) {
                        setSnackBarHandler({
                            severity: "warning",
                            open: true,
                            message: "Please enter all the fields"
                        });
                        return;
                    }
                    else {

                        const formState: any = {
                            committeeId: originalValues.id,
                            committeeName: committeeName,
                            committeeDescription: committeeDescription,
                            committeeChair: chair,
                            department: department,
                            campus: campus,
                            college: college,
                            active: true
                        };

                        updateCommittees(formState, currentRowIndex);
                        setFetchUpdate(true);
                        setTimeout(() => {
                            setOpenUpdateTableModal(false);
                        }, 3000);

                        // axios.put(url,
                        //     formState
                        //     , {
                        //         headers: {
                        //             'x-api-key': accessToken,
                        //             'Accept-Language' : 'en'
                        //         }
                        //     })
                        //     .then(function (response) {
                        //         if (response.status === 200) {
                        //             setSnackBarHandler({
                        //                 severity: (response.status === 200) ? "success" : "error",
                        //                 message: (response.status === 200) ? `Committe has been updated successfully` : (response.data.message),
                        //                 open: true
                        //             })
                        //             const m = response.data.message;
                        //             if (response.status === 200) {
                        //                 setTimeout(() => {
                        //                     setOpenUpdateTableModal(false);
                        //                     setFetchUpdate(true);
                        //                 }, 3000);
                        //             }
                                    
                        //         }
                        //     })
                        //     .catch(function (error) {
                        //         setSnackBarHandler({
                        //             open: true,
                        //             message: error.message,
                        //             severity: "error"
                        //         })
                        //     });
                    }


                } else {
                    alert("Please login first");
                    navigate("/login");
                }
            } else {
                alert("Please login first");
                navigate("/login");
            }
        }
        /// Handling the data of the form

        // Define any functions or state that you want to expose to the parent component

        React.useImperativeHandle(ref, () => ({
            submitForm
        }));

        return (
            <Box className={styles.container}>


                <Box sx={{
                    // border: "1px solid red",
                    padding:
                        // Categorize according to small, medium, large screen
                        (windowSize[0] < 991) ? (2) : (windowSize[0] < 1200) ? (3) : (4),
                    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;"
                }}>



                    <Box sx={{ flexGrow: 1, mt: 0 }}>
                        <Grid container spacing={
                            // Categorize according to small, medium, large screen
                            (windowSize[0] < 576) ? (0) : ((windowSize[0] < 768) ? (1) : ((windowSize[0] < 992) ? (2) : (3)))
                        }>


                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeName.label')}`}
                                    placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeName.placeholder')}`}
                                    variant="standard"
                                    fullWidth
                                    error={committeeNameError}
                                    helperText={(committeeNameError) ? ("* Please Enter Study Plan Title.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={committeeName}
                                    onChange={(e) => setCommitteeName(e.target.value)}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ mt: 1 }}>
                                <TextField
                                    label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeDescription.label')}`}
                                    placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeDescription.placeholder')}`}
                                    variant="standard"
                                    fullWidth
                                    error={committeeDescriptionError}
                                    helperText={(committeeDescriptionError) ? ("* Please Enter Study Plan Title.") : ("")}
                                    dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                    value={committeeDescription}
                                    onChange={(e) => setCommitteeDescription(e.target.value)}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12} sx={{ paddingLeft: "30px" }}>
                                <Autocomplete
                                    options={userListOptions}
                                    getOptionLabel={option => option.label}
                                    value={userListOptions.find((option: any) => option.value === chair) || null}
                                    onChange={handleChairChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeChair.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.committeeChair.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={chairError}
                                            helperText={(chairError) ? ("* Please Select Any user.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={departmentListOptions}
                                    getOptionLabel={option => option.label}
                                    value={departmentListOptions.find((option: any) => option.value === department) || null}
                                    onChange={handleDepartmentChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.department.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.department.placeholder')}`}
                                            variant="standard"
                                            helperText={(departmentError) ? ("* Please select any department.") : ("")}
                                            error={departmentError}
                                            dir={(currentLang === "ar") ? "rtl" : "ltr"}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={campusListOptions}
                                    getOptionLabel={option => option.label}
                                    value={campusListOptions.find((option: any) => option.value === campus) || null}
                                    onChange={handleCampusChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.campus.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.campus.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={campusError}
                                            helperText={(campusError) ? ("* Please Select Any Campus.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xl={6}
                                lg={6}
                                md={6}
                                sm={12}
                                xs={12}>
                                <Autocomplete
                                    options={collegeListOptions}
                                    getOptionLabel={option => option.label}
                                    value={collegeListOptions.find((option: any) => option.value === college) || null}
                                    onChange={handleCollegeChange}
                                    onBlur={(event) => {
                                        event.preventDefault();
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.college.label')}`}
                                            placeholder={`${t('Home.Header.Modals.committee.CreateCommittee.policy.Inputs.college.placeholder')}`}
                                            variant="standard"
                                            dir={currentLang === "ar" ? "rtl" : "ltr"}
                                            error={collegeError}
                                            helperText={(collegeError) ? ("* Please Select Any College.") : ("")}
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xl={4}
                                lg={4}
                                md={4}
                                sm={12}
                                xs={12} sx={{ mt: 2 }}>
                                <FormControlLabel
                                    label="Active"
                                    control={
                                        <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            inputProps={{ 'aria-label': 'Toggle' }}
                                        />
                                    }
                                />
                            </Grid>










                        </Grid>
                    </Box>
                </Box>



                <SnackBar
                    isOpen={snackBarHandler.open}
                    message={snackBarHandler.message}
                    severity={snackBarHandler.severity}
                    isModal={true}
                    setIsOpen={
                        // Only pass the setIsOpen function to the SnackBar component
                        // and not the whole state object
                        (isOpen: boolean) => setSnackBarHandler({ ...snackBarHandler, open: isOpen })
                    }
                />

                <br />
            </Box>
        )
    }
)
export default UpdateCommittee;