import { useState, useEffect } from "react";

import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { HiUserGroup } from "react-icons/hi2";

import AddIcon from '@mui/icons-material/Add';
// Importing material ui components
import Button from '@mui/material/Button';

import axios from "axios";
import Cookies from "js-cookie";

// Importing components
import DataTableMD from "../../../../../DataTableMD";

import { useTranslation } from "react-i18next";

// @@@@@@@@@@@@@@ IMPORTING COURSE OFFERING TABLE DATA @@@@@@@@@@@@@@@@@
// Importing the course offering table data
import { states } from '../../../../../../Data/Tables/CourseOfferings';

// importing urls
import readAPI from "../../../../../../Data/API/READ";

import styles from "./style.module.css";
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/store';
import { manageRole } from "../../../../../../redux/action/accountActions";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { AccountService } from "../../service/AccountService";
import useRoleData from "../customHooks/useRoleData";

interface RolesProps {
    setIsOpen: any,
    isOpen: Boolean,
    // For minified sidebar
    isMinified: Boolean,
    setIsMinified: any,
    currentLang: string,
    setCurrentLang: any,
    // creatable: boolean
}

const ViewRoles: React.FC<RolesProps> = ({
    setIsOpen,
    isOpen,
    // For minified sidebar
    isMinified,
    setIsMinified,
    currentLang,
    setCurrentLang
    // creatable
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const state = location;
    // For Checking the Editable and Deletable Permission
    let FinalsidebarAppsListArray = JSON.parse(localStorage.getItem("sidebarAppsListArray") || '[]');

    const [creatable, setCreatable] = useState<boolean>(false);

    useEffect(() => {
        // For getting the current location path
        // eslint-disable-next-line no-restricted-globals
        const currentLocationPath = location.pathname;



        // Do it using for loop
        for (let i = 0; i < FinalsidebarAppsListArray.length; i++) {
            let subMenu = FinalsidebarAppsListArray[i].subMenu;
            for (let j = 0; j < subMenu.length; j++) {
                // alert("Current Location Path is: " + currentLocationPath + " and the subMenu[j].formUrl is: " + subMenu[j].formUrl + " and the i is: " + i + " and the Menu URL is: " + FinalsidebarAppsListArray[i].appUrl);
                if (subMenu[j].formUrl === currentLocationPath || FinalsidebarAppsListArray[i].appUrl === currentLocationPath) {
                    // alert("Equal" + FinalsidebarAppsListArray[i].appName)
                    if (subMenu[j].createPermission === true) {
                        setCreatable(true);

                    }
                }
            }
        }
    }, [FinalsidebarAppsListArray, creatable]);
    // For Checking the Editable and Deletable Permission

    const currentFormatedDate: string = new Date().toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    // Fetching data using axios
    // const [viewAllUsersData, setViewAllUsersData] = useState(null);
    // const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const [fetchUpdate, setFetchUpdate] = useState(false);

    const [addAlertMessage, setAddAlertMessage] = useState(false);
    const [updateAlertMessage, setUpdateAlertMessage] = useState(false);
    const [forUpdate, setForUpdate] = useState(false);

    const {viewAllUsersData, loadingStatus, callApiFun} = useRoleData(fetchUpdate);

    // let accessToken: any = useSelector((state: RootState) => state.auth.accessToken);
    // const callApiFun = async () => {
    //     if (accessToken === undefined || accessToken === null) {
    //         accessToken = null;
    //     }
    //     if (accessToken !== null) {
    //         // Fetching data using axios and also pass the header x-api-key for auth
    //         setLoadingStatus(true);
    //         const data: any = await AccountService.getAllRole(accessToken);
    //         dispatch(manageRole(data?.data));
    //         setViewAllUsersData(data?.data);
    //         setLoadingStatus(false);
    //     }
    // }
    // const roleList = useSelector((state: RootState) => state.account.manageRole);
    // useEffect(() => {
    //     if (roleList == null && fetchUpdate == true) {
    //         callApiFun();
    //     }
    //     else if (roleList !== null) {
    //         setViewAllUsersData(roleList);
    //     }
    //     else {
    //         callApiFun();
    //     }

    // }, [fetchUpdate]);

    // const addUserRole = localStorage.getItem("addUserRole");

    // useEffect(() => {
    //     if (addUserRole == "true") {
    //         callApiFun();
    //         localStorage.removeItem("addUserRole");
    //     }
    // }, [addUserRole])

    useEffect(() => {
        if (forUpdate === true) {
            // callApiFun();
            setFetchUpdate(prev => {
                if (!prev) {
                    return true;
                }
                return prev;
            });
            if (addAlertMessage === false) {
                setUpdateAlertMessage(true);
                setTimeout(() => {
                    setUpdateAlertMessage(false);
                }, 3000);
            }
            setForUpdate(false);
        }
    }, [forUpdate])

    useEffect(() => {
        if (state?.state?.message) {
            // console.log(state.state.message);
            setAddAlertMessage(true);
            setTimeout(() => {
                setAddAlertMessage(false);
            }, 3000)
        }
    }, [state]);

    return (
        <div
            className={`${styles.container} ${(windowSize[0] < 991 && isOpen) ? ("bgMobileOnSideOpen") : ("")}`}

            // On click of the sidebar, if the sidebar is open, then close it
            onClick={() => {
                if ((windowSize[0] < 991) && isOpen)
                    setIsOpen(false)
            }}
        >
            <div style={{ marginTop: 5 }} className={`${(windowSize[0] > 990) ? ("d-flex justify-content-between") : ("d-flex flex-column justify-content-start")}`}>
                <div>
                    {(t('Home.Sidebar.list.userManagement.subMenu.roles.details.breadcrumb.f1'))} / {(t('Home.Sidebar.list.userManagement.subMenu.roles.details.breadcrumb.f2'))} /<span style={{ color: "#4f747a" }}> {(t('Home.Sidebar.list.userManagement.subMenu.roles.details.breadcrumb.f3'))} </span>
                </div>
                <div>
                    <span style={{ color: "#4f747a", paddingRight: 10 }}>{currentFormatedDate}</span>
                </div>
            </div>

            {/* <hr />
            <br /> */}
            {/* Top Container */}
            <div className={styles.topContainer}>
                <div className={styles.leftTopContainer}>
                    <HiUserGroup size={27} style={{ marginTop: "3px" }} color="#4f747a" />
                    <p className={`${styles.topContainerLeftText}`}>
                        {/* <b style={{ fontWeight: "bold", color: "#4f747a" }}>Roles</b> Management  */}
                        {(t('Home.Sidebar.list.userManagement.subMenu.roles.details.title'))}
                    </p>
                </div>
                <div className={styles.rightTopContainer}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#d5b254",
                            // textTransform: "none",
                            fontWeight: "bold",
                            height: 40,
                            mt: 1,
                            "&:hover": {
                                backgroundColor: "#d5b254",
                            }
                        }}
                        onClick={() => {
                            navigate("/account/roles/addrole");
                        }}
                        startIcon={<AddIcon />}
                        disabled={!creatable}
                    >
                        {(t('Home.Sidebar.list.userManagement.subMenu.roles.details.addUser'))}
                    </Button>
                </div>
            </div>
            {/* Top Container */}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Stack sx={{ width: '40%' }} spacing={2}>
                    {addAlertMessage && (
                        <Alert className={`${styles.alertMessage} ${addAlertMessage ? styles.show : styles.hide}`} severity="success">Role has been created successfully.</Alert>
                    )}
                    {updateAlertMessage && (
                        <Alert className={`${styles.alertMessage} ${updateAlertMessage ? styles.show : styles.hide}`} severity="success">Role has been Updated Successfully.</Alert>
                    )}
                </Stack>
            </div>

            <div style={{ marginTop: 30 }}>
                <DataTableMD
                    isOpen={isOpen}
                    data={(
                        viewAllUsersData !== null
                    ) ? (
                        // @ts-ignore
                        viewAllUsersData
                    ) : ([])}
                    states={states}
                    columnName={"ViewRoles"}
                    tableInfo={(t('Home.Sidebar.list.userManagement.subMenu.roles.details.table.subTitle'))}
                    buttonTitle={"Create New Role"}
                    tableTitle={`<b style={{ fontWeight: "bold" }}>Roles</b> <i>List</i>`}
                    currentLang={currentLang}
                    setCurrentLang={setCurrentLang}

                    setFetchUpdate={setFetchUpdate}
                    loadingStatus={loadingStatus}
                    setForUpdate={setForUpdate}
                />
            </div>

            <br /> <br /> <br />
        </div>
    )
}
export default ViewRoles;