import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch } from 'redux';
import { RootState } from '../store';
import { EqaCoreService } from '../../Apps/eqa-core/service/EqaCoreService';


export const COLLEGE = 'COLLEGE';
export const UNIVERSITY = 'UNIVERSITY';
export const CAMPUS = 'CAMPUS';
export const DEPARTMENT = 'DEPARTMENT';
export const PROGRAM = 'PROGRAM';
export const PROGRAM_TYPE = 'PROGRAM_TYPE';
export const PROGRAM_GOAL = 'PROGRAM_GOAL';


export const FETCH_COLLEGE_DATA_REQUEST = 'FETCH_COLLEGE_DATA_REQUEST';
export const FETCH_COLLEGE_DATA_SUCCESS = 'FETCH_COLLEGE_DATA_SUCCESS';
export const FETCH_COLLEGE_DATA_FAILURE = 'FETCH_COLLEGE_DATA_FAILURE';
export const ADD_COLLEGE_DATA = 'ADD_COLLEGE_DATA';
export const UPDATE_COLLEGE_DATA = 'UPDATE_COLLEGE_DATA';
export const DELETE_COLLEGE_DATA = 'DELETE_COLLEGE_DATA';

export const FETCH_CAMPUS_DATA_REQUEST = 'FETCH_CAMPUS_DATA_REQUEST';
export const FETCH_CAMPUS_DATA_SUCCESS = 'FETCH_CAMPUS_DATA_SUCCESS';
export const FETCH_CAMPUS_DATA_FAILURE = 'FETCH_CAMPUS_DATA_FAILURE';
export const ADD_CAMPUS_DATA = 'ADD_CAMPUS_DATA';
export const UPDATE_CAMPUS_DATA = 'UPDATE_CAMPUS_DATA';
export const DELETE_CAMPUS_DATA = 'DELETE_CAMPUS_DATA';

export const FETCH_DEPARTMENT_DATA_REQUEST = 'FETCH_DEPARTMENT_DATA_REQUEST';
export const FETCH_DEPARTMENT_DATA_SUCCESS = 'FETCH_DEPARTMENT_DATA_SUCCESS';
export const FETCH_DEPARTMENT_DATA_FAILURE = 'FETCH_DEPARTMENT_DATA_FAILURE';
export const ADD_DEPARTMENT_DATA = 'ADD_DEPARTMENT_DATA';
export const UPDATE_DEPARTMENT_DATA = 'UPDATE_DEPARTMENT_DATA';
export const DELETE_DEPARTMENT_DATA = 'DELETE_DEPARTMENT_DATA';

export const FETCH_PROGRAM_DATA_REQUEST = 'FETCH_PROGRAM_DATA_REQUEST';
export const FETCH_PROGRAM_DATA_SUCCESS = 'FETCH_PROGRAM_DATA_SUCCESS';
export const FETCH_PROGRAM_DATA_FAILURE = 'FETCH_PROGRAM_DATA_FAILURE';
export const ADD_PROGRAM_DATA = 'ADD_PROGRAM_DATA';
export const UPDATE_PROGRAM_DATA = 'UPDATE_PROGRAM_DATA';
export const DELETE_PROGRAM_DATA = 'DELETE_PROGRAM_DATA';

export const FETCH_PROGRAM_TYPE_DATA_REQUEST = 'FETCH_PROGRAM_TYPE_DATA_REQUEST';
export const FETCH_PROGRAM_TYPE_DATA_SUCCESS = 'FETCH_PROGRAM_TYPE_DATA_SUCCESS';
export const FETCH_PROGRAM_TYPE_DATA_FAILURE = 'FETCH_PROGRAM_TYPE_DATA_FAILURE';
export const ADD_PROGRAM_TYPE_DATA = 'ADD_PROGRAM_TYPE_DATA';
export const UPDATE_PROGRAM_TYPE_DATA = 'UPDATE_PROGRAM_TYPE_DATA';
export const DELETE_PROGRAM_TYPE_DATA = 'DELETE_PROGRAM_TYPE_DATA';

export const FETCH_UNIVERSITY_DATA_REQUEST = 'FETCH_UNIVERSITY_DATA_REQUEST';
export const FETCH_UNIVERSITY_DATA_SUCCESS = 'FETCH_UNIVERSITY_DATA_SUCCESS';
export const FETCH_UNIVERSITY_DATA_FAILURE = 'FETCH_UNIVERSITY_DATA_FAILURE';
export const ADD_UNIVERSITY_DATA = 'ADD_UNIVERSITY_DATA';
export const UPDATE_UNIVERSITY_DATA = 'UPDATE_UNIVERSITY_DATA';
export const DELETE_UNIVERSITY_DATA = 'DELETE_UNIVERSITY_DATA';


export const FETCH_PROGRAM_GOAL_DATA_REQUEST = 'FETCH_PROGRAM_GOAL_DATA_REQUEST';
export const FETCH_PROGRAM_GOAL_DATA_SUCCESS = 'FETCH_PROGRAM_GOAL_DATA_SUCCESS';
export const FETCH_PROGRAM_GOAL_DATA_FAILURE = 'FETCH_PROGRAM_GOAL_DATA_FAILURE';
export const ADD_PROGRAM_GOAL_DATA = 'ADD_PROGRAM_GOAL_DATA';
export const UPDATE_PROGRAM_GOAL_DATA = 'UPDATE_PROGRAM_GOAL_DATA';
export const DELETE_PROGRAM_GOAL_DATA = 'DELETE_PROGRAM_GOAL_DATA';

export const FETCH_COURSE_DETAILS_DATA_REQUEST = 'FETCH_COURSE_DETAILS_DATA_REQUEST';
export const FETCH_COURSE_DETAILS_DATA_SUCCESS = 'FETCH_COURSE_DETAILS_DATA_SUCCESS';
export const FETCH_COURSE_DETAILS_DATA_FAILURE = 'FETCH_COURSE_DETAILS_DATA_FAILURE';
export const ADD_COURSE_DETAILS_DATA = 'ADD_COURSE_DETAILS_DATA';
export const UPDATE_COURSE_DETAILS_DATA = 'UPDATE_COURSE_DETAILS_DATA';
export const DELETE_COURSE_DETAILS_DATA = 'DELETE_COURSE_DETAILS_DATA';

//@ function for fetching college data ============================================================

export const fetchCollegeRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_COLLEGE_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllCollege();
            dispatch({ type: FETCH_COLLEGE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_COLLEGE_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addCollegeRedux = (data: any) => ({
    type: ADD_COLLEGE_DATA,
    payload: { data }
});

export const updateCollegeRedux = (index: number, updatedCollege: any) => ({
    type: UPDATE_COLLEGE_DATA,
    payload: { index, updatedCollege },
});

export const deleteCollegeRedux = (id: number ) => ({
    type: DELETE_COLLEGE_DATA,
    payload: { id }
});


//@ function for fetching campus data =========================================================

export const fetchCampusRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_CAMPUS_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllCampus();
            dispatch({ type: FETCH_CAMPUS_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_CAMPUS_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addCampusRedux = (data: any) => ({
    type: ADD_CAMPUS_DATA,
    payload: { data }
});

export const updateCampusRedux = (index: number, updatedCampus: any) => ({
    type: UPDATE_CAMPUS_DATA,
    payload: { index, updatedCampus },
});

export const deleteCampusRedux = (id: number ) => ({
    type: DELETE_CAMPUS_DATA,
    payload: { id }
});


//@ function for fetching department data ==============================================

export const fetchDepartmentRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_DEPARTMENT_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllDepartment();
            dispatch({ type: FETCH_DEPARTMENT_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_DEPARTMENT_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addDepartmentRedux = (data: any) => ({
    type: ADD_DEPARTMENT_DATA,
    payload: { data }
});

export const updateDepartmentRedux = (index: number, updatedDepartment: any) => ({
    type: UPDATE_DEPARTMENT_DATA,
    payload: { index, updatedDepartment },
});

export const deleteDepartmentRedux = (id: number ) => ({
    type: DELETE_DEPARTMENT_DATA,
    payload: { id }
});


//@ function for fetching department data =======================================================

export const fetchProgramRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_PROGRAM_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllProgram();
            dispatch({ type: FETCH_PROGRAM_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_PROGRAM_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addProgramRedux = (data: any) => ({
    type: ADD_PROGRAM_DATA,
    payload: { data }
});


export const updateProgramRedux = (index: number, updatedProgram: any) => ({
    type: UPDATE_PROGRAM_DATA,
    payload: { index, updatedProgram },
});

export const deleteProgramRedux = (id: number ) => ({
    type: DELETE_PROGRAM_DATA,
    payload: { id }
});


//@ function for fetching program type data =======================================================

export const fetchProgramTypeRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_PROGRAM_TYPE_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllProgramType();
            dispatch({ type: FETCH_PROGRAM_TYPE_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_PROGRAM_TYPE_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addProgramTypeRedux = (data: any) => ({
    type: ADD_PROGRAM_TYPE_DATA,
    payload: { data }
});

export const updateProgramTypeRedux = (index: number, updateProgramType: any) => ({
    type: UPDATE_PROGRAM_TYPE_DATA,
    payload: { index, updateProgramType },
});

export const deleteProgramTypeRedux = (id: number ) => ({
    type: DELETE_PROGRAM_TYPE_DATA,
    payload: { id }
});

//@ function for fetching UNIVERSITY data =======================================================

export const fetchUniversityRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_UNIVERSITY_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllUniversity();
            dispatch({ type: FETCH_UNIVERSITY_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_UNIVERSITY_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addUniversityRedux = (data: any) => ({
    type: ADD_UNIVERSITY_DATA,
    payload: { data }
});

export const updateUniversityRedux = (index: number, updatedUniversity: any) => ({
    type: UPDATE_UNIVERSITY_DATA,
    payload: { index, updatedUniversity },
});

export const deleteUniversityRedux = (id: number ) => ({
    type: DELETE_UNIVERSITY_DATA,
    payload: { id }
});


//@ function for fetching PROGRAM GOAL data =======================================================

export const fetchProgramGoalRedux = (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch): Promise<void> => {
        dispatch({ type: FETCH_PROGRAM_GOAL_DATA_REQUEST });
        try {
            const response = await EqaCoreService.getAllProgramGoal();
            dispatch({ type: FETCH_PROGRAM_GOAL_DATA_SUCCESS, payload: response.data });
        } catch (error: any) {
            dispatch({ type: FETCH_PROGRAM_GOAL_DATA_FAILURE, payload: error.message });
        }
    };
}

export const addProgramGoalRedux = (data: any) => ({
    type: ADD_PROGRAM_GOAL_DATA,
    payload: { data }
});

export const updateProgramGoalRedux = (index: number, updatedProgramGoal: any) => ({
    type: UPDATE_PROGRAM_GOAL_DATA,
    payload: { index, updatedProgramGoal },
});

export const deleteProgramGoalRedux = (id: number ) => ({
    type: DELETE_PROGRAM_GOAL_DATA,
    payload: { id }
});




/////////////////////

export const university = (data: any) => ({
    type: UNIVERSITY,
    payload: { data }
})



export const programGoal = (data: any) => ({
    type: PROGRAM_GOAL,
    payload: { data }
})

